/*
  Utility functions for making react components:
*/

import React from 'react'
import { Link } from 'react-router-dom'
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs'
import 'react-web-tabs/dist/react-web-tabs.css'
import Collapsible from 'react-collapsible'
import DocumentMeta from 'react-document-meta'
import PuffLoader from 'react-spinners/PuffLoader' // waiting loader icon
import ReactGA from 'react-ga4' // google analytics

import * as Config from './config'
import * as Database from './database-api'
import * as Fileserver from './fileserver-api'
import LandingPage from './landing'

const globals = Config.globals
const fsConfig = Config.fsConfig
const payConfig = Config.payConfig
const searchConfig = Config.pages.search
const generalConfig = Config.pages.general
const libraryConfig = Config.pages.library
const analyticsConfig = Config.analyticsConfig

export const months = ['January', 'February', 'March', 'April','May', 'June', 'July', 'August','September', 'October', 'November', 'December']

// Creates search bar's pull down menu options for all available subjects
export class SubjectOptions extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        dataFetched: Database.isSubjectsDataFetched()
      }
      this.extraClasses = props.extraClasses
      this.subjects = []
      this.isMobileHeader = props.isMobileHeader
      this.optionsSelectComponent = React.createRef()      
      this.searchBarRefName = this.isMobileHeader ? 'mobileHeaderSearchBarRef' : 'headerSearchBarRef'
  }
  
  // if the search subject pulldown menu is changed, store the current subject in the globals:
  subjectOnChangeFn(e, val) {        
    if (val)
      globals.urlParams.subject = val
    else
      delete globals.urlParams.subject 
    this.updateSearchBarPlaceholder(val)
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.optionsSelectComponent.current.setState({options: this.subjects})
  }

  // update the placeholder inside search bar according to current subject:
  updateSearchBarPlaceholder(subject) {    
    if (this.state.dataFetched) {      
      let searchBarElement = globals.currentPage[this.searchBarRefName].current
      if (searchBarElement)
        searchBarElement.placeholder = searchBarElement.placeholder.replace(/in .*$/, 'in ' + (subject ? Database.getSubjectTitle(subject) : 'all subjects'))
    }
  }

  render() {
    let subject
    if (this.state.dataFetched) {
      this.subjects = Database.getSubjects()
      subject = globals.urlParams.subject || ''
    } else {
      this.subjects = []
      subject = ''
    }
    let label = subject ? Database.getSubjectTitle(subject) : 'All Subjects'
    this.updateSearchBarPlaceholder(subject)
    // make key the label to ensure refreshing on second render    
    return (
      <OptionsSelect ref={this.optionsSelectComponent} key={label} label={label} id={'search-subject-select'} containerExtraClasses={this.extraClasses} options={this.subjects} optionKeyToTitleFn={(idx, s) => { return Database.getSubjectTitle(s) }} onChangeFn={this.subjectOnChangeFn.bind(this)} defaultValue={''} defaultLabel={'All Subjects'} addDefault={true} currentValue={subject} disabled={this.props.disabled || false} />
    )
  }  
}

// shopping cart button elements shown in the header panel:
class CartButton extends React.Component {
  constructor(props) {
      super(props)
      this.isMobile = props.isMobile
      this.state = {
        count: Database.getCartCount()
      }
      globals.currentPage['cartButton' + (this.isMobile ? 'Mobile' : '')] = this
  }
  
  // render shopping cart button and its count number badge:
  render() {    
    let empty = this.state.count === 0    
    let countClassName = empty ? ' hidden' : ''    
    let id = 'header-cart-button' + (this.isMobile ? '-mobile' : '')
    let divLinkE = (
      <div className='header-cart-div'>
        {renderImage(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/shopping-cart.png', id, 'header-cart-button image-button button')}
        <div className={'header-cart-count-div' + countClassName}>
          <span className="header-cart-count">{this.state.count}</span>
        </div>
      </div>
    )
    let promo = globals.urlParams.promo
    return (
      <div>
        {renderDivLink(id + '-container', divLinkE, 'cart' + (promo ? ('?promo=' + promo) : ''))}
      </div>
    )
  }
}

// component whose main section has a listing of curriculums (search/libary/cart/etc) and should have page toggle buttons on the bottom:
export class ToggledPagesComponent extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        dataFetched: props.dataFetched,
        pageIndexOffset: 0,
        numberOfResultsShown: props.numberOfResultsShown
      }
      this.parent = props.parent
      globals.currentPage.resultsComponent = this
  }
 
  // We only show N number of cards at a time. this callback fn is called when user clicks to see the previous or next page of cards:
  togglePageIndex(e, isForward) {
    this.setPageIndex(e, (this.state.pageIndexOffset + (isForward ? 1 : -1)))
  }

  // This callback fn is called when user clicks to see a specific page offset #:
  setPageIndex(e, pageOffset) {
    let indexOffset = pageOffset * this.state.numberOfResultsShown    
    // skip if going out of index bounds of clicking on the current page:
    if (indexOffset >= this.totalNumberOfResults || 
        indexOffset < 0 || 
        (pageOffset === this.state.pageIndexOffset * this.state.numberOfResultsShown)) 
        return
    this.reRender(pageOffset) 
  }

  // given a list of search results that came from database, returns a sublist to show in the current page:
  getCurriculumListInCurrentPage(allCurriculums) {
      this.totalNumberOfResults = allCurriculums.length
      var numberOfResultsToShow = Math.min(this.totalNumberOfResults, this.state.numberOfResultsShown) 
      let currResultIndex = this.state.pageIndexOffset * this.state.numberOfResultsShown
      return allCurriculums.slice(currResultIndex, currResultIndex + numberOfResultsToShow)
  }


  // forces search results component to rerender
  reRender(pageOffset) {
    // scroll to top of the new page:
    animateScroll(140, 0)    
    // set new page offset, trigger render of a new page:
    this.setState({pageIndexOffset: pageOffset})
    this.pageTogglesSection.setState({pageIndexOffset: pageOffset})    
  }

  // tell results panels that need to know the search results (summary panel, page toggle buttons) to update now that we have the search results:
  // NOTE: this is because some of the sibling components (filter panel) don't need to be re-rendered, otherwise we would have just called the parent component to re-render (bad design!)...
  notifyDependantSections() {    
    if (this.state.dataFetched) {      
      if (this.parent.dependantComponentsOnToggledPagesComponent) {
        this.parent.dependantComponentsOnToggledPagesComponent.forEach(p => {p.setState({dataFetched: true, numResults: this.totalNumberOfResults})})
      }
    }
  }

  componentDidMount() {
    // set a flag so others know this is ready:
    this.didMount = true   
    this.notifyDependantSections()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.notifyDependantSections()
  }

  // preMapFn: function to run before mapping over each item in list, during render
  // inMapFn: function to run for each item in list, during render
  render(idPrefix, className, preMapFn, inMapFn) {    
    var idCounter = 1
    // If have a function to call before map over the curr list do it:
    if (preMapFn !== undefined)
      preMapFn()
    // get a list of search results that came from database, filtered based on what current toggle page we're on (filter ones which have no data fetched):    
    const curriculumList = this.getCurriculumListInCurrentPage().map(c => Database.getCurriculumData(c)).filter(curriculumData => curriculumData)
    let curriculumListings = curriculumList.length === 0 ?
      renderNothingToShow(idPrefix) :
      curriculumList.map((curriculumData) => {                
        // If have a function to call during map over the curr list for each curriculum, do it:
        if (inMapFn !== undefined)
          inMapFn(curriculumData)
        //let separator = idCounter > 1 ? (<hr/>) : undefined
        var id = idCounter++
        let itemView = this.renderCurriculumItemDiv(id, curriculumData)      
        return (
          <div id={idPrefix + '-curriculum-item-container-' + id} key={idPrefix + '-curriculum-item-container-' + id}>          
            {itemView}          
          </div>
        )
      })    
    return (
      <div id={idPrefix + '-curriculum-listings'} className={className}>        
        {curriculumListings}
      </div>
    )
  }
}

// Results page toggle buttons
export class CurriculumsListingPageToggleButtons extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        dataFetched: props.dataFetched || false,
        numResults: props.numResults || 0,
        pageIndexOffset: 0       
      }
      this.numberOfResultsShown = props.numberOfResultsShown
      if (globals.currentPage.resultsComponent !== undefined) {
        globals.currentPage.resultsComponent.pageTogglesSection = this
      }
      props.parent.dependantComponentsOnToggledPagesComponent.push(this)
  }

  render() {
    if (this.state.dataFetched) {      
      let resultsComponent = globals.currentPage.resultsComponent      
      let numToggledPages = Math.ceil(this.state.numResults / this.numberOfResultsShown)
      if (numToggledPages === 0) {
        return (
          <div id="results-page-toggle" className="page-toggle">
          </div>
        )
      }
      let currPageIdx = this.state.pageIndexOffset
      let numberOfPageTogglesShown = searchConfig.numberOfPageTogglesShownOnEachSide
      let pageIdxs
      // show ellipsis ... when number pages is too high
      // different variations of how the pages and ... are shown depending on which page we're on:
      if (numToggledPages <= numberOfPageTogglesShown + 1)
        pageIdxs = [...Array(numToggledPages).keys()]
      else if (currPageIdx < numberOfPageTogglesShown)
        pageIdxs = [...Array(numberOfPageTogglesShown).keys(), -1, numToggledPages - 1]
      else if (numToggledPages - currPageIdx < numberOfPageTogglesShown + 1)
        pageIdxs = [0, -1, ...Array.from(Array(numberOfPageTogglesShown).keys(), j => numToggledPages - numberOfPageTogglesShown + j)]
      else
        pageIdxs = [0, -1, currPageIdx, -1, numToggledPages - 1]      
      let idCtr = 0
      let toggledPages = pageIdxs.map(i => {
        if (i === -1) {
          // this is a flag to show ellipsis ... when number pages is too high
          return (
            <span id={'results-toggle-page-' + i} key={'results-toggle-page-ellipsis' + idCtr++} className="page-toggle-button">...</span>
          )
        } else {
          let onClickFn = (i === currPageIdx) ? ((e) => {}) : 
            ((e) => {resultsComponent.setPageIndex(e, i)})
          let classNameAddr = (i === currPageIdx) ? ' underlined' : ' button'
          return (
            <span id={'results-toggle-page-' + i} key={'results-toggle-page-' + i} className={'page-toggle-button text-button' + classNameAddr} onClick={onClickFn}>{i + 1}</span>
          )
        }
      })
      // render page butons inside a toggled pages view:
      return renderToggledPagesView(toggledPages, currPageIdx, numToggledPages, resultsComponent.togglePageIndex.bind(resultsComponent), 'results-page-toggle', 'results-page-toggle', 'search-results-page-choose-buttons', '', 'search-results', 'results-toggle', true)
    } else {
      return (
        <div id="results-page-toggle" className="page-toggle"></div>
      )
    }
  }
}

// Used as a superclass for curriculum preview/view pages body component:
export class CurriculumPreviewAndViewPageBodyComponent extends React.Component {
  constructor(props) {
    super(props)
    this.page = props.page
    this.page.body = this
    this.lessonCollapsibles = {}
    this.descriptionsDivs = {}
    this.descriptionsDivContents = {}
    this.setDescriptionsDivContent = []
    this.descriptionsExpanded = false
    this.listingContentComponentRef = React.createRef()
    this.listingContentHeaderComponentRef = React.createRef()
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    // add html description of teacher about text:
    insertHtmlAsChild('view-body-teacher-about-content', this.page.teacherData.about)  
    // if have received the description content from the external iframe, but we haven't yet set the content for the description div, set it here: 
    if (this.setDescriptionsDivContent.length > 0) {      
      this.insertContentFromExternalIframeIntoDOM(this.setDescriptionDivContent.slice())
    }
  }

  wishlistToggle(button) {}
  
  // expand all or collapse all lesson descriptions:
  toggleExpandCoursePlanDescriptions(button) {
    this.descriptionsExpanded = !this.descriptionsExpanded
    button.innerText = this.descriptionsExpanded ? 'Collapse' : 'Expand'    
    Object.keys(this.lessonCollapsibles).forEach(lesson => {
      let lessonCollapsible = this.lessonCollapsibles[lesson].current      
      lessonCollapsible.setState({open: this.descriptionsExpanded, isClosed: !this.descriptionsExpanded, hasBeenOpened: this.descriptionsExpanded})
      lessonCollapsible.innerRef.style.height = this.descriptionsExpanded ? 'auto' : '0px'      
    })
  }

  // sets the tab id of last tab open and optionally forced a fresh render to make sure the right tab is shown as default:
  setLastTabOpen(tabId, forceUpdate = false) {    
    let page = this.page
    if (globals.haveLocalStorage)
      localStorage.setItem('termeric-page-' + page.pageId + '-' + page.id + '-last-tab-open', tabId)
    page.lastTabOpen = tabId
    if (forceUpdate) {
      let listingContentTabs = this.listingContentComponentRef.current
      if (listingContentTabs != null) {        
        // set a flag so that we do the scroling 'scrollToCoursePlan' once force update render is done:
        listingContentTabs.scrollToCoursePlanAfterUpdate = true
        listingContentTabs.forceUpdate()
      }
    }
  }

  // scrolls to course plan section:
  scrollToCoursePlan() {    
    if (this.page.lastTabOpen !== 'plan') {
      this.setLastTabOpen('plan', true)
    } else {
      let listingContentHeaderComponent = this.listingContentHeaderComponentRef.current
      if (listingContentHeaderComponent != null) {        
        listingContentHeaderComponent.scrollIntoView()
        // fix the scroll overshoot:
        window.scrollBy(0, -70)
      }
    }
  }  

  // opens a preview screenshots for this curriculum for user to view samples:
  previewContent(previewsUrl) {    
    openExternalLink(previewsUrl)
    //showDialog(0, (<iframe className="previews-iframe" src={previewsUrl} />), undefined, [], [])
  }

  renderListingTopInfo() {
    let curriculumData = this.page.curriculumData
    let ratingImageWidth = curriculumData.rating * 15
    let numberOfRatings = curriculumData['num-ratings']
    let numberOfDownloads = curriculumData['num-downloads'] 
    let teacherData = this.page.teacherData
    let subject = curriculumData.subject
    let subjectLink = 'search?subject=' + subject
    let minGrade = curriculumData['min-grade'], maxGrade = curriculumData['max-grade'], isGradeRange = curriculumData['is-grade-range'], isPreK = minGrade === 0
    let gradeTitle = isGradeRange ? 'Grades' : (isPreK ? '' : 'Grade')
    let gradeShown = '' + (isPreK ? 'Pre/K' : minGrade) + (isGradeRange ? ('-' + maxGrade) : '')
    return (
      <div id="preview-body-top-listing" className="page-body-top-listing section">
        <div id="top-curriculum-listing-info" className="side-panel">
          <div id="top-curriculum-listing-classification" className="row-div">
            <div key={'subject-button-img-div-' + subject} className="subject-button-img-div-small subject-button-img-div round-border button image-button">
              {renderImageLink(Database.getSubjectIcon(subject), 'subject-button',   'subject-button-img', subjectLink)}
            </div>
            {renderLink(Database.getSubjectTitle(subject), 'top-curriculum-listing-subject', 'h5size', subjectLink)}
            <i className="fas fa-angle-right row-padding-10"></i>
            {renderLink(curriculumData['topic-title'], 'top-curriculum-listing-topic', 'h5size', subjectLink + '&topic=' + curriculumData.topic)}
          </div>
          <h2 id="top-curriculum-listing-title" className="logo-color boxed-text size22">{curriculumData['single-unit'] ? 'Curriculum Unit' : 'Curriculum'}</h2>
          <h2 id="top-curriculum-listing-title" className="logo-font size31">{curriculumData.title}</h2>
          <h4 id="top-curriculum-listing-subtitle" className="logo-font size20">{curriculumData.subtitle}</h4>
          <span id="top-curriculum-listing-grade" className="curriculum-view-grade size20">{gradeTitle}&nbsp;<span className="curriculum-view-grade size20">{gradeShown}</span></span>
          <div id="top-curriculum-listing-rating-container" className="top-listing-rating-container row-div">
            <h5 id="top-curriculum-listing-rating-avg" className="weight200">{curriculumData.rating.toFixed(1)}</h5>
            {renderRatingStars('top-curriculum-listing', 'curriculum-listing', ratingImageWidth)}
            <h6 id="top-curriculum-listing-rating-num-ratings" className="size16">{'(' + plural('rating', numberOfRatings) + ')'}</h6>
            <h6 id="top-curriculum-listing-rating-num-downloads" className="size16">{plural('teacher', numberOfDownloads) + ' bought it'}</h6>
          </div>
          {teacherData === undefined ?
            null :
            (<div className="row-div">
              <h6 id="top-curriculum-listing-teacher" className="size16">Created by teacher</h6>
              {renderLink(teacherData.name, 'top-curriculum-listing-teacher-link', 'footer-button text-button button', 'teacher?teacher=' + teacherData.id)}
            </div>)}
          <div id="top-curriculum-listing-frequency-info" className="top-listing-other-info row-div">
            <div className="flex-row flex-center">
              <i className="fa fa-certificate" aria-hidden="true"></i>
              &nbsp;&nbsp;
              <span id="top-curriculum-listing-frequency-weeks-num" className="h3size weight200">{curriculumData['num-weeks']}</span>
              <h6 id="top-curriculum-listing-frequency-weeks" className="h6size weight200">{plural('week', curriculumData['num-weeks'], true)}</h6>
            </div>
            <div className="flex-row flex-center">
              <i className="fa fa-certificate" aria-hidden="true"></i>
              &nbsp;&nbsp;
              <h5 id="top-curriculum-listing-frequency-lessons-num" className="h3size weight200">{curriculumData['num-lessons-per-week']}</h5>
              <h6 id="top-curriculum-listing-frequency-lessons" className="h6size weight200">{plural('lesson', curriculumData['num-lessons-per-week'], true)} each week</h6>
            </div>
            <div className="flex-row flex-center">
              <i className="fa fa-certificate" aria-hidden="true"></i>
              &nbsp;&nbsp;
              <h5 id="top-curriculum-listing-frequency-minutes-num" className="h3size weight200">{curriculumData['num-minutes-per-lesson']}</h5>
              <h6 id="top-curriculum-listing-frequency-minutes" className="h6size weight200">{plural('minute', curriculumData['num-minutes-per-lesson'], true)} each lesson</h6>
            </div>
          </div>
          {curriculumData['has-instruction-video'] ? (
          <div id="top-curriculum-listing-instruction-video-info" className="top-listing-other-info row-div">
            <div className="flex-row flex-center">
              <i className="fas fa-video" aria-hidden="true"></i>
              &nbsp;
              <h6 id="top-curriculum-listing-frequency-course-plan" className="h6size weight200">Instruction video for lessons included</h6>
            </div>
          </div>
          ) : null}
          <div id="top-curriculum-listing-plans-info" className="top-listing-other-info row-div">
            <div className="flex-row flex-center">
              <i className="fas fa-envelope-open-text" aria-hidden="true"></i>
              &nbsp;&nbsp;
              <span id="top-curriculum-listing-frequency-course-plans-num" className="h3size weight200">1</span>
              <h6 id="top-curriculum-listing-frequency-course-plans" className="h6size weight200">full {curriculumData['single-unit'] ? 'unit' : 'course'} plan included</h6>
            </div>
          </div>
          <div id="top-curriculum-listing-resources-info" className="top-listing-other-info row-div">
            <div className="flex-row flex-center">
              <i className="fas fa-envelope-open-text" aria-hidden="true"></i>
              &nbsp;&nbsp;
              <span id="top-curriculum-listing-frequency-lessons-num" className="h3size weight200">{curriculumData['num-lessons']}</span>
              <h6 id="top-curriculum-listing-frequency-lessons" className="h6size weight200">detailed lesson plans/teacher notes included</h6>
            </div>
            <div className="flex-row flex-center">
              <i className="fas fa-mail-bulk"></i>
              &nbsp;&nbsp;
              <span id="top-curriculum-listing-frequency-resources-num" className="h3size weight200">{curriculumData['num-resources']}</span>
              <h6 id="top-curriculum-listing-frequency-resources" className="h6size weight200">lesson slide decks/resources included</h6>
            </div>
          </div>
          <div id="top-curriculum-listing-assessments-info" className="top-listing-other-info row-div">
            <div className="flex-row flex-center">
              <i className="fas fa-tasks" aria-hidden="true"></i>
              &nbsp;&nbsp;
              <span id="top-curriculum-listing-frequency-assignments-num" className="h3size weight200">{curriculumData['num-assignments']}</span>
              <h6 id="top-curriculum-listing-frequency-assignments" className="h6size weight200">{plural(' assignment', curriculumData['num-assignments'], true) + ' included'}</h6>
            </div>
            <div className="flex-row flex-center">
              <i className="fas fa-envelope-open-text" aria-hidden="true"></i>
              &nbsp;&nbsp;
              <span id="top-curriculum-listing-frequency-tests-num" className="h3size weight200">{curriculumData['num-tests']}</span>
              <h6 id="top-curriculum-listing-frequency-tests" className="h6size weight200">{(curriculumData['single-unit'] ? 'unit' : 'midterm/final') + plural(' test', curriculumData['num-tests'], true) + ' included'}</h6>
            </div>
          </div>
          <div id="top-curriculum-listing-bundle-info" className="top-listing-other-info row-div">
            <div className="flex-row flex-center">
              <i className="fas fa-download"></i>
              <h6 id="top-curriculum-listing-download-bundle" className="h6size weight200">downloadable for offline access</h6>
              <i className="fas fa-print"></i>
              <h6 id="top-curriculum-listing-print-bundle" className="h6size weight200">print-friendly</h6>
            </div>
          </div>
          <div id="top-curriculum-listing-school-info" className="top-listing-other-info row-div">
            <i className="fa fa-school"></i>
            <h6 id="top-curriculum-listing-last-school" className="h7size weight200">Last taught in school</h6>
            {renderExternalLink(curriculumData['school-taught-title'], 'view-body-teacher-link', 'text-button button', curriculumData['school-taught-website'])}
            <div className="flex-row flex-center"><i className="fas fa-clock"></i>
            <h6 id="top-curriculum-listing-last-term-taught" className="h7size weight200">{' term ' + curriculumData['term-last-taught'].split(',').join(' ')}</h6></div>
          </div>
          <div id="top-curriculum-listing-other-info" className="top-listing-other-info flex-row flex-center">
            <i className="fas fa-clock"></i>
            <h6 id="top-curriculum-listing-last-updated" className="h7size weight200">{'Last updated ' + epochToDate(curriculumData['date-last-updated'])}</h6>
            <div className="flex-row flex-center"><i className="fas fa-language"></i>
            <h6 id="top-curriculum-listing-language" className="h7size weight200">{Database.getLanguageTitle(curriculumData['the-language'])}</h6></div>   
          </div>
          <div id="top-curriculum-listing-ctas" className="top-listing-ctas row-div">
            {this.renderWishlistButton()}
            {this.renderShareButton()}
          </div>
        </div>
        {this.renderListingImageAndCTAs(curriculumData)}
      </div>
    )
  }

  // renders wishlist button (preview page (a subclass) will redfine it):
  renderWishlistButton() {
    return undefined
  }

  // renders share button to copy link to clipboard
  renderShareButton() {
    let curriculumData = this.page.curriculumData
    return curriculumData.private ? null : renderButtonFromButton(<button>Share&nbsp;&nbsp;<i className="fas fa-share-alt"></i></button>, 'top-curriculum-listing-share-button', 'wide-text-button colored-bordered-text-button bordered-text-button text-button button', (e) => {copyToClipboard(this.page.getCurriculumShareUrl())})
  }

  renderListingContent() {
    return (
      <CurriculumPreviewListingContentComponent parent={this} page={this.page} ref={this.listingContentComponentRef} />
    )    
  }
  
  render() {
    let pageId = this.page.id    
    if (this.page.state.dataFetched) {
      return (
        <div>
          {this.renderExternalIframes()}
          <div id={pageId + '-body-main'} className={'page-body' + (this.pageBodyClass ? (' ' + this.pageBodyClass) : '')}>
            {this.renderListingTopInfo()}
            {this.renderListingContent()}
          </div> 
        </div>
      )
    } else return renderPageLoading()
  }

   // *** A *** Some of curriculum info (course and each lesson descriptions) comes from external HTML files sitting in the fileserver. We get those content by having invisible iframes to those html files, whose scripts will asyncronously send (postMessage) to this page their content DOM as a string. 
  // Here we create those iframes to initiate this process, and in another place we will receive the sent messages and convert the string into DOM elements to place in the right positions in this page...:
  renderExternalIframes() {
    return renderExternalIframes(this.page, this, this.page.curriculumData, true)
  }

  // *** B *** here we will handle/receive the sent messages and convert the string into DOM elements to place in the right positions in this page...:    
  handleReceivingContentFromExternalIframes() {
    handleReceivingContentFromExternalIframes(this)
  }

  // *** C **** Here we place the received content in the right position in this page:
  // NOTE: list of [id]: id of curriculum/lesson: this is description for curriculum, or lesson 
  insertContentFromExternalIframeIntoDOM(ids) {
    insertContentFromExternalIframeIntoDOM(this, ids)
  }

}

// contains the tabs that show the content listing:
export class CurriculumPreviewListingContentComponent extends React.Component {
  constructor(props) {
    super(props)
    this.page = props.page
    this.parent = props.parent
  }

  // this is called after a forceUpdate due to default tab changing... 
  componentDidUpdate(prevProps, prevState, snapshot) {
    let parent = this.parent
    // repopulate the descriptions for course/lessons contents that came from external iframe:
    parent.descriptionsExpanded = false
    parent.insertContentFromExternalIframeIntoDOM(Object.keys(parent.descriptionsDivContents))
    // if a flag was set so that we do the scroling 'scrollToCoursePlan' once force update render is done, do it now:
    if (this.scrollToCoursePlanAfterUpdate) {
      this.scrollToCoursePlanAfterUpdate = false
      this.parent.scrollToCoursePlan()
    }
  }

  renderListingContentInfo() {
    let parent = this.parent
    let curriculumData = this.page.curriculumData
    let teacherData = this.page.teacherData
    let teacherLink = teacherData ? ('teacher?teacher=' + teacherData.id) : undefined
    let id = curriculumData.id
    let descriptionDivRef = React.createRef()
    parent.descriptionsDivs[id] = descriptionDivRef
    let teacherRequirements = curriculumData['teacher-requirements']
    let teacherRequirementsOptionals = curriculumData['teacher-requirements-optionals']
    let studentRequirements = curriculumData['student-requirements']
    let studentRequirementsOptionals = curriculumData['student-requirements-optionals']
    return (
      <div id="view-body-listing-content-info" className="tab">
        <h4 id="view-what-will-learn-title">What students will learn</h4>
        {renderBulletListFromStringList('view-what-will-learn-text', curriculumData['learn-what'])}
        <h4 id="view-description-title">Description</h4>
        <div id={'view-description-text-' + id} className="curriculum-about-item boxed-div" ref={descriptionDivRef}><em className="opac">description not available...</em></div>
        <h4 id="view-requirements-teacher-title">Requirements for teacher</h4>
        {renderBulletListFromStringList('view-requirements-teacher-text', [...teacherRequirements, ...teacherRequirementsOptionals], [...teacherRequirements.map(i => undefined), ...teacherRequirementsOptionals.map(i => 'optional')], true)}
        <h4 id="view-requirements-students-title">Requirements for students</h4>        
        {renderBulletListFromStringList('view-requirements-student-text', [...studentRequirements, ...studentRequirementsOptionals], [...studentRequirements.map(i => undefined), ...studentRequirementsOptionals.map(i => 'optional')], true)}        
        <h4 id="view-teacher-title">Created by</h4>
        {teacherData === undefined ?
          null :
          (<div id="view-teacher-div" className="flex-col">
            <span>Teacher
            {renderLink(teacherData.name, 'view-body-teacher-link', 'text-button button', teacherLink)}</span>
            {renderImageLink(teacherData.imageUrl, 'view-body-teacher-image', 'view-body-teacher-image round-border', teacherLink)}            
            <div id="view-body-teacher-about-content"></div>
          </div>)
          }
        <h4 id="view-reviews-title">Reviews</h4>
        <Reviews id={this.page.id} parent={this.page} />
      </div>
    )
  }

  renderListingContentCoursePlanHeader() {
    return null
  }

  // renders the course outline table (collapsible by section -> week -> lesson)
  renderListingContentCoursePlan() {
    return renderListingContentCoursePlanAndStoreLessonsPrevNextLinksCache(this.page.curriculumData, this)    
  }

  renderLessonTitle(lesson, lessonTitle, sectionIndex, weekIndex, dayIndex, lessonIndex, perWeekLessonIndex, numberOfLessonsPerWeek) {
    let parent = this.parent
    let descriptionDivRef = React.createRef(), lessonCollapsibleRef = React.createRef()
    parent.descriptionsDivs[lesson] = descriptionDivRef
    parent.lessonCollapsibles[lesson] = lessonCollapsibleRef
    return (
      <Collapsible key={'lesson-title-' + lessonIndex} trigger={(numberOfLessonsPerWeek == 1 ? '' : ('Day ' + (perWeekLessonIndex + 1) + ' ')) + 'Lesson ' + (lessonIndex + 1) + ': ' + lessonTitle} open={this.descriptionsExpanded} transitionTime={200} classParentString="lesson-title-collapsible Collapsible" ref={lessonCollapsibleRef} >
        <div id={'view-description-text-' + lesson} className="description-bg boxed-div curriculum-lesson-description" ref={descriptionDivRef}><em className="opac">description not available...</em></div>
      </Collapsible>
    )
  }
  
  render() {
    let parent = this.parent
    let curriculumData = this.page.curriculumData
    // give such key to force refresh:
    return (
      <div key={getEpochTime()} ref={parent.listingContentHeaderComponentRef} >
        <Tabs id="view-body-listing-content" className="page-body-listing-content section"
          defaultTab={this.page.lastTabOpen || 'info'}
          onChange={(tabId) => { parent.setLastTabOpen(tabId, false) }} >
          <TabList>
            <Tab tabFor='info'><span className="tab-header">About</span></Tab>
            <Tab tabFor='plan'><span className="tab-header">{curriculumData['single-unit'] ? 'Unit' : 'Course'} Plan</span></Tab>          
          </TabList>
          <TabPanel tabId='info'>
            {this.renderListingContentInfo()}
          </TabPanel>
          <TabPanel tabId='plan'>
            {this.renderListingContentCoursePlan()}
          </TabPanel>
        </Tabs>
      </div>  
    )
  }
}

// View page body component (restricted to those who have access to view the curriculum...):
export class CurriculumViewPageBodyComponent extends CurriculumPreviewAndViewPageBodyComponent {

  // if the x-day money back guarantee is still valid, prompt the user before downloading the bundle zip file, since that action will cancel the guarantee:
  promptUserToDownloadCurriculumBundle(curriculumData) {    
    if (Database.isPurchasedCurriculumMoneyBackGuaranteeValid(curriculumData.id)) {
      // ask user confirm download first:
      showTextDialog(0, 'Downloading the curriculum bundle will end the ' + libraryConfig.numberOfDaysBeforeMoneyBackGuaranteeExpiresDays + '-day money back guarantee. You can always access the curriculum online without affecting the guarantee during this period. Are you sure you want to download?', generalConfig.dialogBoxInfoIconClassName, undefined, ['Cancel', 'Download'], [undefined, (e) => this.downloadCurriculumBundle(curriculumData)])
    } else
      this.downloadCurriculumBundle(curriculumData)
  }

  // download the curriculum bundle zip file:
  downloadCurriculumBundle(curriculumData) {
    let onSuccessFn = () => {
      // once curriculum bundle is downloaded, mark this curriculum as downloaded for the user in the db users table:
      Database.database_markUserBoughtCurriculumAsDownloaded(curriculumData.id)
    }
    Fileserver.downloadContent(false, curriculumData.bundle, onSuccessFn)
    showTextDialog(0, 'After the curriculum bundle is downloaded:\nOpen the zip file, go to the folder, and then view the "curriculum.html" file in your browser.', generalConfig.dialogBoxInfoIconClassName)
  }

  renderListingImageAndCTAs(curriculumData) {
    return (
      <div id="top-curriculum-listing-image-and-ctas" className="top-listing-image-and-ctas">
        <img id="top-curriculum-listing-image" className="curriculum-listing-image boxed-div" src={curriculumData.imageUrl} alt="" />
        {renderButtonFromButton(<span><i className="fas fa-book-open"></i>&nbsp;&nbsp;&nbsp;View lesson plans</span>, 'view-lesson-plans-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', this.scrollToCoursePlan.bind(this))}
        {renderLink(<span><i className="fas fa-print"></i>&nbsp;&nbsp;&nbsp;Print {curriculumData['single-unit'] ? 'unit' : 'course'} plan</span>, 'view-print-course-plan-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', 'curriculum-print?subject=' + this.page.subject + '&id=' + this.page.id + '&random-key=' + this.page.curriculumData['random-key'])}
        {renderButtonFromButton(<button><i className="fas fa-file-download"></i>&nbsp;&nbsp;&nbsp;Download full curriculum</button>, 'view-download-all-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', (e) => this.promptUserToDownloadCurriculumBundle(curriculumData))}
        {renderButtonFromButton(<span><i className="fas fa-star-half-alt"></i>&nbsp;&nbsp;&nbsp;Rate this course</span>, 'view-review-button', 'wide-cta-text-button cta-text-button yellow-shadowed-text-button bordered-text-button text-button button', this.page.openReviewDialog.bind(this.page))}
        {renderButtonFromButton(<span><i className="fas fa-paper-plane"></i>&nbsp;&nbsp;&nbsp;Contact teacher</span>, 'view-contact-teacher-button', 'wide-cta-text-button cta-text-button clear-shadowed-text-button bordered-text-button text-button button', e => this.page.setState({navigateTo: '/contact?recipient-teacher=' + this.page.curriculumData.teacher}))}
      </div>
    )
  }

  renderListingContent() {
    return (
      <CurriculumViewListingContentComponent parent={this} page={this.page} ref={this.listingContentComponentRef} />
    )    
  }

}

// contains the tabs that show the content listing:
export class CurriculumViewListingContentComponent extends CurriculumPreviewListingContentComponent {

  renderListingContentCoursePlanHeader() {
    return (
      <h6 className="top-margin30">Click on each lesson title to view the lesson plan.</h6>
    )
  }

  renderLessonTitle(lesson, lessonTitle, sectionIndex, weekIndex, dayIndex, lessonIndex, perWeekLessonIndex, numberOfLessonsPerWeek) {
    let parent = this.parent
    let descriptionDivRef = React.createRef(), lessonCollapsibleRef = React.createRef()
    parent.descriptionsDivs[lesson] = descriptionDivRef
    parent.lessonCollapsibles[lesson] = lessonCollapsibleRef
    let lessonNumber = lessonIndex + 1
    return (
      <Collapsible key={'lesson-title-' + lessonIndex} trigger={renderLink((numberOfLessonsPerWeek == 1 ? '' : ('Day ' + (perWeekLessonIndex + 1) + ' ')) + 'Lesson ' + lessonNumber + ': ' + lessonTitle, 'lesson-link-' + lessonIndex, 'size16', this.page.pageDir + 'lesson?subject=' + this.page.subject + '&id=' + this.page.id + '&random-key=' + this.page.curriculumData['random-key'] + '&section=' + (sectionIndex + 1) + '&week=' + (weekIndex + 1) + '&day=' + (dayIndex + 1) + '&lesson=' + lesson + '&number=' + lessonNumber)} open={this.descriptionsExpanded} transitionTime={200} classParentString="lesson-title-collapsible Collapsible" ref={lessonCollapsibleRef}>
        <div id={'view-description-text-' + lesson} className="description-bg boxed-div curriculum-lesson-description" ref={descriptionDivRef}><em className="opac">description not available...</em></div>
      </Collapsible>
    )
  }

  renderCurriculumContentResources() {
    let curriculumData = this.page.curriculumData    
    return (            
      <div id="curriculum-body-listing-content-resources" className="tab">        
        <h4>Resources</h4>        
        <h6>General course resources not specific to any lesson:</h6>
        {renderBulletListFromStringList('curriculum-resources', curriculumData['resource-descriptions'], curriculumData['resource-urls'], false, undefined, curriculumData['resource-is-externals'])}
      </div>
    )
  }

  renderCurriculumContentTeacherNotes() {
    let curriculumData = this.page.curriculumData
    return (
      <div id="curriculum-body-listing-content-teacher-notes" className="tab">
        <h4>Teacher Notes</h4>
        <h6>Some notes and tips on how to deliver this curriculum...</h6>
        <span className="form-input-description"><em className="opac">Also look for any notes for the teacher in each lesson&#39;s <strong>teacher notes</strong> as well as the <strong>speaker notes</strong> in the lesson <strong>slides</strong></em>.</span>
        {renderBulletListFromStringList('curriculum-teacher-notes', curriculumData['teacher-notes'])}
      </div>
    )
  }

  render() {
    let parent = this.parent
    let curriculumData = this.page.curriculumData
    // give such key to force refresh:
    return (
      <div key={getEpochTime()} ref={parent.listingContentHeaderComponentRef}>
        <Tabs id="view-body-listing-content" className="page-body-listing-content section"
          defaultTab={this.page.lastTabOpen || 'info'}
          onChange={(tabId) => { parent.setLastTabOpen(tabId, false) }} >
          <TabList>
            <Tab tabFor='info'><span className="tab-header">About</span></Tab>
            <Tab tabFor='plan'><span className="tab-header">{curriculumData['single-unit'] ? 'Unit' : 'Course'} Plan</span></Tab>
            <Tab tabFor='resources'><span className="tab-header">Resources</span></Tab>
            <Tab tabFor='teacher-notes'><span className="tab-header">Teacher Notes</span></Tab>            
          </TabList>
          <TabPanel tabId='info'>
            {this.renderListingContentInfo()}
          </TabPanel>
          <TabPanel tabId='plan'>
            {this.renderListingContentCoursePlan()}
          </TabPanel>
          <TabPanel tabId='resources'>
            {this.renderCurriculumContentResources()}
          </TabPanel>
          <TabPanel tabId='teacher-notes'>
            {this.renderCurriculumContentTeacherNotes()}
          </TabPanel>
        </Tabs>
      </div>   
    )
  }
}

// main page component for the curriculum-print page:
export class CurriculumPrintViewPageBodyComponent extends CurriculumPreviewAndViewPageBodyComponent {
  
  constructor(props) {
    super(props)
    this.pageBodyClass = 'no-padding-page-body'
  }

  renderListingTopInfo() {
    return null
  }

  renderListingHeaderInfo() {
    let curriculumData = this.page.curriculumData
    let teacherData = this.page.teacherData
    let minGrade = curriculumData['min-grade'], maxGrade = curriculumData['max-grade'], isGradeRange = curriculumData['is-grade-range'], isPreK = minGrade === 0
    let gradeTitle = isGradeRange ? 'Grades' : (isPreK ? '' : 'Grade')
    let gradeShown = '' + (isPreK ? 'Pre/K' : minGrade) + (isGradeRange ? ('-' + maxGrade) : '')
    return (
      <div>
        <h2 id="top-curriculum-listing-title" className="logo-color size24 top-margin20">{curriculumData['single-unit'] ? 'Curriculum Unit' : 'Curriculum'}</h2>
        <div className="margin20 section white-bg boxed-div">          
          {renderLink(curriculumData.title, 'top-curriculum-listing-title', 'logo-font size31', 'view?subject=' + this.page.subject + '&id=' + this.page.id + '&random-key=' + curriculumData['random-key'])}
          <h4 id="top-curriculum-listing-subtitle" className="logo-font size20">{curriculumData.subtitle}</h4>
        </div>
        <span id="top-curriculum-listing-grade" className="curriculum-view-grade size20">{gradeTitle}&nbsp;<span className="curriculum-view-grade size20">{gradeShown}</span></span>
        <h6 id="top-curriculum-listing-teacher" className="size16">Created by teacher {teacherData.name}</h6>
        <h6 id="top-curriculum-listing-teacher" className="size14 weight200">Taught in School: {curriculumData['school-taught-title']}</h6>
        <div id="top-curriculum-listing-frequency-info" className="top-listing-other-info row-div">
          <i className="fa fa-certificate" aria-hidden="true"></i>
          &nbsp;&nbsp;<span id="top-curriculum-listing-frequency-weeks-num" className="h3size weight200">{curriculumData['num-weeks']}</span><h6 id="top-curriculum-listing-frequency-weeks" className="h6size weight200">{plural('week', curriculumData['num-weeks'], true)}</h6>
          <i className="fa fa-certificate" aria-hidden="true"></i>
          &nbsp;&nbsp;<h5 id="top-curriculum-listing-frequency-lessons-num" className="h3size weight200">{curriculumData['num-lessons-per-week']}</h5><h6 id="top-curriculum-listing-frequency-lessons" className="h6size weight200">{plural('lesson', curriculumData['num-lessons-per-week'], true)} each week</h6>
          <i className="fa fa-certificate" aria-hidden="true"></i>
          &nbsp;&nbsp;<h5 id="top-curriculum-listing-frequency-minutes-num" className="h3size weight200">{curriculumData['num-minutes-per-lesson']}</h5>
          <h6 id="top-curriculum-listing-frequency-minutes" className="h6size weight200">{plural('minute', curriculumData['num-minutes-per-lesson'], true)} each lesson</h6>
        </div>
        {curriculumData['has-instruction-video'] ? (
          <div id="top-curriculum-listing-instruction-video-info" className="top-listing-other-info row-div">
            <div className="flex-row flex-center">
              <i className="fas fa-video" aria-hidden="true"></i>
              &nbsp;
              <h6 id="top-curriculum-listing-frequency-course-plan" className="h6size weight200">Instruction video for lessons included</h6>
            </div>
          </div>
          ) : null}
        <div id="top-curriculum-listing-course-plan-info" className="top-listing-other-info row-div">
            <div className="flex-row flex-center">
              <i className="fas fa-envelope-open-text" aria-hidden="true"></i>
              &nbsp;&nbsp;
              <span id="top-curriculum-listing-frequency-course-plans-num" className="h3size weight200">1</span>
              <h6 id="top-curriculum-listing-frequency-course-plan" className="h6size weight200">full {curriculumData['single-unit'] ? 'unit' : 'course'} plan included</h6>
            </div>
        </div>
        <div id="top-curriculum-listing-lessons-info" className="top-listing-other-info row-div">
            <div className="flex-row flex-center">
              <i className="fas fa-envelope-open-text" aria-hidden="true"></i>
              &nbsp;&nbsp;
              <span id="top-curriculum-listing-frequency-lessons-num" className="h3size weight200">{curriculumData['num-lessons']}</span>
              <h6 id="top-curriculum-listing-frequency-lessons" className="h6size weight200">{plural('detailed lesson plan/teacher note', curriculumData['num-lessons'], true) + ' included'}</h6>
            </div>
        </div>
        <div id="top-curriculum-listing-resources-info" className="top-listing-other-info row-div">
          <div className="flex-row flex-center">
              <i className="fas fa-mail-bulk"></i>
              &nbsp;&nbsp;
              <span id="top-curriculum-listing-frequency-resources-num" className="h3size weight200">{curriculumData['num-resources']}</span>
              <h6 id="top-curriculum-listing-frequency-resources" className="h6size weight200">{plural('lesson slide decks/resource', curriculumData['num-resources'], true) + ' included'}</h6>
            </div>
        </div>
        <div id="top-curriculum-listing-assessments-info" className="top-listing-other-info row-div">
          <div className="flex-row flex-center">
            <i className="fas fa-tasks" aria-hidden="true"></i>
              &nbsp;&nbsp;
            <span id="top-curriculum-listing-frequency-assignments-num" className="h3size weight200">{curriculumData['num-assignments']}</span>
            <h6 id="top-curriculum-listing-frequency-assignments" className="h6size weight200">{plural(' assignment', curriculumData['num-assignments'], true) + ' included'}</h6>
          </div>
          <div className="flex-row flex-center">
            <i className="fas fa-envelope-open-text" aria-hidden="true"></i>
              &nbsp;&nbsp;
            <span id="top-curriculum-listing-frequency-tests-num" className="h3size weight200">{curriculumData['num-tests']}</span>
            <h6 id="top-curriculum-listing-frequency-tests" className="h6size weight200">{(curriculumData['single-unit'] ? 'unit' : 'midterm/final') + plural(' test', curriculumData['num-tests'], true) + ' included'}</h6>
          </div>
        </div>
        <div id="top-curriculum-listing-bundle-info" className="top-listing-other-info row-div">
            <div className="flex-row flex-center">
              <i className="fas fa-download"></i>           
              <h6 id="top-curriculum-listing-download-bundle" className="h6size weight200">downloadable for offline access</h6>
              <i className="fas fa-print"></i>
              <h6 id="top-curriculum-listing-print-bundle" className="h6size weight200">print-friendly</h6>
            </div>
          </div>
      </div>
    )
  }

  renderListingDescriptionInfo() {
    let curriculumData = this.page.curriculumData
    let id = curriculumData.id
    let descriptionDivRef = React.createRef()
    this.descriptionsDivs[id] = descriptionDivRef
    let teacherRequirements = curriculumData['teacher-requirements']
    let teacherRequirementsOptionals = curriculumData['teacher-requirements-optionals']
    let studentRequirements = curriculumData['student-requirements']
    let studentRequirementsOptionals = curriculumData['student-requirements-optionals']
    return (
      <div>
        <h4 id="view-what-will-learn-title">What students will learn</h4>
        {renderBulletListFromStringList('view-what-will-learn-text', curriculumData['learn-what'])}
        <h4 id="view-description-title">Description</h4>
        <div id={'view-description-text-' + id} className="" ref={descriptionDivRef}></div>
        <h4 id="view-requirements-teacher-title">Requirements for teacher</h4>
        {renderBulletListFromStringList('view-requirements-teacher-text', [...teacherRequirements, ...teacherRequirementsOptionals], [...teacherRequirements.map(i => undefined), ...teacherRequirementsOptionals.map(i => 'optional')], true)}
        <h4 id="view-requirements-students-title">Requirements for students</h4>
        {renderBulletListFromStringList('view-requirements-student-text', [...studentRequirements, ...studentRequirementsOptionals], [...studentRequirements.map(i => undefined), ...studentRequirementsOptionals.map(i => 'optional')], true)}
      </div>
    )
  }

  renderListingContentCoursePlan() {    
    let curriculumData = this.page.curriculumData
    let sectionLessonIndices = curriculumData['section-lesson-indices']
    let lessons = curriculumData['lessons']
    let lessonTitles = curriculumData['lesson-titles']
    let numberOfSections = sectionLessonIndices.length
    let numberOfLessons = curriculumData['num-lessons']
    let numberOfLessonsPerWeek = curriculumData['num-lessons-per-week']
    var currSectionLessonIndex = sectionLessonIndices[0]
    var nextSectionLessonIndex = numberOfSections > 1 ? sectionLessonIndices[1] : numberOfLessons
    var weekIndex = 0, lessonIndex = 0, leftoverDaysFromWeekInPrevSection = 0
    let sectionsComponent = curriculumData['section-titles'].map((st, sectionIndex) => {
      let sectionTitle = st || 'Unit'
      var sectionLessonIndex = 0
      let sectionLessons = lessons.slice(currSectionLessonIndex, nextSectionLessonIndex)
      let sectionLessonTitles = lessonTitles.slice(currSectionLessonIndex, nextSectionLessonIndex)
      let numLessonsInSection = nextSectionLessonIndex - currSectionLessonIndex
      let hadLeftoverDaysFromWeekInPrevSection = leftoverDaysFromWeekInPrevSection > 0
      let numLessonsInSectionMinusLeftoverFromWeekInPrevSection = numLessonsInSection - leftoverDaysFromWeekInPrevSection
      let leftoverDaysDromWeekInPrevSectionCovered = numLessonsInSectionMinusLeftoverFromWeekInPrevSection >= 0
      let numberOfWeeksAddedDueToLeftoverFromWeekInPrevSection = hadLeftoverDaysFromWeekInPrevSection ? 1 : 0
      let WeeksPerSectionDecimal = Math.max(0, numLessonsInSectionMinusLeftoverFromWeekInPrevSection) / numberOfLessonsPerWeek
      let numberOfInvolvedWeeksInSection = Math.ceil(WeeksPerSectionDecimal) + numberOfWeeksAddedDueToLeftoverFromWeekInPrevSection
      let numberOfFullWeeksInSection = Math.floor(WeeksPerSectionDecimal)
      let idxs = [...Array(numberOfInvolvedWeeksInSection).keys()]      
      let weeksComponent = idxs.map(i => {
        let isLeftoverWeek = i == 0 && hadLeftoverDaysFromWeekInPrevSection
        var perWeekLessonIndex = isLeftoverWeek ? (numberOfLessonsPerWeek - leftoverDaysFromWeekInPrevSection) : 0
        let numberOfLessonsPerThisWeek = Math.min(numLessonsInSection, isLeftoverWeek ? leftoverDaysFromWeekInPrevSection : numberOfLessonsPerWeek)
        let lessonsList = sectionLessons.slice(sectionLessonIndex, sectionLessonIndex + numberOfLessonsPerThisWeek)        
        let lessonTitlesList = sectionLessonTitles.slice(sectionLessonIndex, sectionLessonIndex + numberOfLessonsPerThisWeek)        
        sectionLessonIndex += numberOfLessonsPerThisWeek
        let currWeekIndex = weekIndex + i    
        let lessonsComponent = lessonsList.map((lesson, index) => {
          let lessonTitle = lessonTitlesList[index]
          let lessonComponent = this.renderLessonTitle(lesson, lessonTitle, sectionIndex, currWeekIndex, perWeekLessonIndex, lessonIndex, perWeekLessonIndex, numberOfLessonsPerWeek)
          perWeekLessonIndex++
          lessonIndex++
          return lessonComponent
        })
        return lessonsList.length == 0 ? null : (
          <div key={'week-' + currWeekIndex}>
            <h5 className="size18 weight200 underlined">Week {currWeekIndex + 1}</h5>
            {lessonsComponent}
            {i == numberOfInvolvedWeeksInSection - 1 ? null : <hr/>}                      
          </div>
        )
      })      
      currSectionLessonIndex = nextSectionLessonIndex
      let currLeftover = numLessonsInSectionMinusLeftoverFromWeekInPrevSection >= 0 ? (numLessonsInSectionMinusLeftoverFromWeekInPrevSection % numberOfLessonsPerWeek) : -numLessonsInSectionMinusLeftoverFromWeekInPrevSection
      weekIndex += numberOfFullWeeksInSection + ((hadLeftoverDaysFromWeekInPrevSection && leftoverDaysDromWeekInPrevSectionCovered) ? 1 : 0)
      leftoverDaysFromWeekInPrevSection = numLessonsInSectionMinusLeftoverFromWeekInPrevSection >= 0 ? (currLeftover == 0 ? 0 : (numberOfLessonsPerWeek - currLeftover)) : currLeftover
      nextSectionLessonIndex = (sectionIndex + 2) < numberOfSections ? sectionLessonIndices[sectionIndex + 2] : numberOfLessons      
      return (
        <div key={'section-' + sectionIndex} className="avoid-print-break-inside white-bg boxed-div">
            <h3>{sectionTitle}</h3>
            {weeksComponent}        
        </div>        
      )
    })
    return (
      <div>
          <h1 id="view-course-plan-title" className="bottom-margin20">{curriculumData['single-unit'] ? 'Unit' : 'Course'} Plan</h1>          
          <div>
            {sectionsComponent}      
          </div>
      </div>
    )
  }
  
  renderLessonTitle(lesson, lessonTitle, sectionIndex, weekIndex, dayIndex, lessonIndex, perWeekLessonIndex, numberOfLessonsPerWeek) {  
    let descriptionDivRef = React.createRef()
    this.descriptionsDivs[lesson] = descriptionDivRef
    return (
      <div key={'lesson-div-' + lessonIndex} >
        <h6 key={'lesson-title-' + lessonIndex} className="size18">{(numberOfLessonsPerWeek == 1 ? '' : ('Day ' + (perWeekLessonIndex + 1) + ' ')) + 'Lesson ' + (lessonIndex + 1) + ': '}<span className="weight600">{lessonTitle}</span></h6>
        <div id={'view-description-text-' + lesson} className="boxed-div transparent-background" ref={descriptionDivRef}><em className="opac">description not available...</em></div>
      </div>
    )
  }
  
  renderCurriculumContentResources() {
    let curriculumData = this.page.curriculumData    
    return (            
      <div id="curriculum-body-listing-content-resources">
        <h4>Resources</h4>
        <h6>General course resources not specific to a lesson:</h6>
        {renderBulletListFromStringList('curriculum-resources', curriculumData['resource-descriptions'], curriculumData['resource-urls'], false, undefined, curriculumData['resource-is-externals'])}
      </div>
    )
  }

  renderCurriculumContentTeacherNotes() {
    let curriculumData = this.page.curriculumData
    return (
      <div id="curriculum-body-listing-content-teacher-notes">
        <h4>Teacher Notes</h4>
        <h6>Some notes and tips on how to deliver this curriculum...</h6>
        <span className="form-input-description"><em className="opac">Also look for any notes for the teacher in each lesson&#39;s <strong>teacher notes</strong> as well as the <strong>speaker notes</strong> in the lesson <strong>slides</strong></em>.</span>
        {renderBulletListFromStringList('curriculum-teacher-notes', curriculumData['teacher-notes'])}
      </div>
    )
  }

  renderListingContent() {    
    return (
      <div>
        <div id="view-body-listing-content-info-1" className="A4-print-page">
          {renderLogo(this)}          
          {this.renderListingHeaderInfo()}          
        </div>        
        <div id="view-body-listing-content-info-2" className="A4-print-page">
          {this.renderListingDescriptionInfo()}
        </div>
        <div id="view-body-listing-content-info-3" className="A4-print-page">
          {this.renderCurriculumContentResources()}        
        </div>        
        <div id="view-body-listing-content-info-4" className="A4-print-page">
          {this.renderListingContentCoursePlan()}
        </div>        
        <div id="view-body-listing-content-info-5" className="A4-print-page">
          {this.renderCurriculumContentTeacherNotes()}        
        </div>
      </div>
    )
  }
}

// section containing actual curriculum items listing (shopping cart/library/etc. pages):
export class LibraryListings extends ToggledPagesComponent {

  constructor(props) {
    super(props)
    this.extraClasses = props.extraClasses || ''
    this.itemExtraClasses = props.itemExtraClasses
  }

  getCurriculumListInCurrentPage() {    
    return super.getCurriculumListInCurrentPage(globals.currentPage.getCurriculumsForTab()) || []
  }

  getCurriculumViewerPage() {
    return globals.currentPage.isShowingBoughtCurriculums() ? 'view' : 'preview'
  }

  renderOptionalCurriculumInfo(id, curriculumData) {
    let pageId = globals.currentPage.pageId        
    let gradeTitle = Database.getCurriculumGradeTitle(curriculumData)
    return (
      <div>
        <span id={pageId + '-curriculum-item-topic-' + id} className="block h8size">{curriculumData['topic-title']}</span>
        <span className="curriculum-view-card-grade curriculum-view-grade h8size block">{gradeTitle}</span>
        <span id={pageId + '-curriculum-item-teacher-' + id} className="curriculum-item-view-teacher curriculum-view-teacher h8size">By {curriculumData['teacher-name']}</span>
      </div>
    )
  }

  // renders a Library curriculum item view's inside content
  renderCurriculumItemDivContent(id, curriculumData) {
    let pageId = globals.currentPage.pageId
    let viewerPage = this.getCurriculumViewerPage()
    let imageAddress = curriculumData.imageUrl
    let libraryItemImageAndInfoDiv = (
      <div className="curriculum-item-link">
        <img id={pageId + '-curriculum-item-img-' + id} className="curriculum-view-image curriculum-item-view-image round-corners" src={imageAddress} alt="" />
        <div key={pageId + '-curriculum-item-info-' + id} id={pageId + '-curriculum-item-info-' + id} className="curriculum-item-info-view">
          <div className="flex-row-mobile-portrait-col">
            <span id={pageId + '-curriculum-item-type-' + id} className="logo-color boxed-text curriculum-type-flag h8size">{curriculumData['single-unit'] ? 'Curriculum Unit' : 'Curriculum'}</span>
            {curriculumData['has-instruction-video'] ? <span className="logo-color boxed-text curriculum-type-flag left-margin5or0 right-margin0 h8size">&nbsp;<i className="fas fa-video" aria-hidden="true"></i>&nbsp;&nbsp;Instruction Video</span> : null}
          </div>
          <span id={pageId + '-curriculum-item-title-' + id} className="curriculum-item-view-title curriculum-view-title h5size weight600">{curriculumData.title}</span>            
          <span id={pageId + '-curriculum-item-subtitle-' + id} className="curriculum-item-view-subtitle curriculum-view-subtitle h8size">{curriculumData.subtitle}</span>
          {this.renderOptionalCurriculumInfo(id, curriculumData)}
        </div>
      </div>
    )
    let promo = globals.urlParams.promo
    return renderDivLink(pageId + '-curriculum-item-link-' + id, libraryItemImageAndInfoDiv, viewerPage + '?subject=' + curriculumData.subject + '&id=' + curriculumData.id + (viewerPage === 'preview' ? (promo ? ('&promo=' + promo) : '') : ('&random-key=' + curriculumData['random-key'])))   
  }

  // renders a Library curriculum item view:
  renderCurriculumItemDiv(id, curriculumData) {
    let pageId = globals.currentPage.pageId
    let libraryItemImageAndInfoDivLink = this.renderCurriculumItemDivContent(id, curriculumData)
    let idPrefix = pageId + '-curriculum-item-' + id
    return (
      <div key={idPrefix} id={idPrefix} className={'curriculum-item-view' + (this.itemExtraClasses ? (' ' + this.itemExtraClasses) : '')}>
        {libraryItemImageAndInfoDivLink}        
      </div>
    )
  }

  // function to run before mapping over each item in list, during render
  itemsPreMapFn() {}

  // function to run for each item in list, during render
  itemsInMapFn(curriculumData) {}

  render() {  
    return super.render(globals.currentPage.pageId, (this.extraClasses ? (this.extraClasses + ' ') : '') + 'curriculum-listings-panel panel', this.itemsPreMapFn.bind(this), this.itemsInMapFn.bind(this))
  }
}

// Library tab selection component:
export class LibraryTabSelection extends React.Component {
 
  constructor(props) {
    super(props)
    this.page = props.page
  }  

  // if the search subject pulldown menu is changed, store the current subject in the globals:
  tabChangeFn(e, val) {
    let newVal = parseInt(val, 10) || 0
    if (globals.haveLocalStorage)
      localStorage.setItem(this.page.lastTabOpenLocalStorageKey(), newVal) 
    this.page.setState({tabIndex: newVal}) 
  }

  render() {
    let page = this.page
    return (
      // giving a unique key to be sure next time parent is re-render this component will re-render as well:
      <div id="library-tab-select-panel" className="panel">        
        <OptionsSelect label={page.tabTitles[page.state.tabIndex]} key={getEpochTime()} id={'library-tab-select-panel-options'} optionsDivExtraClasses={'library-tab-select-panel-options-div-options'} options={[0, 1]} optionKeyToTitleFn={(idx, s) => { return page.tabTitles[s] }} optionKeyToTitleIconFn={(idx, s) => { return page.tabTitleIcons[s] }} onChangeFn={this.tabChangeFn.bind(this)} defaultValue={page.state.tabIndex} addDefault={false} />
      </div>
    )
  }
}

// Reviews Listing Panel for a curriculum
export class Reviews extends React.Component {
  constructor(props) {
      super(props)
      this.id = props.id
      props.parent.reviewsComponent = this
      this.state = {
        dataFetched: Database.isReviewsDataFetched(this.id)        
      }
      if (!this.state.dataFetched)
        Database.database_fetchReviewsDataForCurriculum(this.id)      
  }
 
   // renders a Library curriculum item view:
   renderReviewItemDiv(id, reviewData) {  
    let ratingImageWidth = reviewData.rating * 15    
    let reviewParagraphs = reviewData.review.split('<br>').map((p, pIdx) => {
      return (<p key={'view-review-item-' + id + '-' + pIdx}>{p}</p>)
    })
    return (
      <div key={'view-review-item-' + id} id={'view-review-item-' + id} className="review-item-view">
        <h6>{reviewData['reviewer-name'] || 'Anonymous'}</h6>
        {renderRatingStars('review-item', 'review-listing', ratingImageWidth)}        
        {reviewParagraphs}
      </div>
    )
  }

  render() {
    if (this.state.dataFetched) { 
      var idCounter = 0
      let reviews = Database.getReviewsData(this.id)
      if (reviews === undefined)
        return (<div id="view-reviews" className="">No reviews yet</div>)
      const reviewListings = reviews.map((reviewData) => {        
        var id = idCounter++
        let itemView = this.renderReviewItemDiv(id, reviewData)
        return (
          <div id={'view-review-item-container-' + id} key={'view-review-item-container-' + id}>
            {itemView}
          </div>
        )
      })
      return (
        <div id="view-reviews">{reviewListings}</div>
      )
    } else {
      return (
        <div id="view-reviews"></div>
      )
    }
  }
}

// a landing page that also has a dialog box for showing messages
export class LandingPageWithDialogBox extends LandingPage {
  // default render of the body and footer of a landing page (renderBody to be implemented by the subclass):
  renderBodyAndFooter() {
    return renderBodyAndFooterForPageWithPopupDialogbox(this, this.numberOfDialogBoxes || 1)
  }
}

// a page that cannot be viewable if not signed in (have user session). 
// if so redirect to home page:
export class SessionLandingPage extends LandingPage {
  
  // If detecting that user not signed in, redirect back to home page:
  componentDidMount() {    
    if (!isUserSignedIn()) {
      this.setState({navigateTo: '/'})
    } else {
      super.componentDidMount()
    }
  }
}

// a page that cannot be viewable if not signed in (have user session). 
// if so redirect to home page:
export class SessionLandingPageWithDialogBox extends LandingPageWithDialogBox {
  
  // If detecting that user not signed in, redirect back to home page:
  componentDidMount() {  
    if (!isUserSignedIn()) {
      this.setState({navigateTo: '/'})    
    } else {
      super.componentDidMount()
    }
  }
}

// a landing page that requires 1) user session active (signed in) and 2) user has already purchased curriculum with id:
export class RestrictedAccessSessionLandingPage extends SessionLandingPageWithDialogBox {
  constructor(props) {
      super(props)
      this.subject = globals.urlParams.subject
      this.id = globals.urlParams.id
      this.isCurriculumAccessGranted = false
      this.state = {
        dataFetched: false
      }
      // start up fileserver to allow the user to download curriculum content
      if (!globals.fileserverInit)
        Fileserver.fileserver_initConnection()
  }
  
  userDataFetched() {
      super.userDataFetched()      
      // if user hasn't bought the curr id we're currently viewing, they have not permission. Redirect to home:
      if (Database.getUserBoughtCurriculums().indexOf(this.id) === -1) {
          this.setState({navigateTo: '/'})
      }
  }

  // Database API calls this when curriculum info for the given id is fetched. Here we check to make sure the curriculum's random key matches the url param:
  curriculumsDataFetched() {      
    this.checkValidAccessForPage()
  }

  checkValidAccessForPage() {
    checkValidAccessForRestrictedAccessPage(this)
  }

  // which page to navigate to if curriculum with id on url params not found:
  accessNotAllowedNavigationPage() {
    return '/'
  }
}

// options select div where data can be updated via state:
export class UpdatableOptionsSelect extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        options: []
      }
      this.page = props.page
      this.extraClasses = props.extraClasses      
      this.optionsSelectComponent = React.createRef()      
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.optionsSelectComponent.current.setState({options: this.state.options})
  }

  // subclass to define:
  onChangeFn(e, val) {
  }

  render() {
    let props = this.props
    return (      
      <OptionsSelect label={props.label} id={props.id} containerExtraClasses={props.extraClasses} options={this.state.options} optionKeyToTitleFn={props.optionKeyToTitleFn} optionKeyToTitleIconFn={props.optionKeyToTitleIconFn} onChangeFn={this.onChangeFn.bind(this)} defaultLabel={props.defaultLabel} defaultValue={props.defaultValue || ''} addDefault={props.addDefault || false} currentValue={props.currentValue} disabled={props.disabled || false} ref={this.optionsSelectComponent} />
    )
  } 
}

// renders a pull down (select) "select option" menu:
export class OptionsSelect extends React.Component {

  constructor(props) { 
    super(props)
    this.state = {
      label: props.label,
      options: props.options
    }
    this.id = props.id
    this.containerExtraClasses = props.containerExtraClasses
    this.optionsDivExtraClasses = props.optionsDivExtraClasses
    this.optionKeyToTitleFn = props.optionKeyToTitleFn
    this.optionKeyToTitleIconFn = props.optionKeyToTitleIconFn
    this.onChangeFn = this.onChangeFn ? this.onChangeFn.bind(this) : props.onChangeFn
    this.defaultValue = props.defaultValue
    this.defaultLabel = props.defaultLabel || props.label
    this.addDefault = props.addDefault 
    this.currentValue = props.currentValue || this.defaultValue
    this.disabled = props.disabled || false
    this.noBackgroundLayer = props.noBackgroundLayer === true // set true in problematic cases where select menu is only visible on hover...
    this.optionsDivClasses = 'select-div-options-container-left select-div-options-container'
    this.selectDiv = React.createRef() 
    this.selectButton = React.createRef() 
    this.optionsDiv = React.createRef() 
  }

  // gets the current value:
  getValue() {
    return this.selectButton.current.getAttribute('value')
  }

  // sets the current value:
  setValue(newVal) {
    let oldVal = this.selectButton.current.getAttribute('value')
    // skip if value has not changed:  
    if (oldVal !== newVal) {
      this.selectButton.current.setAttribute('value', newVal)
      this.setState({label: this.state.label})
    }
  }

  // turns on/off "select option" pull down menu:
  optionsOnClick() {
    let button = this.selectButton.current
    if (button) {
      let on = button.isActive || false
      on = !on
      button.isActive = on
      handleSelectComponentToggle(this, on)
      fadeToggleElementOnAndOff(this.optionsDiv.current, this.optionsDivClasses + (this.optionsDivExtraClasses ? ' ' + this.optionsDivExtraClasses : '') + ' flex-col', 'flex', on)
    }
  }

  // wrapper for user's "select option" select element onchange function:
  augmentedSelectOnChangeFn(e) {    
    let onChangeFn = this.onChangeFn
    let oldVal = this.selectButton.current.getAttribute('value')
    let newVal = e.target.getAttribute('value') 
    // skip if value has not changed:    
    if (oldVal !== newVal) {
      this.selectButton.current.setAttribute('value', newVal)      
      // do user's onchange
      if (onChangeFn !== undefined)
        onChangeFn(e, newVal)
      // also change the select element's label:
      let pulldownOptionElement = e.target
      copyElementContentsFromAnotherAddFontAwesomeIcon(this.selectButton.current, pulldownOptionElement, 'fas fa-angle-down', this.optionKeyToTitleIconFn ? this.optionKeyToTitleIconFn(0, newVal) : undefined)
    }
    //and and make select options hide:
    this.optionsOnClick()
  }

  render() {    
    let id = this.id
    let buttonClass = this.disabled ? '' : ' button'
    const optionsComponents = this.state.options.map((s, idx) => {        
      return (
        renderButtonWithKey(this.optionKeyToTitleFn(idx, s), this.optionKeyToTitleIconFn ? this.optionKeyToTitleIconFn(idx, s) : undefined, id + '-' + s, s, 'select-option text-button button', (e) => { this.augmentedSelectOnChangeFn(e) })
      )
    })
    let optionsDiv = (
      <div ref={this.optionsDiv} className={'select-div-options-container-left select-div-options-container' + (this.optionsDivExtraClasses ? ' ' + this.optionsDivExtraClasses : '') + ' flex-col'}>
        <div className="tooth tooth-left"></div>
        <div id={id + '-div-options'} className={'scroll-y-visible scroll-visible select-div-options flex-col'} >          
          {this.addDefault ? renderButtonWithKey(this.defaultLabel, this.optionKeyToTitleIconFn ? this.optionKeyToTitleIconFn(0, this.defaultValue) : undefined, id + '-default', this.defaultValue, 'select-option text-button button', (e) => { this.augmentedSelectOnChangeFn(e)}) : undefined}
          {optionsComponents}
        </div>
      </div>
    )
    return (
      <div id={id + '-container'} className={'labeled-select-div select-div' + (this.containerExtraClasses ? ' ' + this.containerExtraClasses : '')} ref={this.selectDiv} >
        <div className={'select-button-container' + buttonClass} onClick={this.disabled ? undefined : (e) => this.optionsOnClick()}>
          {renderButtonWithRightIconWithRef(this.state.label, this.optionKeyToTitleIconFn ? this.optionKeyToTitleIconFn(0, this.currentValue) : undefined, this.disabled ? undefined : 'fas fa-angle-down', id, this.currentValue, 'select-button' + (this.disabled ? ' disabled-button ' : ' text-button') + buttonClass, undefined, this.selectButton)}
        </div>
        {optionsDiv}
      </div>
    )
  }
}

// a pull down menu of buttons and/or links
export class ButtonOrLinkMenuSelect extends OptionsSelect {

  constructor(props) {
    super(props)
    this.allOptionsAreButtons = props.allOptionsAreButtons === true
    this.optionsAreLinks = props.optionsAreLinks
    this.header = props.header
    this.selectButtonIcon = props.selectButtonIcon || 'fas fa-angle-down'
    this.selectButtonIconLeftElement = props.selectButtonIconLeftElement 
    this.optionsDivClasses = 'select-div-options-container-right menu-div-options-container select-div-options-container'
  }

  // renders a pull down (select) "links" menu (can have a header on top)
  render() {
    let options = this.state.options
    let id = this.id
    const optionsComponents = Object.keys(options).map((s) => {
      let isLink = !this.allOptionsAreButtons && (!this.optionsAreLinks || this.optionsAreLinks[s])
      let labelIcon = this.optionKeyToTitleIconFn ? this.optionKeyToTitleIconFn[s] : undefined
      let optionId = id + '-' + s
      let optionClassName = 'select-option text-button button'
      let optionLinkOrOnClick = options[s]
      return isLink ?
          renderLinkWithKey(s, optionId, optionClassName, optionLinkOrOnClick, labelIcon) :
          renderButtonWithKey(s, labelIcon, optionId, s, optionClassName, (e) => { this.optionsOnClick(); optionLinkOrOnClick(e)})      
    })
    let optionsDiv = (
      <div className={'select-div-options-container-right menu-div-options-container select-div-options-container' + (this.optionsDivExtraClasses ? ' ' + this.optionsDivExtraClasses : '') + ' flex-col'} ref={this.optionsDiv}>
        <div className="tooth tooth-right"></div>
        <div id={id + '-div-options'} className={'scroll-y-visible scroll-visible menu-div-options select-div-options flex-col'} >
          {this.header || null}
          {optionsComponents}
        </div>
      </div>
    )
    let selectButtonComponent = (
      <div className="flex-row flex-center">
        {this.selectButtonIconLeftElement || null}
        <i className={this.selectButtonIcon}></i>
      </div>)    
    return (
      <div id={id + '-container'} className={'menu-div select-div' + (this.containerExtraClasses ? ' ' + this.containerExtraClasses : '')} ref={this.selectDiv} >
        {renderButtonWithRef(selectButtonComponent, id, 'select-button text-button button', (e) => this.optionsOnClick(), undefined, this.selectButton)}
        {optionsDiv}
      </div>
    )
  }
}

// Popup Dialog Box showing a given content inside it set by the parent component:
export class DialogBoxPopUp extends React.Component {

  constructor(props) { 
    super(props)
    this.buttonLabels = ['OK']    
    this.index = props.index
    this.defaultButtonLabels = ['OK', 'Cancel']
    this.contentComponent = React.createRef()
    this.dialogBox = React.createRef()
  }
  
  render() {
    let index = this.index
    let idPrefix = 'dialog-box-' + index
    let dialogBox = this.dialogBox.current
    // color negative buttons differently:
    let firstButtonIsNegative = dialogBox && 
      this.buttonLabels.length === 2 && 
      dialogBox.onButtonFns && dialogBox.onButtonFns.length > 0 && dialogBox.onButtonFns[0] === undefined    
    let buttonComponents = this.buttonLabels.map((label, index) => {
      return renderButtonAddKey(label, 'dialog-box-button-' + index, 'top-margin15 ' + (index === 0 && firstButtonIsNegative ? 'clear-shadowed-text-button' : 'shadowed-text-button') + ' bordered-text-button min-width-text-button text-button button', async (e) => await this.dialogButtonClickedFn(index))
    })
    return (
      <div id={idPrefix} className="dialog-box hidden" ref={this.dialogBox} >
        <div id={idPrefix + '-bg'} className="dark-layer-bg"></div>
        <div id={idPrefix + '-inner'} className="dark-layer-bg-inner dialog-box-inner">
          <div id={idPrefix + '-content'} className="dialog-box-content">
            <DialogBoxPopUpContent ref={this.contentComponent} />
          </div>
          {renderImage(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/x-icon.png', 'dialog-box-exit-button', 'exit-button rounded-button button', (e) => this.dialogBoxToggleFn(false))}
          <div className="flex-row flex-center">
            {buttonComponents}
          </div>
        </div>
      </div>
    )
  }
  
  // displays a dialog box with text inside (optional fontawesome icon class to put on top):
  showTextDialog(text, topIconClassName, onCloseFn, buttonLabels, onButtonFns, showWaitingDialogAfterClick = false) {    
    let dialogContent = text.split('\n').map((l, idx) => <p key={idx}>{l}</p>)
    if (topIconClassName) {
      dialogContent = (
        <div className="col-div">
          <i className={topIconClassName + ' size36-no-weight bottom-margin35'}></i>
          {dialogContent}
      </div>)
    }
    this.showDialog(dialogContent, onCloseFn, buttonLabels, onButtonFns, showWaitingDialogAfterClick)
  }

  // displays a dialog box with content inside:
  showDialog(contentElement, onCloseFn, buttonLabels = [this.defaultButtonLabels[0]], onButtonFns = [undefined], showWaitingDialogAfterClick = false) {    
    buttonLabels = buttonLabels.map((l, index) => l || this.defaultButtonLabels[index])    
    if (!arrayEquals(this.buttonLabels, buttonLabels)) {
      this.buttonLabels = buttonLabels
      this.forceUpdate()
    }
    let contentComponent = this.contentComponent.current    
    if (contentComponent) {
      contentComponent.setState({content: contentElement})
      this.dialogBoxToggleFn(true, onCloseFn, onButtonFns, showWaitingDialogAfterClick)
    }
  }
  
  // to programmatically close the dialog:
  closeDialog(showWaitingDialogAfterClick = false) {
    this.dialogBoxToggleFn(false, undefined, undefined, showWaitingDialogAfterClick)
  }

  // dialog for prompting user to wait while something is in progress...:
  showWaitingDialog(waitingMessage = 'Just a moment...') {
    this.showDialog(
    <div className="col-div">
      {renderWaitingIcon(50)}
      <p className="top-margin40">{waitingMessage}</p>
    </div>, undefined, [], [])
  }

  // called when button at index was clicked:
  // only closes dialog box if button click handler fn returns true
  async dialogButtonClickedFn(index) {
    if (this.buttonsDisabled)
      return
    // disable the buttons since a button was already clicked:
    this.buttonsDisabled = true
    let dialogBox = this.dialogBox.current
    var success = true
    let onButtonFn = dialogBox.onButtonFns[index]
    // "cancel" button was clicked, no need to show the waiting dialog if it was set:
    if (onButtonFn === undefined) 
      delete dialogBox.showWaitingDialogAfterClick
    else // run the function of the clicked button:
      success = await onButtonFn()
    this.buttonsDisabled = false
    // don't auto close the dialog box if fn failed. let the caller handle it:
    if (success) {
      this.dialogBoxToggleFn(false, undefined, undefined, dialogBox.showWaitingDialogAfterClick || false)
    }      
  }

  // fades in/out dialog box to show an alert:
  // if it has an onCloseFn will call it once it's closed...
  dialogBoxToggleFn(on, onCloseFn, onButtonFns, showWaitingDialogAfterClick = false) {
    let dialogBox = this.dialogBox.current
    if (on) {
      dialogBox.style.opacity = 1
      dialogBox.className = 'dialog-box fade-in'
      dialogBox.style.display = 'block'    
      if (onCloseFn !== undefined)
        dialogBox.onCloseFn = onCloseFn
      if (onButtonFns !== undefined)
        dialogBox.onButtonFns = onButtonFns
      dialogBox.showWaitingDialogAfterClick = showWaitingDialogAfterClick
    } else {
      dialogBox.style.opacity = 0
      dialogBox.className = 'dialog-box fade-out'    
      setTimeout((e) => { 
        dialogBox.style.display = 'none'      
        if (dialogBox.onButtonFns !== undefined) {          
          delete dialogBox.onButtonFns        
        }
        if (dialogBox.onCloseFn !== undefined) {
          dialogBox.onCloseFn()        
          delete dialogBox.onCloseFn        
        }
        if (showWaitingDialogAfterClick)
          this.showWaitingDialog()
        delete dialogBox.showWaitingDialogAfterClick
      }, 100)
    }
  }
  
}

class DialogBoxPopUpContent extends React.Component {
  constructor(props) { 
    super(props)
    this.state = {
      content: null
    }
  }

  render() {
    return this.state.content
  }

}


/*

      Utility Functions

*/

// checks that url params have the necessary keys to view a private curriculum related page:
export function checkValidAccessForRestrictedAccessPage(page) {
  page.curriculumData = Database.getCurriculumData(page.id)
  if (page.curriculumData !== undefined) {
    let urlRandomKey = globals.urlParams['random-key']    
    if (!(urlRandomKey && urlRandomKey === page.curriculumData['random-key'])) {
      page.setState({navigateTo: page.accessNotAllowedNavigationPage()})
    }
  }
}

// Following 4 functions are used for iframes which get their contents from external html files stored on the fileservers: step *** A *** is created by component classes themselves to create/render the iframes...:

// *** A *** Some of curriculum info (course and each lesson descriptions) comes from external HTML files sitting in the fileserver. We get those content by having invisible iframes to those html files, whose scripts will asyncronously send (postMessage) to this page their content DOM as a string. 
  // Here we create those iframes to initiate this process, and in another place we will receive the sent messages and convert the string into DOM elements to place in the right positions in this page...:
export function renderExternalIframes(stateHolderComponent, callerComponent, curriculumOrLessonData, doLessons) {
  if (stateHolderComponent.state.dataFetched && curriculumOrLessonData.description) {
    callerComponent.handleReceivingContentFromExternalIframes()    
    // add a unique url param to iframe src to bypass browser cache, to ensure user always gets the updated descriptions:
    let srcParams = '?time=' + getEpochTime()
    let lessonIframes
    if (doLessons) {
      // get template for url of lessons descriptions, and replace the @ with each lesson id:
      let lessonDescriptionUrlTemplate = curriculumOrLessonData.lessonDescriptionUrlTemplate + srcParams      
      lessonIframes = curriculumOrLessonData.lessons.map(l => {
          let id = 'description-text-iframe-' + l
          return (<iframe id={id} key={id} title="description-text-iframe" className="imported-html-iframe" src={lessonDescriptionUrlTemplate.replace('@', encodeURIComponent(l))} />)
      })
    } else
      lessonIframes = null
    return (
      <div>
        <iframe id={'description-text-iframe-' + curriculumOrLessonData.id} key="description-text-iframe-curriculum"  title="description-text-iframe" className="imported-html-iframe" src={curriculumOrLessonData.description + srcParams} />
        {lessonIframes}
      </div>
    )
  } else return
}

// *** B *** here we will handle/receive the sent messages and convert the string into DOM elements to place in the right positions in this page...: 
export function handleReceivingContentFromExternalIframes(callerComponent) {
  window.onmessage = (event) => {
    // skip message if it didn't come from the external iframe source sitting on the fileserver:
    if (event.origin !== fsConfig.rootBucketUrl)
      return
    let iframeHtmlText = event.data.replace(/\\/g, '')
    let body = parseHTMLBodyFromString(iframeHtmlText)
    let descriptionElement = body.children[0]
    let lesson = descriptionElement.getAttribute('item')    
    callerComponent.descriptionsDivContents[lesson] = descriptionElement
    callerComponent.insertContentFromExternalIframeIntoDOM([lesson])
  }
}

// *** B *** same as above, but for creator-edit and creator-edit-lesson pages, to put the content of external html into the text editor where description is edited:
// for edit-curriculum page this serves both purposes of getting the previously saved editable curriciulum description, as well as the lessons' desciption for submission sanity checks to make sure all lessons within this curriculum have saved descriptions...
export function handleReceivingContentFromExternalIframesForTextEditor(callerComponent, doLessonsForCurriculumWithId) {
  window.onmessage = (event) => {
    // skip message if it didn't come from the external iframe source sitting on the fileserver:
    if (event.origin !== fsConfig.rootBucketUrl)
        return
    let iframeHtmlText = event.data.replace(/\\/g, '')
    let body = parseHTMLBodyFromString(iframeHtmlText)
    let descriptionElement = body.children[0]
    let descriptionDivContents = descriptionElement.innerHTML    
    if (doLessonsForCurriculumWithId !== undefined) {
      let lessonOrCurriculumId = descriptionElement.getAttribute('item')      
      // if this is description for a lesson inside the curr, not the description of curr itself:
      if (lessonOrCurriculumId !== doLessonsForCurriculumWithId) {
        let lessonComponent = callerComponent.lessonsData[lessonOrCurriculumId]
        if (lessonComponent !== undefined)        
          lessonComponent.descriptionText = descriptionDivContents
        return
      }
    }
    callerComponent.descriptionText = descriptionDivContents.replaceAll('<p></p>', '').replaceAll('\n', '')
    callerComponent.formDescriptionTextEditor.current.setContentStatefromHtml(descriptionDivContents)
  }
}

// *** C **** Here we place the received content in the right position in this page:
  // NOTE: list of [id]: id of curriculum/lesson: this is description for curriculum, or lesson 
export function insertContentFromExternalIframeIntoDOM(callerComponent, ids) {
  ids.forEach(id => {
    let descriptionComponent = callerComponent.descriptionsDivs[id]
    // if the iframe element is rendered already, we're ready to insert its content:
    if (descriptionComponent && descriptionComponent.current) {      
      let descriptionContent = callerComponent.descriptionsDivContents[id]
      let descriptionElement = descriptionComponent.current
      // remove old content first:
      while (descriptionElement.firstChild)
        descriptionElement.removeChild(descriptionElement.firstChild)
      // add new externally sent content:
      descriptionElement.appendChild(descriptionContent)        
      // remove it from list of todos if it's there:
      let idx = callerComponent.setDescriptionsDivContent.indexOf(id)
      if (idx > -1)
        callerComponent.setDescriptionsDivContent.splice(idx, 1)
    } else // if description div not created yet, set a flag to wait until it's ready:
      callerComponent.setDescriptionsDivContent.push(id)
  })
}

// turn off any other select element currently active and make sure this select component is on top of everything else in the page:
function handleSelectComponentToggle(selectComponent, on) {
    let page = globals.currentPage    
    if (on) {
      // toggle off any select element that was previously on:
      if (page.selectComponentCurrentlyActive)
        page.selectComponentCurrentlyActive.optionsOnClick()
      // set a flag that this select element is currently active:
      page.selectComponentCurrentlyActive = selectComponent  
    } else
      delete page.selectComponentCurrentlyActive
    if (!selectComponent.noBackgroundLayer && page.selectComponentsBackgroundLayerRef.current)
      page.selectComponentsBackgroundLayerRef.current.className = 'full-page-bg-layer ' + (on ? 'block' : 'none')
    // make sure this menu shows on top of all else:
    selectComponent.optionsDiv.current.style['z-index'] = on ? 10 : 3     
}

// turn off any other select element currently active and make sure this select component is on top of everything else in the page:
export function turnOffAnySelectComponentCurrentlyActive() {
  let page = globals.currentPage
  if (page.selectComponentCurrentlyActive)
    page.selectComponentCurrentlyActive.optionsOnClick()
}

// Returns a wrapper div adding previous and next buttons to a toggle-paged view:
export function 
renderToggledPagesView(toggledPages, currPageIdx, numToggledPages, togglePageIndexFn, wrapperDivId, wrapperDivClassName, innerDivId, innerDivClassName, buttonsIdPrefix, buttonsClassNamePrefix, dontShowIfSinglePage) {
  let prevToggleFn = currPageIdx === 0 ? ((e) => {}) : ((e) => togglePageIndexFn(e, false))
  let nextToggleFn = currPageIdx === (currPageIdx === numToggledPages - 1) ? ((e) => {}) : ((e) => togglePageIndexFn(e, true))
  let prevToggleClassNameAddr = currPageIdx === 0 ? ' hidden' : ' button'
  let nextToggleClassNameAddr = (currPageIdx >= numToggledPages - 1) ? ' hidden' : ' image-button button'
  // Only render toggle page buttons if have more than 1 page to show:
  if (!dontShowIfSinglePage || numToggledPages > 1) {
    return (
      <div id={wrapperDivId} className={(wrapperDivClassName ? (wrapperDivClassName + ' ') : '') + 'page-toggle'}>
          {renderButtonFromButton(<button><i className="fas fa-angle-left"></i></button>, buttonsIdPrefix + '-prev', buttonsClassNamePrefix + ' toggle-button round-border image-button' + prevToggleClassNameAddr, prevToggleFn)}   
          <div id={innerDivId} className={innerDivClassName}>
              {toggledPages}
          </div>
          {renderButtonFromButton(<button><i className="fas fa-angle-right"></i></button>, buttonsIdPrefix + '-next', buttonsClassNamePrefix + ' toggle-button round-border image-button' + nextToggleClassNameAddr, nextToggleFn)}
      </div>
    )
  } else {
    return <div id={wrapperDivId} className={(wrapperDivClassName ? (wrapperDivClassName + ' ') : '') + 'page-toggle'}></div>
  }
}

// we use this cache to enable lessons linking to prev and next lesson for easy navigation:
// this function is not necessary since it's done during rendering of curriculum view page.
// it's only used when a lesson page is loaded as a landing page (hence curriculum view 
// page hasn't happened and this lessons links cache isn't computed yet):
// HACK FIX ME - duplicated the renderListingContentCoursePlan function without cleaning up
// unneeded code yet!
export function renderListingContentCoursePlanAndStoreLessonsPrevNextLinksCache(curriculumData, callerComponent) {  
  let sectionLessonIndices = curriculumData['section-lesson-indices']
  let lessons = curriculumData['lessons']
  let lessonTitles = curriculumData['lesson-titles']
  let numberOfSections = sectionLessonIndices.length
  let numberOfLessons = curriculumData['num-lessons']    
  let numberOfLessonsPerWeek = curriculumData['num-lessons-per-week']    
  // 'lessons-prev-next-info': we use this cache to enable lessons linking to 
  // prev and next lesson for easy navigation:
  let lessonsPrevNextInfo = curriculumData['lessons-prev-next-info']    
  if (lessonsPrevNextInfo == null) {
    lessonsPrevNextInfo = {}
    let everyLessonIdxs = [...Array(numberOfLessons).keys()]      
    everyLessonIdxs.forEach(i => {lessonsPrevNextInfo[i] = {}})
    curriculumData['lessons-prev-next-info'] = lessonsPrevNextInfo
  }    
  var currSectionLessonIndex = sectionLessonIndices[0]
  var nextSectionLessonIndex = numberOfSections > 1 ? sectionLessonIndices[1] : numberOfLessons
  var weekIndex = 0, lessonIndex = 0, leftoverDaysFromWeekInPrevSection = 0
  let sectionsComponent = curriculumData['section-titles'].map((st, sectionIndex) => {
    let sectionTitle = st || 'Unit'
    var sectionLessonIndex = 0
    let sectionLessons = lessons.slice(currSectionLessonIndex, nextSectionLessonIndex)
    let sectionLessonTitles = lessonTitles.slice(currSectionLessonIndex, nextSectionLessonIndex)
    let numLessonsInSection = nextSectionLessonIndex - currSectionLessonIndex
    let hadLeftoverDaysFromWeekInPrevSection = leftoverDaysFromWeekInPrevSection > 0
    let numLessonsInSectionMinusLeftoverFromWeekInPrevSection = numLessonsInSection - leftoverDaysFromWeekInPrevSection
    let leftoverDaysDromWeekInPrevSectionCovered = numLessonsInSectionMinusLeftoverFromWeekInPrevSection >= 0
    let numberOfWeeksAddedDueToLeftoverFromWeekInPrevSection = hadLeftoverDaysFromWeekInPrevSection ? 1 : 0
    let WeeksPerSectionDecimal = Math.max(0, numLessonsInSectionMinusLeftoverFromWeekInPrevSection) / numberOfLessonsPerWeek
    let numberOfInvolvedWeeksInSection = Math.ceil(WeeksPerSectionDecimal) + numberOfWeeksAddedDueToLeftoverFromWeekInPrevSection
    let numberOfFullWeeksInSection = Math.floor(WeeksPerSectionDecimal)
    //console.log('sectionIndex', sectionIndex, 'numLessonsInSection', numLessonsInSection, 'leftoverDaysFromWeekInPrevSection', leftoverDaysFromWeekInPrevSection, 'numberOfWeeksAddedDueToLeftoverFromWeekInPrevSection', numberOfWeeksAddedDueToLeftoverFromWeekInPrevSection, 'WeeksPerSectionDecimal', WeeksPerSectionDecimal, 'numberOfInvolvedWeeksInSection', numberOfInvolvedWeeksInSection, 'numberOfFullWeeksInSection', numberOfFullWeeksInSection, 'numLessonsInSectionMinusLeftoverFromWeekInPrevSection', numLessonsInSectionMinusLeftoverFromWeekInPrevSection)
    let idxs = [...Array(numberOfInvolvedWeeksInSection).keys()]      
    let weeksComponent = idxs.map(i => {
      let isLeftoverWeek = i == 0 && hadLeftoverDaysFromWeekInPrevSection
      var perWeekLessonIndex = isLeftoverWeek ? (numberOfLessonsPerWeek - leftoverDaysFromWeekInPrevSection) : 0
      let numberOfLessonsPerThisWeek = Math.min(numLessonsInSection, isLeftoverWeek ? leftoverDaysFromWeekInPrevSection : numberOfLessonsPerWeek)
      let lessonsList = sectionLessons.slice(sectionLessonIndex, sectionLessonIndex + numberOfLessonsPerThisWeek)        
      let lessonTitlesList = sectionLessonTitles.slice(sectionLessonIndex, sectionLessonIndex + numberOfLessonsPerThisWeek)        
      sectionLessonIndex += numberOfLessonsPerThisWeek
      let currWeekIndex = weekIndex + i    
      let lessonsComponent = lessonsList.map((lesson, index) => {
        let lessonTitle = lessonTitlesList[index]
        let lessonComponent = callerComponent == null ? null : callerComponent.renderLessonTitle(lesson, lessonTitle, sectionIndex, currWeekIndex, perWeekLessonIndex, lessonIndex, perWeekLessonIndex, numberOfLessonsPerWeek)
        let currPrevNextInfo = {lesson: lesson, section: sectionIndex + 1, week: currWeekIndex + 1, day: perWeekLessonIndex + 1}
        if (lessonIndex > 0 && lessonsPrevNextInfo[lessonIndex - 1].next == null)
          lessonsPrevNextInfo[lessonIndex - 1].next = currPrevNextInfo
        if (lessonIndex < numberOfLessons - 1 && lessonsPrevNextInfo[lessonIndex + 1].prev == null)
          lessonsPrevNextInfo[lessonIndex + 1].prev = currPrevNextInfo
        perWeekLessonIndex++
        lessonIndex++          
        return lessonComponent
      })
      return lessonsList.length == 0 ? null : (
        <Collapsible key={'week-' + currWeekIndex} trigger={'Week ' + (currWeekIndex + 1)} open={true} transitionTime={200} classParentString="week-title-collapsible Collapsible">
          {lessonsComponent}
        </Collapsible>
      )
    })
    currSectionLessonIndex = nextSectionLessonIndex
    let currLeftover = numLessonsInSectionMinusLeftoverFromWeekInPrevSection >= 0 ? (numLessonsInSectionMinusLeftoverFromWeekInPrevSection % numberOfLessonsPerWeek) : -numLessonsInSectionMinusLeftoverFromWeekInPrevSection
    //console.log('currLeftover', currLeftover, 'weekIndex += ', numberOfFullWeeksInSection + ((hadLeftoverDaysFromWeekInPrevSection && currLeftover == 0) ? 1 : 0))
    weekIndex += numberOfFullWeeksInSection + ((hadLeftoverDaysFromWeekInPrevSection && leftoverDaysDromWeekInPrevSectionCovered) ? 1 : 0)
    leftoverDaysFromWeekInPrevSection = numLessonsInSectionMinusLeftoverFromWeekInPrevSection >= 0 ? (currLeftover == 0 ? 0 : (numberOfLessonsPerWeek - currLeftover)) : currLeftover
    nextSectionLessonIndex = (sectionIndex + 2) < numberOfSections ? sectionLessonIndices[sectionIndex + 2] : numberOfLessons
    return (
      <Collapsible key={'section-' + sectionIndex} trigger={sectionTitle} open={true} transitionTime={200} classParentString={(sectionIndex === 0 ? 'first-unit-title-collapsible ' : '') + 'unit-title-collapsible Collapsible'}>
          {weeksComponent}
      </Collapsible>
    )
  })  
  return callerComponent == null ? null : (
    <div className="tab">
      {callerComponent.renderListingContentCoursePlanHeader()}
      <div id="view-body-listing-content-course-plan" className="boxed-div listing-table flex-col">
        {renderButton('Expland', 'course-plan-explan-all-button', 'flex-end-item clear-shadowed-text-button bordered-text-button text-button button', (e) => callerComponent.parent.toggleExpandCoursePlanDescriptions(e.target))}
        {sectionsComponent}
      </div>
    </div>
  )
}

// wrapper for user's onClick fn so that click event is sent to analytics
function onClickWithAnalytics(buttonId, onClick) {
  return analyticsConfig.analyticsOn ?
    ((e) => {
      // Analytics - record button click:      
      ReactGA.event({
        category: 'Click',
        action: globals.currentPage.pageId,
        label: buttonId
      })      
      // do user's onClick:
      if (onClick !== undefined)
        onClick(e)
  }) :
  onClick
}

// Text button
export function Button(props) {
    //let onClick = props.onClick
    let onClick = onClickWithAnalytics(props.id, props.onClick)
    return (
      <span id={props.id} className={props.className} onClick={onClick}>{props.value}</span>
    )
}

// Text button
export function ButtonWithKey(props) {
  //let onClick = props.onClick
  let onClick = onClickWithAnalytics(props.id, props.onClick)
  let label = props.label, labelIcon = props.labelIcon
  return labelIcon ?
    (<span id={props.id} className={props.className} value={props.value} onClick={onClick}>
      <i className={'label-icon ' + labelIcon}></i>&nbsp;&nbsp;{label}
    </span>)
      : (<span id={props.id} className={props.className} value={props.value} onClick={onClick}>{label}</span>)
}

// Text button
export function ButtonWithValue(props) {
  //let onClick = props.onClick
  let onClick = onClickWithAnalytics(props.id, props.onClick)
  return (
    <span id={props.id} className={props.className} value={props.currentvalue} onClick={onClick} ref={props.ref} >{props.value}</span>
  )
}

// Image 
export function Image(props) {
  //let onClick = props.onClick
  let onClick = onClickWithAnalytics(props.id, props.onClick)
  return (
    <img id={props.id} className={props.className} src={props.src} alt="" onClick={onClick} />
  )
}

// renders termeric logo
export function renderLogo(page, id, className, link) {
  // for pages that need to show a prompt to warn user about navigating away from the page before saving work:
  return page.hasAwayNavigationPrompt ?
    renderImage(generalConfig.logoUrl, id || 'top-logo', className || 'button logo', (e) => page.promptForSaveBeforeNavigatingAway.bind(page)('/' + link))
    : renderImageLink(generalConfig.logoUrl, id || 'top-logo', className || 'logo', link || '')
}

// Div that directs to a new page
export function renderDivLink(id, div, to) {
    return (
        <Link key={id} id={id} to={'/' + to}>
            {div}
        </Link>
    )
}

// Image that directs to a new page
export function renderImageLink(imageAddress, id, className, to) {
  return (
      <Link id={id} to={'/' + to}>
          <img src={imageAddress} className={className} alt="" /> 
      </Link>
  )
}

// Link that directs to a new page
export function renderLink(label, id, className, to, options) {
    return (
      options !== undefined && options.tabIndex !== undefined ?
        (<Link id={id} className={className} tabIndex={options.tabIndex} to={'/' + to}>{label}</Link>) :
        (<Link id={id} className={className} to={'/' + to}>{label}</Link>)
    )
}

// Link that directs to a new page (forces refresh)
export function renderReloadingLink(label, id, className, to, options) {
  return (
    options !== undefined && options.tabIndex !== undefined ?
      (<Link reloadDocument id={id} className={className} tabIndex={options.tabIndex} to={'/' + to}>{label}</Link>) :
      (<Link reloadDocument id={id} className={className} to={'/' + to}>{label}</Link>)
  )
}

// Link that directs to a new page
export function renderLinkWithKey(label, id, className, to, labelIcon) {
  return labelIcon ?
    (<Link id={id} key={id} className={className} to={'/' + to}><i className={'label-icon ' + labelIcon}></i>&nbsp;&nbsp;{label}</Link>)  
    : (<Link id={id} key={id} className={className} to={'/' + to}>{label}</Link>)  
}

// Link that directs to an external page
export function renderExternalLink(label, id, className, to, newTab = true) {
  return (
      <a id={id} className={className} href={to} target={newTab ? '_blank' : '_self'}>{label}</a>
  )
}

// Image that directs to an external page
export function renderExternalImageLink(imageAddress, id, className, to, newTab = true) {
  return (
    <a id={id} href={to} className="flex-row" target={newTab ? '_blank' : '_self'}>
      <img className={className} src={imageAddress} alt="" />
    </a>
  )
}

export function renderButton(label, id, className, onClick, options) {
    return (
      options !== undefined && options.tabIndex !== undefined ?
        (<Button value={label} id={id} className={className} tabIndex={options.tabIndex} onClick={onClick} />) :
        (<Button value={label} id={id} className={className} onClick={onClick} />)
    )
}

export function renderButtonWithRef(label, id, className, onClick, options, componentRef) {  
  //let finalOnClick = onClick
  let finalOnClick = onClickWithAnalytics(id, onClick)
  return (
    options !== undefined && options.tabIndex !== undefined ?    
      (<span id={id} className={className} tabIndex={options.tabIndex} onClick={finalOnClick} ref={componentRef} >{label}</span>) :
      (<span id={id} className={className} onClick={finalOnClick} ref={componentRef} >{label}</span>)
  )
}

export function renderButtonAddKey(label, id, className, onClick) {
  return (
    <Button 
        value={label}
        key={id}
        id={id}
        className={className}           
        onClick={onClick} />
  )
}

export function renderButtonWithKey(label, labelIcon, id, value, className, onClick) {
  return (
    <ButtonWithKey
        label={label}
        labelIcon={labelIcon}
        id={id}
        key={value}
        value={value}
        className={className}           
        onClick={onClick} />
  )
}

export function renderButtonFromButton(button, id, className, onClick) {  
  return (
    <Button
        value={button.props.children}
        id={id}
        className={className}           
        onClick={onClick} />          
  )
}

export function renderButtonWithRightIconWithRef(label, labelLeftIcon, labelRightIcon = '', id, value, className, onClick, componentRef) {  
  //let finalOnClick = onClick
  let finalOnClick = onClickWithAnalytics(id, onClick)
  let key = getEpochTime()  
  // give it a unique key so that we force refreshing next time its parent re-renders:
  return labelLeftIcon ?
    (<span id={id} key={key} className={className} value={value} onClick={finalOnClick} ref={componentRef} ><i className={labelLeftIcon}></i>&nbsp;&nbsp;{label}&nbsp;&nbsp;<i className={labelRightIcon}></i></span>)
  : (
    <span id={id} key={key} className={className} value={value} onClick={finalOnClick} ref={componentRef} >{label}&nbsp;&nbsp;<i className={labelRightIcon}></i></span>
  )
}

export function renderImage(src, id, className, onClick) {
  return (
    <Image 
        src={src}
        id={id}
        className={className}           
        onClick={onClick}/>
  )   
}

/*
  Header
*/
export function renderSearchPanel(page, isMobileHeader = false) {
  let searchBarRef = React.createRef()
  let searchBarPullDownRef = React.createRef()
  page[isMobileHeader ? 'mobileHeaderSearchBarRef' : 'headerSearchBarRef'] = searchBarRef  
  page[isMobileHeader ? 'mobileSearchBarPulldownRef' : 'searchBarPulldownRef'] = searchBarPullDownRef
  let subject = globals.urlParams.subject
  return (
    <div className="search-panel">
      <div id="search-bar-container" className="flex-row flex-center">
        <input id="search-bar" type="search" placeholder={'Search ready-to-teach curriculums in ' + (subject ? Database.getSubjectTitle(subject) : 'all subjects')} ref={searchBarRef} />
        <div id="search-button-container">
          {renderImage(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/search-icon.png', 'search-button', 'image-button button', (e) => {globals.currentPage.searchBarClicked(isMobileHeader)})}
        </div>
      </div>
      <SubjectOptions isMobileHeader={isMobileHeader} ref={searchBarPullDownRef} />
    </div>
  )
}

export function renderHeaderSigninRelatedPanel(page) {
  // if logged in already: 
  let sessionInfo = globals.sessionInfo
  if (isUserSignedIn()) {
    page.userIconImageRef = React.createRef()
    let menuTop = (
      <div className="select-menu-header">
        <h6 className="weight200 h7size">{sessionInfo.userName}</h6>
        <h6 className="weight200 h8size">{sessionInfo.userEmail}</h6>
        <hr/>
      </div>
    )
    let userProfileImageUrl = Database.getUserImageUrl()
    let userButtonIconUrl = userProfileImageUrl || generalConfig.avatarIconImageUrl
    let selectButtonIconLeftElement = (<img className={'user-button-icon' + (userProfileImageUrl ? ' user-button-icon-user-image' : '')} src={userButtonIconUrl} alt="" ref={page.userIconImageRef} />)
    let options = {'My Curriculums': 'library', 'Curriculum Creator': 'creator/library', 'My Sales': 'creator/sales', 'Sign out': 'signout'}
    let optionIcons = {'My Curriculums': 'fas fa-briefcase', 'Curriculum Creator': 'fas fa-edit', 'My Sales': 'fas fa-file-invoice-dollar', 'Sign out': 'fas fa-sign-out-alt'}
    return (
      <div id="header-signin-related-buttons">
        <ButtonOrLinkMenuSelect header={menuTop} selectButtonIconLeftElement={selectButtonIconLeftElement} id={'user-button'} options={options} optionKeyToTitleIconFn={optionIcons} optionsDivExtraClasses={'wide-menu-div-options top75'} />
      </div>
    )
  } else {
    // if not yet logged in:
    let referingPageUrlParams = getSigninRelatedPageNavigationLink()
    return (
      <div id="header-signin-related-buttons">
        {renderLink('Sign in', 'signin-button', 'shadowed-text-button bordered-text-button  text-button button', 'signin' + referingPageUrlParams)}
        {renderLink('Register', 'register-button', 'shadowed-text-button bordered-text-button text-button button', 'register' + referingPageUrlParams)}
      </div>
    )
  }
}

// get url params for navigating to signin/register pages while storing the referer page's url params so we can get back to them after authentication:
export function getSigninRelatedPageNavigationLink() {
  let isHomePage = globals.currentPage.pageId === 'home'
  return isHomePage ? '' : '?referer=' + getUrlParamsPortionFromUrl()
}

export function renderMobileHeaderSigninRelatedPanel(page) {
  // if logged in already:
  let sessionInfo = globals.sessionInfo
  if (isUserSignedIn()) {
    page.mobileUserIconImageRef = React.createRef()
    let menuTop = (
      <div className="select-menu-header">
        <h6 className="weight200 h7size">{sessionInfo.userName}</h6>
        <h6 className="weight200 h8size">{sessionInfo.userEmail}</h6>
        <hr/>
      </div>
    )
    let userProfileImageUrl = Database.getUserImageUrl()
    let userButtonIconUrl = userProfileImageUrl || generalConfig.avatarIconImageUrl
    let selectButtonIconLeftElement = (<img className={'user-button-icon' + (userProfileImageUrl ? ' user-button-icon-user-image' : '')} src={userButtonIconUrl} alt="" ref={page.mobileUserIconImageRef} />)    
    let options = {'My Curriculums': 'library', 'Curriculum Creator': 'creator/library',  'My Sales': 'creator/sales', 'Sign out': 'signout'}
    let optionIcons = {'My Curriculums': 'fas fa-briefcase', 'Curriculum Creator': 'fas fa-edit', 'My Sales': 'fas fa-file-invoice-dollar', 'Sign out': 'fas fa-sign-out-alt'}    
    let header = (<div>
        {selectButtonIconLeftElement}
        {menuTop}
        </div>
      )
    return (
      <div id="header-signin-related-buttons">
          {renderButtonsOrLinksDivContainer('header-signin-related-buttons-container', options, optionIcons, false, {'My Curriculums': true, 'Curriculum Creator': true, 'My Sales': true, 'Sign out': true}, header, 'wide-menu-div-options top75', '')}
      </div>
    )
  } else {
    let isHomePage = globals.currentPage.pageId === 'home'
    // if not yet logged in:
    let referingPageUrlParams = getSigninRelatedPageNavigationLink()
    return (
      <div id="header-signin-related-buttons">
        {renderLink('Sign in', 'mobile-menu-signin', 'text-button button', 'signin' + referingPageUrlParams)} 
        {renderLink('Register', 'mobile-menu-register', 'text-button button', 'register' + referingPageUrlParams)}
      </div>
    )    
  }
}

// render header panels for desktop and mobile view:
export function renderHeaders(page) {  
  return (
    <div id="header-container">
      {renderHeader(page)}
      {renderMobileHeader(page)}
      {renderMobileHeaderMenu(page)}
      {renderMobileSearchPanel(page)}
    </div>
  )
}

export function renderHeader(page) {  
  return (
    <div id="header" className="header">
        {renderLogo(page)}
        {renderSearchPanel(page)}
        <div className="header-right-buttons">
          <CartButton />
          {renderHeaderSigninRelatedPanel(page)}
        </div>            
      </div>
  )
}

// show or hide the special mobile menu panel:
function mobileHeaderMenuToggleFn(page, on) {
  let className = on ? 'header-menu-mobile' : 'header-menu-mobile hidden'
  let menuElement = page.mobileHeaderMenuRef.current
  menuElement.className = className
  menuElement.style.display =  on ? 'block' : 'none'
} 

// Header is different for mobile device:
export function renderMobileHeader(page) {
  return (    
    <div id="header-mobile" className="header">
      {renderButton(<i className="fas fa-bars" />, 'header-mobile-menu-button', 'text-button button', (e) => mobileHeaderMenuToggleFn(page, true))}
      {renderLogo(page, 'header-mobile-top-logo')}
      <CartButton isMobile={true} />
      {renderImage(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/search-icon.png', 'header-mobile-search-button', 'search-button image-button button', (e) => mobileHeaderSearchBarToggleFn(page, true))}
    </div>
  )
}

// Header is different for mobile device:
export function renderMobileHeaderNoSearch(page) {
  return (
    <div id="header-mobile" className="header">
      {renderButton(<i className="fas fa-bars" />, 'header-mobile-menu-button', 'text-button button', (e) => mobileHeaderMenuToggleFn(page, true))}
      {renderLogo(page, 'header-mobile-top-logo')}      
    </div>
  )
}

// Mobile device has a menu on top-left:
export function renderMobileHeaderMenu(page) {  
  page.mobileHeaderMenuRef = React.createRef()
  return (
    <div id="header-menu-mobile" className="header-menu-mobile hidden" ref={page.mobileHeaderMenuRef}>
      <div id="header-menu-mobile-bg" className="dark-layer-bg"></div>
      <div id="header-menu-mobile-inner" className="dark-layer-bg-inner header-menu-mobile-inner" >
        {renderMobileHeaderSigninRelatedPanel(page)}
        {renderImage(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/x-icon.png', 'mobile-menu-exit-button', 'exit-button rounded-button button', (e) => mobileHeaderMenuToggleFn(page, false))}
      </div>
    </div>
  )  
  return null
}

// show or hide the special mobile search bar panel:
export function mobileHeaderSearchBarToggleFn(page, on) {
  let className = on ? 'header-serach-bar-mobile' : 'header-serach-bar-mobile hidden'  
  let searchBarElementContainer = page.mobileHeaderSearchBarContainerRef.current  
  searchBarElementContainer.className = className
  searchBarElementContainer.style.display =  on ? 'block' : 'none'
} 

// Mobile device has a special search bar panel:
export function renderMobileSearchPanel(page) {  
  page.mobileHeaderSearchBarContainerRef = React.createRef()
  return (
    <div id="header-search-bar-mobile" className="header-search-bar-mobile hidden" ref={page.mobileHeaderSearchBarContainerRef}>
      <div id="header-search-bar-mobile-bg" className="dark-layer-bg"></div>
      <div id="header-search-bar-mobile-inner" className="dark-layer-bg-inner header-menu-mobile-inner header-search-bar-mobile-inner" >
        {renderSearchPanel(page, true)}
        {renderImage(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/x-icon.png', 'mobile-search-bar-exit-button', 'exit-button rounded-button button', (e) => mobileHeaderSearchBarToggleFn(page, false))}
      </div>
    </div>
  )  
  return null
}

// Mobile site not ready yet, block the page:
export function renderMobileSiteDisabled(page) {
  return (
    <div id="mobile-disabled-bg" className="mobile-disabled-bg">
      <p>Please view this site using a computer or tablet in landscape view.<br />Our mobile site is under development.</p>
    </div>
  )
}

// default render of a landing page without search bar in header...:
export function renderNoSearchBarPage(page) {
  // if someone has set a rerouting navigating to another page, then navigate to it instead of rendering this page:
  let anyRedirects = page.renderRedirect()
  if (anyRedirects)
    return anyRedirects
  // no reroutes. render the page:
  let metaTags = page.getPageMetaDiffs()
  return (
    <div id={page.pageId + '-page'} className={(page.isCreatorSite ? 'creator-page ' : '') + 'page'}>
      {metaTags ? <DocumentMeta {...metaTags} /> : null}      
      {renderHeadersNoSearch(page)}
      {page.renderBodyAndFooter()}
    </div>
  )
}

// render header panels for desktop and mobile view:
export function renderHeadersNoSearch(page) {
  return (
    <div id="header-container">
      {renderHeaderNoSearch(page)}
      {renderMobileHeaderNoSearch(page)}
      {renderMobileHeaderMenu(page)}
      {renderMobileSearchPanel(page)}
    </div>
  )
}

export function renderHeaderNoSearch(page) {  
  return (
      <div id="no-search-header" className={(page.isCreatorSite ? 'creator-header ' : '') + 'header'}>
          {renderLogo(page, undefined, undefined, page.pageDir + 'library')}        
          <div className="header-right-buttons">
              {renderHeaderSigninRelatedPanel(page)}
          </div>
      </div>
  )
}

/*
  Footer
*/
export function renderFooter() {
  let socialUrls = generalConfig.socialUrls
  return (
    <div id="footer" className="section">
      <div id="footer-1">
        <div id="footer-menu">
          {renderLink('News', 'footer-news', 'footer-button text-button button', 'news')}
          {renderLink('About us', 'footer-about', 'footer-button text-button button', 'about')}
          {renderLink('Contact us', 'footer-contact', 'footer-button text-button button', 'contact')}
          {renderLink('Privacy policy', 'footer-privacy', 'footer-button text-button button', 'privacy')}
          {renderLink('Terms', 'footer-terms', 'footer-button text-button button', 'terms')}
          {renderExternalLink('Curriculum Directory', 'footer-curriculum-directory', 'footer-button text-button button', Fileserver.getWebpageCurriculumDirectoryPageUrl(), false)}
          {renderExternalLink('Teacher Directory', 'footer-teacher-directory', 'footer-button text-button button', Fileserver.getWebpageTeacherDirectoryPageUrl(), false)}          
        </div>
        <div id="footer-socials">          
          <div className="img-frame">
            {renderExternalImageLink(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/linkedin-icon.png', 'linkedin-button', 'image-button button', socialUrls.linkedin)}
          </div>
          {/*
          <div className="img-frame">
            {renderExternalImageLink(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/twitter-icon.png', 'twitter-button', 'image-button button', socialUrls.twitter)}
          </div>
          */}
          <div className="img-frame">
            {renderExternalImageLink(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/facebook-icon.png', 'facebook-button', 'image-button button', socialUrls.facebook)}
          </div>
          <div className="img-frame">
            {renderExternalImageLink(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/instagram-icon.png', 'instagram-button', 'image-button button', socialUrls.instagram)}
          </div>
        </div>
      </div>    
      <div id="footer-2"><span className="opac no-select-text">Termeric Education | 222 N Singingwood St, Orange, CA 92869 USA</span></div>
    </div>
  )
}

// renders page body and footer components for a page that has a popup dialog box (count = numberOfDialogBoxes):
export function renderBodyAndFooterForPageWithPopupDialogbox(page, numberOfDialogBoxes, doRenderFooter = true) {
  // create references to dialog box components:
  page.dialogBoxes = []
  let dialogBoxComponents = [...Array(numberOfDialogBoxes || 1).reverse().keys()].map(i => {
    let ref = React.createRef()
    page.dialogBoxes.unshift(ref)
    return (
      <DialogBoxPopUp key={page.pageId + '-dialog-box-' + i} ref={ref} index={i} />
    )
  })
  return (
    <div id={page.pageId + '-body'} className="body">
      {page.renderBody()}
      {doRenderFooter ? renderFooter() : null}
      {page.renderInvisibleBackgroundLayerFromOptionsSelect()}
      {dialogBoxComponents}
    </div>
  )
}

// fades in/out dialog box to show an alert:
function fadeToggleElementOnAndOff(element, className, display, on) {   
  if (on) {    
    element.style.opacity = 1
    element.className = className + ' fade-in'
    element.style.display = display
  } else {
    element.style.opacity = 0
    element.className = className + ' fade-out'
    setTimeout((e) => { element.style.display = 'none' }, 100)    
  }
}

// renders checkbox with theme styling:
export function renderCheckbox(id, checked = false, text = '', ref = undefined, containerClass = undefined, textClass = 'size14', value = undefined, onChangeFn) {
  return (
    <div id={id + '-checkbox-wrapper'} key={id + '-checkbox-wrapper'} className={(containerClass ? (containerClass + ' ') : '') + 'checkbox-container'}>
      <div id={id} key={id} className="checkbox" value={value} ischecked={checked ? 'true' : 'false'} onClick={(e) => {toggleCheckbox(e.target, onChangeFn)}} ref={ref}>{checked ? '✓' : ''}</div>               
      <span className={textClass}>{text}</span>
    </div>
  )
}

// is check box checked:
export function isCheckboxChecked(checkbox) {
  return checkbox.getAttribute('ischecked') === 'true'
}

export function toggleCheckbox(checkbox, onChangeFn) {  
  let checked = isCheckboxChecked(checkbox)
  checked = !checked    
  checkbox.innerText = checked ? '✓' : ''    
  checkbox.setAttribute('ischecked', checked ? 'true' : 'false')
  if (onChangeFn !== undefined)
    onChangeFn(checked, checkbox.getAttribute('value'))
} 

// renders a div box with a column of links or buttons inside (like a select div which is opened by default):
export function renderButtonsOrLinksDivContainer(id, options, optionKeyToTitleIconFn, allOptionsAreButtons, optionsAreLinks, header, optionsDivExtraClasses, optionsDivRef) {
  const optionsComponents = Object.keys(options).map((s) => {
    let isLink = !allOptionsAreButtons && (!optionsAreLinks || optionsAreLinks[s])
    let labelIcon = optionKeyToTitleIconFn ? optionKeyToTitleIconFn[s] : undefined
    let optionId = id + '-' + s
    let optionClassName = 'select-option text-button button'
    let optionLinkOrOnClick = options[s]
    return isLink ?
        renderLinkWithKey(s, optionId, optionClassName, optionLinkOrOnClick, labelIcon) :
        renderButtonWithKey(s, labelIcon, optionId, s, optionClassName, (e) => { /*optionsOnClick(); //FIXME! */ optionLinkOrOnClick(e)})
  })
  let optionsDiv = (
    <div className={'' + (optionsDivExtraClasses ? ' ' + optionsDivExtraClasses : '') + ' flex-col'} ref={optionsDivRef}>
      <div className="tooth tooth-right"></div>
      <div id={id + '-div-options'} className={'scroll-y-visible scroll-visible menu-div-options select-div-options flex-col'} >
        {header || null}
        {optionsComponents}
      </div>
    </div>
  )
  return optionsDiv
}

// renders rating 1-5 stars:
export function renderRatingStars(idPrefix, classPrefix, ratingImageWidth) {
  return (
    <div id={idPrefix + '-rating-stars'} className="curriculum-view-rating-stars">
      <div id={idPrefix + '-rating-bg'} className={'curriculum-view-rating-bg ' + classPrefix + '-view-rating curriculum-view-rating'}></div>
      <div id={idPrefix + '-rating'} className={'curriculum-view-rating-fg ' + classPrefix + 
       '-view-rating-fg ' + classPrefix + '-view-rating curriculum-view-rating'} style={{width: ratingImageWidth + 'px'}}></div>
    </div>
  )
}

// handle selecting of rating 1-5 stars:
function handleRatingSelectClick(componentRef, rating, ratingImageWidth) {
  let starFgE = componentRef.current
  starFgE.setAttribute('value', rating)
  starFgE.style.width = (rating * ratingImageWidth) + 'px'
}

// renders selecting of rating 1-5 stars:
export function renderSelectableRatingStars(callerComponent, idPrefix, classPrefix, ratingImageWidth, currentRating, componentRef) {  
  currentRating = currentRating || 0
  ratingImageWidth = ratingImageWidth / 5
  let starComponents = [1, 2, 3, 4, 5].map(rating => {
    return (
      <div id={idPrefix + '-rating-bg-' + rating} key={idPrefix + '-rating-bg-' + rating} className={'curriculum-view-rating-bg ' + classPrefix + '-view-rating curriculum-view-rating button'} style={{width: ratingImageWidth + 'px'}} onClick={e => handleRatingSelectClick(componentRef, rating, ratingImageWidth)}></div>
    )
  })
  return (
    <div id={idPrefix + '-rating-stars'} className="curriculum-view-rating-stars">
      <div id={idPrefix + '-rating-bg'} className={'flex-row flex-center ' + classPrefix + '-view-rating curriculum-view-rating'}>
        {starComponents}
      </div>
      <div id={idPrefix + '-rating'} className={'curriculum-view-rating-fg ' + classPrefix + 
       '-view-rating-fg ' + classPrefix + '-view-rating curriculum-view-rating'} value={currentRating} style={{width: (currentRating * ratingImageWidth) + 'px'}} ref={componentRef}></div>
    </div>
  )
}

// renders a progress waiting animated icon while page is loading:
export function renderPageLoading() {
  return (
    <div className="flex-col flex-center center-content page-body">
      {renderWaitingIcon(100)}
    </div>
  )
}
// renders a progress waiting animated icon:
export function renderWaitingIcon(sizePx = 50) {
  return (
    <PuffLoader color={generalConfig.theme.colors.yellow} loading={true} speedMultiplier={0.5} size={sizePx} />
  )
}

// applies the promo code once the user enters it in:
export function applyPromoButtonClicked(caller) {
  let promoInputElement = caller.promoCodeInputRef.current
  let promo = promoInputElement.value
  if (promo) {
    if (!(promo.length === payConfig.promoCodeLength && validatePromoCode(promo)))
      showTextDialog(0, 'Promo code was invalid.', generalConfig.dialogBoxErrorIconClassName)
    else {
      globals.urlParams.promo = promo      
      // refresh page component so that discounted price will render:
      caller.forceUpdate()
    }
    promoInputElement.value = ''
  }
}

// input element to enter promo code
export function renderApplyPromo(id, caller) {
  caller.promoCodeInputRef = React.createRef()
  return (
    <div className="flex-row top-margin40 bottom-margin40">
      <input className="short-height-input" placeholder="Enter promo code" maxLength={'' + payConfig.promoCodeLength} ref={caller.promoCodeInputRef} />
      {renderButton('Apply', id, 'clear-shadowed-text-button bordered-text-button text-button button', (e) => applyPromoButtonClicked(caller))}
    </div>
  )
}

// renders a nothing to show icon and text:
export function renderNothingToShow(idPrefix, text = 'Nothing to show...') {
  return (
    <div className="nothing-to-show-container flex-col">
      {renderImage(generalConfig.nothingToShowImageUrl, idPrefix + '-nothing-to-show', 'nothing-to-show')}
      <span className="nothing-to-show-text logo-font opac">{text}</span>
    </div>
  )
}

// takes a list of strings and creates bullet list <ul> element with <li> items inside:
// optional itemDescriptions to put a text under each item:
// optinoal optionalUrlDescriptions list signals that what goes into as descriptions are urls, which could be external (= true), or on our fileservers (= false)
// optional optionalUrlIsTests/optionalUrlIsAssignments list ads a 'test/assignment' mark next to description url for true items in the list
export function renderBulletListFromStringList(id, list, itemDescriptions, descriptionIsInline = false, optionalComponentRef, optionalUrlDescriptions, optionalDownloadLabel = 'Download   ⇩', optionalUrlIsTests, optionalUrlIsAssignments) {
  let isPrintPage = globals.currentPage.isPrintPage
  let count = list.length
  const items = list.map((i, index) => {
    let itemDescription = itemDescriptions === undefined ? undefined : itemDescriptions[index]
    let isTest = optionalUrlIsTests && (optionalUrlIsTests[index] === true)
    // extract any formatted links in the item text:
    let itemTextParsedAsComponent = parseFormattedURLsFromText(i, isPrintPage)
    return (
      <li key={i} value={i}>{
        itemDescriptions === undefined ?
        itemTextParsedAsComponent :
          (<div className={descriptionIsInline ? 'flex-row' : ''}>
            <div className="flex-row bottom-margin10">
              {(isTest || (optionalUrlIsAssignments && (optionalUrlIsAssignments[index] === true))) ? 
              <div className="flex-row logo-color">            
                <i className={isTest ? 'fas fa-envelope-open-text' : 'fas fa-tasks'} aria-hidden="true"></i>
                <span className="monospace span-with-side-neighbors boxed-text h8size line-height12">{isTest ? 'TEST' : 'ASSIGNMENT'}</span>              
              </div>
              : null}
              {itemTextParsedAsComponent}
            </div>
            {itemDescription === undefined ? null : 
            (optionalUrlDescriptions === undefined || optionalUrlDescriptions[index] === true ?
              (descriptionIsInline ?
                (<em className="opac row-padding-20">{'— ' + itemDescription}</em>) :
                 <a href={itemDescription} target="_blank" className="top-margin10 block resource-link">{itemDescription}</a>) :
              (renderButtonWithKey(optionalDownloadLabel, undefined, id + '-' + index + '-resource-download-button', index, 'left-margin0 clear-shadowed-text-button bordered-text-button text-button left-right-padded-button button', (e) => { Fileserver.downloadContent(false, itemDescription) }))
            )}
            {(!descriptionIsInline && index < count - 1) ? <hr/> : null}
          </div>)
        }</li>
    )
  })  
  return list.length === 0 ?
    (<p id={id} ref={optionalComponentRef}>None</p>) :
    (<ul id={id} ref={optionalComponentRef}>{items}</ul>)
}

// parses URL annotated with format [[Title||URL]] inside a text and generates a component with any detected URLs with actual <a> link tags:
export function parseFormattedURLsFromText(i, isPrintPage = false) {
  let haveLinkMatches = false
  let linkMatches = i.match(/\[\[([^\[]+)\|\|([^\[]+)\]\]/g)
  let linkItemComponents
  if (linkMatches) {
    haveLinkMatches = true
    let iPart = i
    let keyCtr = 0
    linkItemComponents = []
    linkMatches.forEach((m) => {
      let pos = iPart.indexOf(m)
      let len = m.length
      m = m.replace(/\[\[|\]\]/g, '').split('||')
      let linkTitle = m[0], linkTarget = m[1]
      linkItemComponents.push(<span key={'' + keyCtr++}>{iPart.substring(0, pos)}</span>)
      linkItemComponents.push(<a key={'' + keyCtr++} href={linkTarget} target="_blank" className="resource-link">{linkTitle}</a>)
      if (isPrintPage)
        linkItemComponents.push(<span key={'' + keyCtr++} className="resource-link">{' (' + linkTarget + ')'}</span>)
      iPart = iPart.slice(pos + len)
    })
    linkItemComponents.push(<span key={'' + keyCtr++}>{iPart}</span>)
  }    
  return haveLinkMatches ? 
    (<div className="inline-block">{linkItemComponents}</div>) : 
    (<span>{i}</span>)
}

// given id of an element parse an unparsed html text as insert a child element:
export function insertHtmlAsChild(id, htmlText) {    
  let element = document.getElementById(id)
  if (element) {
    let html = parseHTMLBodyFromString(htmlText)
    while (html.firstChild)
      element.appendChild(html.firstChild)    
  }
}

// splits a <br> separated text into <p> paragraphs:
export function renderParagraphsFromString(id, text) {
  var ctr = 0
  const paragraphs = text.split('<br>').map((p) => {        
    return (
      <p key={'paragraph-' + ctr++}>{p}</p>
    )                
  })
  return (
    <div id={id}>{paragraphs}</div>
  )
}

// Higher order function to generate a list that goes inside component render function:
export function generateComponentList(count, idPrefix, itemGenFn, componentGenFn, valueFn, onChangeFn) {
  const options = Array.from(Array(count).keys()).map((i) => {
    let item = itemGenFn(i)
    let id = idPrefix + '-' + i
    return componentGenFn(i, id, item, valueFn, onChangeFn)
  })
  return options
}

// Generates onChange listener functions all the filter kinds 
export function genOnChangeFnForFilters(filterName) {   
  return (checked, value) => {    
    if (Database.searchResultsCurrentFilters === undefined)
      Database.resetSearchResultsCurrentFilters()
    let filters = Database.searchResultsCurrentFilters
    if (!filters[filterName])
      filters[filterName] = {}
    let currFilters = filters[filterName]
    if (checked)
      currFilters[value] = true
    else {
      // delete the filters map when all empty:
      delete currFilters[value]      
      if (Object.keys(currFilters).length === 0) 
        delete filters[filterName]
      if (Object.keys(filters).length === 0)
        Database.unsetSearchResultsCurrentFilters()
    }
    // notify the database API to apply filters update to search results list:
    Database.applyFiltersUpdateToSearchResults(globals.currentPage.resultsComponent)
  }
}

// returns whether or not we have a user signed-in session:
export function isUserSignedIn() {
  let sessionInfo = globals.sessionInfo
  return sessionInfo && sessionInfo.signedIn
}

// returns the aws cognito username (whether or not user is signed-in or not aws will assign it a username...):
export function getSessionUsername() {
  let sessionInfo = globals.sessionInfo
  return isUserSignedIn() ? sessionInfo.username : globals.unAuthSessionInfo.username
}

// Animate scrolling vertically:
export function animateScroll(elementY, duration) {
  let startingY = window.pageYOffset, diff = elementY - startingY, start
  // Bootstrap our animation - it will get called right before next frame shall be rendered.
  window.requestAnimationFrame(function step(timestamp) {
    if (!start) start = timestamp
    // Elapsed milliseconds since start of scrolling.
    let time = timestamp - start
    // Get percent of completion in range [0, 1].
    let percent = Math.min(time / duration, 1)
    window.scrollTo(0, startingY + diff * percent)
    // Proceed with animation as long as we wanted it to.
    if (time < duration) window.requestAnimationFrame(step)    
  })
}

// store refering page before signin/register redirect to external cognito auth page, so that upon return we can auto redirect back to that page, instead of home page...:
export function storeReferingPageBeforeAuthRedirect() {  
  if (globals.currentPage.pageId !== 'home') {
    let params = parseRawUrlParams()    
    let referingPageUrlParams = params.referer
    if (referingPageUrlParams) {      
      if (globals.haveLocalStorage)
        localStorage.setItem('termeric-page-home-last-page-open-before-signin', referingPageUrlParams)
    }
  }
}

// Returns the portion of the address bar url that includes the page and params
export function getUrlParamsPortionFromUrl() {
  return encodeURIComponent(window.location.href.replace(window.location.protocol + '//' + window.location.host, ''))
}

// Parses passed parameters to the current page in the address bar:
export function parseUrlParams() {
  var regex = /[?&]([^=#]+)=([^&#]*)/g,
    url = decodeURIComponent(window.location.href),
    params = {},
    match
  // add params that should persist from previous pages navigated from:
  let urlParams = globals.urlParams
  if (urlParams) {
    generalConfig.urlParamsToPersist.forEach(p => {
      if (urlParams[p])
        params[p] = urlParams[p]
    })
  }
  // now parse the params currently in the address bar:
  while(match = regex.exec(url))
      params[match[1]] = match[2]
  globals.debug = params.debug !== undefined
  return params
}

// Parses passed parameters (without decoding) to the current page in the address bar:
export function parseRawUrlParams() {
  var regex = /[?&]([^=#]+)=([^&#]*)/g,
    url = window.location.href,
    params = {},
    match  
  // now parse the params currently in the address bar:
  while(match = regex.exec(url))
      params[match[1]] = match[2]  
  return params
}

// Open a link in a new tab
export function openExternalLink(url) {
  window.open(url, '_blank').focus()
}

// capitalize (uppercase first character) word:
export function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

// capitalized <-> not capitalized swap
export function switchCapitalization(string) {
  let first = string.charAt(0)
  let rest = string.slice(1)
  return (first.toUpperCase() === first ? 
    first.toLowerCase() : first.toUpperCase()) + rest
}

export function switchCapitalizationInSentence(string) {
  return string.split(' ').map(s => { return switchCapitalization(s) }).join(' ')
}

// converts unix epoch seconds to a mm/yyyy format
export function epochToDate(seconds) {
  let date = new Date(seconds * 1000)
  return (date.getMonth() + 1) + '/' + date.getFullYear()  
}

// returns current date/time in epoch format (sec.):
export function getEpochTime(doInMillisecond = false) {
  let ms = new Date().getTime()
  return Math.round(doInMillisecond ? ms : ms / 1000)
}

// returns current date/time in dd-mm-yyyy format:
export function getFormattedTime(date) {
  date = date ? new Date(date * 1000) : new Date()
  return '' + date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()  
}

export function getFormattedTime2(date) {
  date = date ? new Date(date * 1000) : new Date()
  return '' +  months[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()  
}

// assumes epoch in sec. (not ms.)
export function numberOfDaysBetweenEpochTimes(t1, t2) {
  return Math.abs(Math.round((t2 - t1) / 60 / 60 / 24)) 
}

// returns name of current month:
export function getCurrentMonthName() { return months[new Date().getMonth()] }

// returns the epoch seconds range within the specified month, year:
export function getEpochRangeforMonth(monthName, year) {
  // Create a Date object for the first day of the specified month and year
  const firstDayOfMonth = new Date(`${monthName} 1, ${year} 00:00:00 UTC`)
  // Calculate the first day of the next month by setting the day to 1 and
  // moving to the next month (month + 1)
  const firstDayOfNextMonth = new Date(firstDayOfMonth)
  firstDayOfNextMonth.setUTCDate(1)
  firstDayOfNextMonth.setUTCMonth(firstDayOfMonth.getUTCMonth() + 1)
  // Convert the Date objects to epoch times (in seconds)
  const firstSecondOfMonthEpoch = Math.floor(firstDayOfMonth.getTime() / 1000)
  const firstSecondOfNextMonthEpoch = Math.floor(firstDayOfNextMonth.getTime() / 1000)
  return [firstSecondOfMonthEpoch, firstSecondOfNextMonthEpoch]
}

// returns a quanity plus plural version of a word:
export function plural(word, quantity, skipQuantity = false) {
  return (skipQuantity ? '' : quantity + ' ') + word + (quantity === 1 ? '' : 's')
}

// returns a filename (spaces replaced by dashes)
export function generateFilenameFromTitle(title, ext) {
  return title.split(/[\s\'\-]+/g).join('-') + (ext ? '.' + ext : '')
}


// displays a dialog box (at index) with text inside (optional fontawesome icon class to put on top):
export function showTextDialog(dialogBoxIndex, text, topIconClassName, onCloseFn, buttonLabels, onButtonFns, showWaitingDialogAfterClick = false) {
  dialogBoxIndex = dialogBoxIndex || 0
  let dialogBoxes = globals.currentPage.dialogBoxes
  if (dialogBoxes !== undefined) {
    if (dialogBoxes.length > dialogBoxIndex)
      dialogBoxes[dialogBoxIndex].current.showTextDialog(text, topIconClassName, onCloseFn, buttonLabels, onButtonFns, showWaitingDialogAfterClick)
  }
}

// displays a dialog box (at index) with content inside:
export function showDialog(dialogBoxIndex, contentElement, onCloseFn, buttonLabels, onButtonFns, showWaitingDialogAfterClick = false) {  
  dialogBoxIndex = dialogBoxIndex || 0
  let dialogBoxes = globals.currentPage.dialogBoxes
  if (dialogBoxes !== undefined) {
    if (dialogBoxes.length > dialogBoxIndex)
      dialogBoxes[dialogBoxIndex].current.showDialog(contentElement, onCloseFn, buttonLabels, onButtonFns, showWaitingDialogAfterClick)
  }
}

// for programattically closing a dialog:
export function closeDialog(dialogBoxIndex, showWaitingDialogAfterClick = false) {
  let dialogBoxes = globals.currentPage.dialogBoxes
  if (dialogBoxes !== undefined) 
    if (dialogBoxes.length > dialogBoxIndex)
      dialogBoxes[dialogBoxIndex].current.closeDialog(showWaitingDialogAfterClick)
}

// dialog for prompting user to wait while something is in progress...:
export function showWaitingDialog(dialogBoxIndex = 0, waitingMessage) {
  let dialogBoxes = globals.currentPage.dialogBoxes
  if (dialogBoxes !== undefined)
    if (dialogBoxes.length > dialogBoxIndex)
      dialogBoxes[dialogBoxIndex].current.showWaitingDialog(waitingMessage)
}

// copies a text to clipboard and shows a user tip message about it
export function copyToClipboard(text) {
  if (!navigator.clipboard) {
    oldBrowserCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    copyToClipboardSuccessDialog()
  }, function(err) {
    console.error('Could not copy text: ', err)
  })
}

function copyToClipboardSuccessDialog() {
  showTextDialog(0, 'Share link copied to clipboard.', generalConfig.dialogBoxInfoIconClassName)
}

function oldBrowserCopyTextToClipboard(text) {
  let textArea = document.createElement('textarea')
  textArea.value = text
  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    let successful = document.execCommand('copy')
    if (successful) 
      copyToClipboardSuccessDialog()
    else 
      console.error('Oops, unable to copy')
  } catch (err) {
    console.error('Oops, unable to copy', err)
  }
  document.body.removeChild(textArea)
}

// gets the curriculum description as a div element inside an external html file sent using postmessage from iframe:
export function parseHTMLBodyFromString(code) {
  let parser = new DOMParser()
  return parser.parseFromString(code, 'text/html').body
}

// clicking on a button with icon toggles the text shown inside it:
export function rerenderElementWithIcon(element, innerText, iconClassName, addIcon) {
  // remove any icon content first:
  while (element.firstChild)
    element.removeChild(element.firstChild)
  element.innerText = innerText
  // add icon if needed: 
  if (addIcon)
    addFontAwesomeIconToElement(element, innerText + '&nbsp;&nbsp;', iconClassName)
}

// adds a font awesome icon to the right of an element's inner text:
export function addFontAwesomeIconToElement(element, label, iconClassName) {
  const iElement = document.createElement('i')
  iElement.className = iconClassName
  element.innerHTML = label
  element.appendChild(iElement)
}

// copies the contents of an element into another element. the target element gets an additional font awesome icon to the right of the source element's inner text:
export function copyElementContentsFromAnotherAddFontAwesomeIcon(targetElement, sourceElement, rightIconClassName, leftIconClassName) {
  let iElement = document.createElement('i')
  iElement.className = rightIconClassName
  targetElement.innerHTML = sourceElement.innerText + '&nbsp;&nbsp;'
  targetElement.appendChild(iElement)  
  if (leftIconClassName) {
    iElement = document.createElement('i')
    iElement.className = leftIconClassName
    targetElement.insertBefore(iElement, targetElement.firstChild)
  }
}

// how to display locale price in a page:
export function priceToString(price) {  
  return payConfig.currencySymbol + price.toLocaleString()
}

// add commas in 1000s positions in a price:
export function priceWithCommas(price) {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function renderMoneyBackGuaranteeLabel(page, className = '') {
  return (
    <a className={'boxed-text opac' + (className ? (' ' + className) : '')} href="/terms#terms-moneyback"><i className="fa fa-certificate" aria-hidden="true"></i>&nbsp;&nbsp;{libraryConfig.numberOfDaysBeforeMoneyBackGuaranteeExpiresDays}-day money back guarantee</a>
  )
}
// how to render local current price of a curriculum in a page:
export function renderCurriculumPrice(page, curriculumData, className, showOriginalPrice, showDiscountPercent = false) {
  // set a flag so we know if we need to force rerender in case locale pay config (currency) has been updated later on...:
  page.priceRendered = true
  let priceAndPromoInfo = Database.getCurriculumPriceInfo(curriculumData)
  let hasPromo = priceAndPromoInfo.hasPromo, currPrice = priceAndPromoInfo.price, origPrice = priceAndPromoInfo.origPrice, percentOff = priceAndPromoInfo.percentOff
  let currency = payConfig.currencySymbol
  return (
    <span className={className}>
      {currPrice === 0 ? <small className="orange-question-color">FREE</small> : (currency + currPrice.toLocaleString())}
      {hasPromo && showOriginalPrice ?
        <small className="before-discount-price line-through">{currency + origPrice.toLocaleString()}</small> : null}
      {hasPromo && showDiscountPercent ?
        <small className="before-discount-price weight200 logo-color">{percentOff}% off</small>
      : null}
    </span>
  )
}

// get url for curriculum's final page URL (not the static seo-friendly page):
export function getCurriculumDirectShareUrl() {
    return window.location.protocol + '//' + window.location.host + '/preview?subject=' + globals.urlParams.subject + '&id=' + globals.urlParams.id    
}

// get url for a given curriculum's final page URL:
export function getACurriculumsDirectShareUrl(id, subject) {
  return window.location.protocol + '//' + window.location.host + '/preview?subject=' + subject + '&id=' + id    
}

// adds links from text
export function linkify(inputText) {
  //URLs starting with http://, https://, or ftp://
  var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
  // if url happens to be our own fileserver, strip the full url (only show file name) in the link:
  let matchUrl = inputText.match(replacePattern1)
  var replacedText, fileName = undefined
  if (matchUrl && matchUrl.length > 0) {    
    if (matchUrl[0].startsWith(fsConfig.rootBucketUrl))
      fileName = inputText.slice(1 + inputText.lastIndexOf('/'))    
  }
  replacedText = inputText.replace(replacePattern1, (fileName !== undefined) ?
      ('<a class="resource-link"href="$1" target="_blank">' + fileName + '</a>') : '<a class="resource-link" href="$1" target="_blank">$1</a>')
  // URLs starting with www. (without // before it, or it'd re-link the ones done above)
  let replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
  replacedText = replacedText.replace(replacePattern2, '$1<a class="resource-link" href="http://$2" target="_blank">$2</a>')
  // change email addresses to mailto:: links
  let replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim
  replacedText = replacedText.replace(replacePattern3, '<a class="resource-link"  href="mailto:$1">$1</a>')
  return replacedText
}

export function validateName(name) {
  let pattern = /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9\.\-()'"]{1,}|[a-zA-Z0-9\.\-()'"]+\s{1}[a-zA-Z0-9\.\-()'"]{1,}\s{1}[a-zA-Z0-9\.\-()'"]{1,})$/
  return pattern.test(name)
}

export function validateEmail(email) {
  let pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(email.toLowerCase())
}

// email address or header: name <email@...>
export function validateEmailAddressHeader(email) {
  let pattern = /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9\.\-()'"]{1,}|[a-zA-Z0-9\.\-()'"]+\s{1}[a-zA-Z0-9\.\-()'"]{1,}\s{1}[a-zA-Z0-9\.\-()'"]{1,})\s*\<(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*\>/
  return validateEmail(email) || pattern.test(email.toLowerCase())
}

export function validateThingName(name) {
  let pattern = /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9\.-]{1,}|[a-zA-Z0-9\.\-]+\s{1}[a-zA-Z0-9\.\-]{1,}\s{1}[a-zA-Z0-9\.\-]+)$/
  return pattern.test(name)
}

// commented out: \p{S}
export function validateTitle(title) {
    //let pattern = /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}\s{1}[a-zA-Z0-9]{1,})[a-zA-Z0-9\-' ]*$/
    let pattern = /^[A-Z'"\p{Alpha}\p{M}\p{N}\p{P}\p{Join_C}][A-Za-z0-9&_~\+=\-:'"\p{Alpha}\p{M}\p{N}\p{P}\p{Join_C}]*(\s[A-Za-z0-9&_~\+=\-:\.'"\?*<>()\[\],;\p{Alpha}\p{M}\p{N}\p{P}\p{Join_C}]+)*$/u
    return title.length >= 3 && pattern.test(title)
}

// validate a string as a url:
export function validateURL(url) {
  let pattern = new RegExp(
    //'^(https?:\\/\\/)?'+ // protocol
    '^(https?:\\/\\/)'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_\\.~+=-]*)?$','i') // fragment locator
  return !!pattern.test(url)
}

// is input string a url:
export function isURL(url) {
  let pattern = new RegExp('^\s*(https?:\\/\\/)')
  return pattern.test(url)
}

export function validatePromoCode(code) {
  let pattern = /^[a-zA-Z0-9]+$/
  return pattern.test(code)
}

// remove indexes in the removeIdxs list from a given list:
export function removeIndicesFromList(list, removeIdxs) {
  removeIdxs.sort()
  var offset = 0
  removeIdxs.forEach(idx => { 
    list.splice(idx - offset++, 1)
  })
}

// arrays equal or not
export function arrayEquals(a1, a2) {
  return JSON.stringify(a1) === JSON.stringify(a2)
}

// array from number x to number y (bidirectional = true: ascending or descending):
export function arrayFromTo(from, to, bidirectional = true) {
  let diff = to - from
  let n = bidirectional ? (Math.abs(diff) + 1) : (diff >= 0 ? (diff + 1) : 0)
  let sign = to >= from ? 1 : -1    
  return Array(n).fill().map((_, i) => from + (i * sign))
}

// moves item at index 'from' to index 'to'
export function arrayMoveIndexFromTo(list, from, to) {
  if (from !== to)
    list.splice(to, 0, (list.splice(from, 1))[0])
}

// given a sorted list of numbers where two indices have swapped, returns the from/to swap positions:
export function arrayFindMoveFromToIndex(list) {
  // find out where the drag index was from and to:
  var to = 0, from = 0, count = list.length
  while (to < count - 1 && list[to] < list[to + 1])
    to++  
  let val = list[to]
  from = 0
  while (from < count - 1 && (from + 1 === to || list[from + 1] < val)) {    
    from++  
  }
  return {from: from, to: to}
}

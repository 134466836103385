/*
    Home page
*/

import React from 'react'

import * as Config from './config'
import * as Database from './database-api'
import * as Utility from './utility'
import LandingPage from './landing'

const fsConfig = Config.fsConfig
const homeConfig = Config.pages.home
const generalConfig = Config.pages.general
 
// ------------------------------------------------------------------------
// Home Page element
class HomePage extends Utility.LandingPageWithDialogBox {
  constructor(props) {
    super(props)
    this.pageId = 'home'
    // a flag that say whether this page needs a resize event handler:
    this.hasResizeHandler = true
    // keep a ref to sections that need to render only after database data fetched:
    this.subjectsSection = React.createRef()
    
  }

  componentDidMount() {
    super.componentDidMount()      
    // If database has already finished its fetching curriculum data for default query at this time, call the callback manually (otherwise, it will call it for us itself once fetched):
    if (Database.isSubjectsDataFetched())
      this.subjectsDataFetched()
  }

  // Default (including for home page) initial search query to run is to get all popular (rating >= 4) curriculums in any subject
  getOnLoadCurriculumResultsQueryForHomePage() {
    return Database.getOnLoadCurriculumResultsQueryForHomePage()
  }


  // database API calls this when subjects info is fetched. It'll trigger components that need that info to render now...
  subjectsDataFetched() {
    super.subjectsDataFetched()
    let subjectsSectionComponent = this.subjectsSection.current
    if (this.didMount && (subjectsSectionComponent != null))
      subjectsSectionComponent.setState({dataFetched: true})
    if (Database.isPopularAndLatestCurriculumsDataFetched())
      this.popularAndLatestCurriculumsDataFetched()
    else {
      let searchQuery = this.getOnLoadCurriculumResultsQueryForHomePage()    
      if (searchQuery)
        Database.database_fetchCurriculumsData(searchQuery, true)
      }
  }

  // database API calls this when popular curriculums info for all subjects are fetched. It'll trigger components that need that info to render now...
  popularAndLatestCurriculumsDataFetched() {
    let subjectsSectionComponent = this.subjectsSection.current
    if (this.didMount && (subjectsSectionComponent != null))
      subjectsSectionComponent.popularAndLatestCurriculumsDataFetched()
  }
  
  // re-render for adaptive components (currently: home page: number of curriculum cards shown, all pages: search bar width):
  handleResize() {    
    super.handleResize()
    let subjectsSectionComponent = this.subjectsSection.current
    if (subjectsSectionComponent != null)
      subjectsSectionComponent.handleResize()
  }

  // Locale API calls this if it's just updated locale pay config to non-default (non USD currency) and it noticed that the current page has already rendered price of an item (so that page can force update of its price component so that it shows in the right local currency). 
  localePayConfigUpdated() {
    let subjectsSectionComponent = this.subjectsSection.current    
    if (subjectsSectionComponent != null)
      subjectsSectionComponent.forceUpdate()
  }
  
  /*
    Top CTA
  */
  renderTopCTA() {
    if (Utility.isUserSignedIn()) {
      return null
    } else {
      // pick a random home page top banner image to show:
      let randomImageIndex = Math.floor(Math.random() * 3) + 1
      return (
        <div id="cta-top" className="section flex-center">
          <div id="cta-top-message-container" className="slide-in-zoom flex-col flex-center">
            <h1 className="logo-font header-text h1size"><strong>Teachers</strong> &amp; <strong>Parents</strong></h1>
            <p id="cta-top-text" className="h4size">Welcome to Termeric&#8212;an online marketplace for complete, <strong>ready-to-teach</strong>, classroom-tested, and peer-reviewed K-12 school subject <strong>course curriculums</strong> and stand-alone <strong>unit</strong> curriculums.<br/><br/>Say goodbye to scrambling for teaching resources online and hours and days spent piecing them together to create your courses!</p>
            {Utility.renderLink('Register', 'cta-top-register-button', 'cta-text-button shadowed-text-button bordered-text-button text-button button', 'register')}
          </div>
          {Utility.renderImage(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/bg-illustration-' + randomImageIndex + '.png', 'cta-top-background-img-1', randomImageIndex === 1 ? 'cta-top-background-img-larger' : '')}
        </div>
      )
    }
  }

  /*
    Popular Subjects
  */

  renderSubjects() {
    return (
      <SubjectsSection page={this} ref={this.subjectsSection} />
    )
  }

  /*
    Footer CTA
  */
  renderTopBenefits() {
    let benefitsIcon = generalConfig.ctaItemIconImageUrl
    return (
      <div id="top-benefits" className="slide-in-up section">
        <div className="flex-col top-benefits-div">
          {Utility.renderImage(benefitsIcon, 'top-benefits-1-img', 'top-benefit-img round-border')}
          <span className="logo-font top-benefit-span size25"><span className="logo-color">Complete</span>, <span className="logo-color">Classroom-Tested</span> &amp; <span className="logo-color">Ready-to-Teach</span> Course Curriculums &amp; Units for School or Homeschooling</span>
          <p id="top-benefits-1-text">Every course curriculum includes everything you need to start teaching the course: Course and Lesson Plans, Slides, and more. Stand-Alone units allow you to mix and match to your needs.</p>
        </div>
        <div className="flex-col top-benefits-div">
          {Utility.renderImage(benefitsIcon, 'top-benefits-2-img', 'top-benefit-img round-border')}
          <span className="logo-font top-benefit-span size25">Fully Curated &amp; Uniformly <span className="logo-color">Structured</span> Curriculums Created &amp; <span className="logo-color">Peer-Reviewed</span> by School Teachers</span>
          <p id="top-benefits-2-text">Our expert teachers peer review each curriculum to ensure quality. Termeric verifies every course curriculum was previously taught by a real teacher at an actual school.</p>
        </div>
        <div className="flex-col top-benefits-div">
          {Utility.renderImage(benefitsIcon, 'top-benefits-3-img', 'top-benefit-img round-border')}
          <span className="logo-font top-benefit-span size25">Join Our Course Curriculum Creator Teachers</span>
          <p id="top-benefits-3-text">Every curriculum on Termeric is created by teachers like you. Share your course curriculums with the world! Our <a className="resource-link" href="/creator/library">Curriculum Creator</a> tool guides you to create and submit your curriculum.</p>
        </div>        
      </div>
    )
  } 

  renderBody() {
    return (
      <div id="home-body-main">
        <div id="body-top">
          {this.renderTopCTA()}
          {this.renderTopBenefits()}
        </div>
        {this.renderSubjects()}
      </div>
    )
  }    
    
}

/*
  Header
*/

// ------------------------------------------------------------------------
/*
    Main "Subject"s Section
*/
// Creates buttons for popular subjects in the subjects panel
class SubjectsSection extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        dataFetched: Database.isSubjectsDataFetched()  
      }      
      this.page = props.page
      this.latestCurriculumsRef = React.createRef()
      this.latestCurriculumUnitsRef = React.createRef()
      this.popularCurriculumsRef = React.createRef()
      this.popularCurriculumUnitsRef = React.createRef()      
  }

  popularAndLatestCurriculumsDataFetched() {
    [this.latestCurriculumsRef, this.latestCurriculumUnitsRef, this.popularCurriculumsRef, this.popularCurriculumUnitsRef].forEach(ref => {
      if (ref.current)
        ref.current.setState({dataFetched: true})
    })
  }

  handleResize() {
    [this.latestCurriculumsRef, this.latestCurriculumUnitsRef, this.popularCurriculumsRef, this.popularCurriculumUnitsRef].forEach(ref => {
      if (ref.current)
        ref.current.handleResize()
    })      
  }
  
  render() {
    if (this.state.dataFetched) {    
      return (
        <div id="subjects" className="section">
          <SubjectButtons />
          <CurriculumViewCards isPopularCurriculums={false} isUnitCurriculums={false} idPrefix="latest-curriculums" page={this.page} ref={this.latestCurriculumsRef} />
          <CurriculumViewCards isPopularCurriculums={false} isUnitCurriculums={true} idPrefix="latest-curriculums" page={this.page} ref={this.latestCurriculumUnitsRef} />
          <CurriculumViewCards isPopularCurriculums={true} isUnitCurriculums={false} idPrefix="popular-curriculums" page={this.page} ref={this.popularCurriculumsRef} />          
          <CurriculumViewCards isPopularCurriculums={true} isUnitCurriculums={true} idPrefix="popular-curriculums" page={this.page} ref={this.popularCurriculumUnitsRef} />
        </div>
      )
    } else 
      return (
        <div id="subjects" className="loading section">
          {Utility.renderWaitingIcon(100)}
        </div>
      )
  }
}

// Creates buttons for all available subjects in the subjects panel
class SubjectButtons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          currentSubject: Database.getDefaultSubject()
        }        
    }
    
    render() {
      const buttons = Database.getPopularSubjects().map((s) => { 
        let subjectLink = 'search?subject=' + s
        return (
          <div key={'subject-button-div-' + s} className="button-hover subject-button-div">
            <div key={'subject-button-img-div-' + s} className="subject-button-img-div-big subject-button-img-div-big-in-col subject-button-img-div round-border button image-button">
              {Utility.renderImageLink(Database.getSubjectIcon(s), 'subject-button-' + s, 'subject-button-img', subjectLink)}
            </div>
            {Utility.renderLink(Database.getSubjectTitle(s), 'subject-title-button-' + s, '', subjectLink)}
          </div>
        )
      })
      return (
        <div id="subjects-panel-container" className="slide-in-left">
          <span className="logo-font size39">Popular subjects</span>
          <div id="subjects-panel" className="scroll-x-visible scroll-visible">
            <div className="subjects-panel-side-margin"></div>
            {buttons}
            <div className="subjects-panel-side-margin"></div>
          </div>
          <div id="subjects-panel-overlay-1" className="subjects-panel-overlay"></div>
          <div id="subjects-panel-overlay-2" className="subjects-panel-overlay"></div>
        </div>
      )
    }
}
  

// ------------------------------------------------------------------------
// Views a row of some of popular 'curriculums' cards and a row of some of popular 'curriculum units' cards
class CurriculumViewCards extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
          dataFetched: Database.isPopularAndLatestCurriculumsDataFetched(),
          cardPageIndexOffset: 0,          
          numberOfCardsShown: 0
        }
        this.page = props.page
        this.isPopularCurriculums = props.isPopularCurriculums
        this.isUnitCurriculums = props.isUnitCurriculums
        this.idPrefix = props.idPrefix        
    }

    // Number of curriculum cards shown in the page changes based on window size. This calculates it based on window width:
    calculateNumberOfCardsShown() {
      var isMobile = window.innerWidth < homeConfig.maxScreenWidthToAssumeMobile
      var bordersWidth = 1
      var pageSideBorder = isMobile ? 0 : homeConfig.curriculumCardsViewSideBorder
      var sectionPadding = isMobile ? 10 : 30
      var toggleButtonWidth = 30
      var toggleButtonPadding = isMobile ? 1 : 10
      var toggleButtonMargin= isMobile ? 1 : 10
      var cardsAreaWidth = window.innerWidth - (2 * (pageSideBorder + bordersWidth + sectionPadding + toggleButtonWidth + toggleButtonPadding + toggleButtonMargin))
      var cardWidth = homeConfig.curriculumCardWidth      
      var cardsGap = 10
      var cardsPadding = 10
      var cardsMargin = 10
      var eachCardWidthSpace = cardWidth + (2 * (cardsPadding + bordersWidth + cardsMargin)) + cardsGap
      var numCards = Math.max(1, Math.floor((cardsAreaWidth + cardsGap) / eachCardWidthSpace))
      //console.log('window.innerWidth', window.innerWidth, 'isMobile', isMobile, 'pageSideBorder', pageSideBorder, 'sectionPadding', sectionPadding, 'toggleButtonPadding', toggleButtonPadding, 'cardsAreaWidth', cardsAreaWidth, 'cardWidth', cardWidth, 'eachCardWidthSpace', eachCardWidthSpace, 'numCards', numCards)
      return numCards
    }

    // Number of curriculum cards shown in the page changes based on window size. This retriggers a recalculation and re-render when window size changes:
    handleResize() {
      this.setState({cardPageIndexOffset: 0, numberOfCardsShown: this.calculateNumberOfCardsShown()})
    }

    // We only show N number of cards at a time. this callback fn is called when user clicks to see the previous or next page of cards:
    toggleCardIndex(e, isForward) {
      let indexOffset = this.state.cardPageIndexOffset * this.state.numberOfCardsShown
      if ((isForward && (indexOffset + this.state.numberOfCardsShown > this.totalNumberOfCards - 1)) || (!isForward && indexOffset === 0)) { return }
      this.setState({cardPageIndexOffset: this.state.cardPageIndexOffset + (isForward ? 1 : -1)})
    }
  
    // renders a curriculum card inside a toggled pages view:
    renderCurriculumCardDiv(id, curriculumData, keyIndex) {
      let ratingImageWidth = curriculumData.rating * 20
      let gradeTitle = Database.getCurriculumGradeTitle(curriculumData)
      let key = this.idPrefix + '-subject-curriculum-card-' + keyIndex + '-' + id
      return (
        <div key={key} className="curriculum-view-card button">
          <img className="curriculum-view-card-image curriculum-view-image round-corners" key={key + '-img'} src={curriculumData.imageUrl} alt="" />
          <div className="flex-row">
            <span className="logo-color boxed-text curriculum-type-flag left-margin5 h8size">{curriculumData['single-unit'] ?  'Curriculum Unit' : 'Curriculum'}</span>
            {curriculumData['has-instruction-video'] ? <span className="logo-color boxed-text curriculum-type-flag left-margin5or0 right-margin0 h8size">&nbsp;<i className="fas fa-video" aria-hidden="true"></i>&nbsp;&nbsp;Instruction Video</span> : null}
          </div>
          <div className="curriculum-view-card-title-box">
            <span className="curriculum-view-card-title curriculum-view-title h4size weight500 lines-limit-2">{curriculumData.title}</span>
            <span className="curriculum-view-card-subtitle curriculum-view-subtitle lines-limit-1 h7size">{curriculumData.subtitle}</span>
          </div>
          <span className="curriculum-view-card-grade curriculum-view-grade h8size">{gradeTitle}</span>
          {Utility.renderCurriculumPrice(this.page, curriculumData, 'curriculum-view-card-price curriculum-view-price h4size weight500', true)}
          {Utility.renderRatingStars(this.idPrefix + '-subject-curriculum-card', 'curriculum-card', ratingImageWidth)}
        </div>
      )
    }

    render() {
      if (this.state.dataFetched) {
        this.state.numberOfCardsShown = this.calculateNumberOfCardsShown() 
        var curriculumsData = Database.getPopularOrLatestCurriculums(this.isPopularCurriculums, this.isUnitCurriculums)        
        this.totalNumberOfCards = curriculumsData.length
        if (this.totalNumberOfCards === 0)
          return (<div id="subject-curriculums-panel"></div>)
        let numToggledPages = Math.ceil(this.totalNumberOfCards / this.state.numberOfCardsShown)
        var idCounter = 1
        var idx = 0
        var numberOfCardsToShow = Math.min(this.totalNumberOfCards, this.state.numberOfCardsShown)
        let currIdxOffset = this.state.cardPageIndexOffset * this.state.numberOfCardsShown 
        const curriculumList = curriculumsData.slice(currIdxOffset, currIdxOffset + numberOfCardsToShow)    
        const curriculums = curriculumList.map((c) => {
          var curriculumData = curriculumList[idx++]
          var id = idCounter++                    
          return (
            Utility.renderDivLink(this.idPrefix + '-subject-curriculum-card-link-' + id, this.renderCurriculumCardDiv(id, curriculumData, currIdxOffset), 'preview?subject=' + curriculumData.subject + '&id=' + curriculumData.id)
          )
        })
        // render curriculum cards inside a toggled pages view:
        return (
          <div className="subject-curriculums-panel-container slow-fade-in">
            <span className="logo-font size39">{this.isPopularCurriculums ? 'Popular' : 'Latest'} {this.isUnitCurriculums ? 'curriculum units' : 'curriculums'}</span>                  
            {Utility.renderToggledPagesView(curriculums, this.state.cardPageIndexOffset, numToggledPages, this.toggleCardIndex.bind(this), this.idPrefix + '-subject-curriculums-panel', 'subject-curriculums-panel', this.idPrefix + '-subject-curriculums', 'subject-curriculums curriculum-view-span-container', 'subject-curriculums', 'subject-curriculums-toggle', false)}
          </div>
        )
      } else
        return (<div id="subject-curriculums-panel"></div>)
  }
}

export default HomePage
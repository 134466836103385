/*
    Admin site - Home page: form to enter id/data for the curriculum to be viewed...
*/

import React from 'react'
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs'
import 'react-web-tabs/dist/react-web-tabs.css'

import * as Config from './config'
import * as Database from './database-api'
import * as Fileserver from './fileserver-api'
import * as AdminUtility from './admin-utility'
import * as CreatorUtility from './creator-utility'
import * as Utility from './utility'


const globals = Config.globals
const dbConfig = Config.dbConfig
const generalConfig = Config.pages.general
const adminConfig = Config.pages.admin

class AdminLibraryPage extends AdminUtility.AdminLandingPage {
    constructor(props) {
        super(props)
        this.pageId = 'admin-libary'
        this.curriculumOptionsRef = React.createRef()
        this.curriculumFormRef = React.createRef()
        this.subjectOptionsRef = React.createRef()
        this.topicOptionsRef = React.createRef()
        this.languageOptionsRef = React.createRef()
        this.subjectFormRef = React.createRef()
        this.topicFormRef = React.createRef()
        this.languageFormRef = React.createRef()
        this.adminCurriculumPurchaseFormUsernameRef = React.createRef()
        this.adminCurriculumPurchaseFormCurriculumsRef = React.createRef()
        this.adminCurriculumPurchaseFormSubjectsRef = React.createRef()
        this.adminCurriculumPurchaseFormPricesRef = React.createRef()
        this.bulkEmailFormEmailsRef = React.createRef()
        this.bulkEmailFormCCEmailsRef = React.createRef()
        this.bulkEmailFormBCCEmailsRef = React.createRef()
        this.bulkEmailFormSubjectRef = React.createRef()
        this.bulkEmailFormBodyRef = React.createRef()
        this.bulkEmailFormOneAtATimeCheckboxRef = React.createRef()
        this.bulkEmailFormHasTemplateVarsCheckboxRef = React.createRef()      
        if (globals.haveLocalStorage)
            this.lastTabOpen = localStorage.getItem('termeric-page-' + this.pageId + '-last-tab-open')
    }

    // database API calls this when data for admin user in session's info is fetched
    // if the user is indeed admin, Database.cachedAdminUserInfo will be defined...
    adminUserDataFetched() {        
        super.adminUserDataFetched()
        this.setState({dataFetched: true})
        // Get all proposed subjects info:
        Database.admin_runFunction('database_fetchProposedSubjectsData', [])
    }

    proposedSubjectsDataFetched() {
        // Get all proposed topics info:
        Database.admin_runFunction('database_fetchProposedTopicsData', [])
        this.subjectOptionsRef.current.setState({dataFetched: true, options:
            [...Database.admin_runFunction('getProposedSubjects', []), 'Propose new']
        })        
    }

    proposedTopicsDataFetched() {
        // Get all proposed languages info:
        Database.admin_runFunction('database_fetchProposedLanguagesData', [])
        this.topicOptionsRef.current.setState({dataFetched: true, options:
            [...Database.admin_runFunction('getProposedTopics', []), 'Propose new']
        })
    }

    proposedLanguagesDataFetched() {
        Database.database_fetchCurriculumsData(undefined, false, true)
        this.languageOptionsRef.current.setState({dataFetched: true, options:
            [...Database.admin_runFunction('getProposedLanguages', []), 'Propose new']
        })
    }

    curriculumsDataFetched() {
        super.curriculumsDataFetched()
        if (this.curriculumOptionsRef.current)      
            this.curriculumOptionsRef.current.setState({dataFetched: true, options: [...Database.admin_runFunction('getSubmittedCurriculums', []), 'Enter ID']})      
    }

    openCurriculum() {
        let form = this.curriculumFormRef.current
        let err        
        if (form.curriculumFormSubjectRef.current != null) {
            let subject = form.curriculumFormSubjectRef.current.value
            let id = form.curriculumFormIdRef.current.value
            let randomKey = form.curriculumFormRandomKeyRef.current.value
            let teacher = form.curriculumFormTeacherRef.current.value            
            if (!subject) {
                err = 'Enter the subject'
            } else if (!id) {
                err = "Enter the id"
            } else if (!randomKey) {
                err = "Enter the random key"
            } else if (!teacher) {
                err = "Enter the teacher"
            }
        } else
            err = "Choose Curriculum ID first"
        if (err) {
            Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
        } else {
            this.setState({navigateTo: '/admin/view?subject=' + subject + '&id=' + id + '&random-key=' + randomKey + '&teacher=' + teacher})
        }
    }

    submitSubject() {
        let teacher = this.subjectOrTopicData.teacher
        let curriculum = this.subjectOrTopicData.curriculum
        let inProductions = this.subjectOrTopicData['in-productions']        
        let form = this.subjectFormRef.current
        let title = form.subjectFormTitleRef.current.value
        let subject = form.subjectFormKeyRef.current.value
        let prefix = form.subjectFormPrefixRef.current.value
        let sortIndexString = form.subjectFormSortIndexRef.current.value
        let sortIndex = parseInt(sortIndexString, 10)
        let icon = this.subjectIconUploaded ? this.subjectIconFileName : ''
        let isProposalFromCurriculumSubmission = !!teacher
        let err
        if (inProductions) {
            // FIXME: Check this!!
            err = 'Modifying subject or proposing a new subject for a live curriculum is not allowed... This is an error!'
        } else if (!title) {
            err = 'Enter the subject title'
        } else if (!Utility.validateTitle(title)) {
            err = 'Enter a valid, correctly capitalized subject title'
        } else if (!subject) {
            err = 'Enter the subject key'
        } else if (subject !== subject.toLowerCase()) {
            err = 'Enter a subject key with all lower case letters'
        } else if (!AdminUtility.validateSubjectKey(subject)) {
            err = 'Enter a subject key with only [a-z] characters with - for spaces'
        } else if (!prefix || prefix.length !== 2) {
            err = "Enter the subject prefix of 2 characters"
        } else if (!sortIndexString || ('' + sortIndex  !== '' + sortIndexString)) {
            err = "Enter a valid subject sort index"
        } else if (Database.isSubjectInDatabase(subject)) {
            err = "Enter a different subject. Current one is already in database"
        } else if (Database.admin_runFunction('subjectSortIndexExists', [sortIndex])) {
            err = "Enter a different sort index. Current one is already used"
        }
        if (err) {
            Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
        } else {
            let subjectData = {subject: subject, 'sort-index': sortIndex, prefix: prefix, icon: icon, title: title}
            let proposedSubjectData = {teacher: teacher, 'date-last-updated': this.subjectOrTopicData['date-last-updated']}
            let onSuccessFn = () => {
                Utility.showTextDialog(0, 'New subject is added.', generalConfig.dialogBoxOKIconClassName)
                // reset the form:
                this.subjectOptionsRef.current.setState({dataFetched: true, options:
                    [...Database.admin_runFunction('getProposedSubjects', []), 'Propose new']
                })                
                this.subjectFormRef.current.setState({data: undefined})
                return true
            }
            let onFailureFn = (err) => {
                console.log(err)
                return false
            }
            let onSubjectAddFn = () => {
                // step 2: if this proposal came from a teacher (and not a direct add by the admin),update tne entry for the curriculum who proposed this new subject in the curriculums table where it is:                
                if (isProposalFromCurriculumSubmission) {               
                    let keyParams = {teacher: {S: teacher}, id: {S: curriculum}}
                    Database.admin_runFunction('database_updateItem', [dbConfig.curriculumsSubmissionsTable, keyParams, 'set subject = :s', undefined, {':s': {S: subject}}, 'updating subject for the curriculum', onSuccessFn, onFailureFn])
                } else
                    onSuccessFn()
            }
            // step 1: add new subject
            Database.admin_runFunction('database_addNewSubject', [subjectData, proposedSubjectData, isProposalFromCurriculumSubmission, onSubjectAddFn, onFailureFn])
        }
    }

    submitTopic() {
        let teacher = this.subjectOrTopicData.teacher
        let curriculum = this.subjectOrTopicData.curriculum
        let subjectOrig = this.subjectOrTopicData.subject        
        let inProductions = this.subjectOrTopicData['in-productions']
        let form = this.topicFormRef.current
        let title = form.subjectFormTitleRef.current.value
        let subject = form.subjectFormKeyRef.current.value
        let topic = form.subjectFormTopicKeyRef.current.value
        let subjectIsUpdated = subject !== subjectOrig
        let isProposalFromCurriculumSubmission = !!teacher
        let err
        if (inProductions && subjectIsUpdated) {
            // FIXME: Check this!!
            err = 'Modifying subject or proposing a new subject for a live curriculum is not allowed... This is an error!'
        } else if (!title) {
            err = 'Enter the topic title'
        } else if (!Utility.validateTitle(title)) {
            err = 'Enter a valid, correctly capitalized topic title'
        } else if (!topic) {
            err = 'Enter the topic key'
        } else if (topic !== topic.toLowerCase()) {
            err = 'Enter the subject key with all lower case letters'
        } else if (!AdminUtility.validateSubjectKey(topic)) {
            err = 'Enter a topic key with only [a-z] characters with - for spaces'
        } else if (!subject) {
            err = 'Enter the subject key'
        } else if (!Database.isSubjectInDatabase(subject)) {
            err = 'No such subject key in database'
        } else if (Database.isTopicInDatabase(topic)) {
            err = "Enter a different topic. Current one is already in database"
        }
        if (err) {
            Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
        } else {            
            let topicData = {topic: topic, subject: subject, title: title}            
            let proposedTopicData = {teacher: teacher, 'date-last-updated': this.subjectOrTopicData['date-last-updated']}
            let onSuccessFn = () => {
                Utility.showTextDialog(0, 'New topic is added.', generalConfig.dialogBoxOKIconClassName)
                // reset the form:
                this.topicOptionsRef.current.setState({dataFetched: true, options:
                    [...Database.admin_runFunction('getProposedTopics', []), 'Propose new']
                })                        
                this.topicFormRef.current.setState({data: undefined})            
                return true
            }
            let onFailureFn = (err) => {
                console.log(err)
                return false
            }
            let onTopicAddFn = () => {
                // step 2: if this proposal came from a teacher (and not a direct add by the admin),update tne entry for the curriculum who proposed this new topic in the curriculums table where it is:
                if (isProposalFromCurriculumSubmission) {
                    let keyParams = inProductions ? {id: {S: curriculum}, subject: {S: subject}} : {teacher: {S: teacher}, id: {S: curriculum}}
                    let expressionAttributeNames = {'#tt': 'topic-title'}               
                    let expressionAttributeValues = {':t': {S: topic}, ':tt': {S: title}}
                    let updateExpressions = ['topic = :t', '#tt = :tt']
                    // if subject was changed, update that as well:
                    if (subjectIsUpdated) {
                        expressionAttributeValues[':s'] = {S: subject}
                        updateExpressions.push('subject = :s')
                    }
                    Database.admin_runFunction('database_updateItem', [inProductions ? dbConfig.curriculumsTable : dbConfig.curriculumsSubmissionsTable, keyParams, 'set ' + updateExpressions.join(', ') , expressionAttributeNames, expressionAttributeValues, 'updating topic for the curriculum', onSuccessFn, onFailureFn])
                } else
                    onSuccessFn()
            }
            // step 1: add new topic
            Database.admin_runFunction('database_addNewTopic', [topicData, proposedTopicData, isProposalFromCurriculumSubmission, onTopicAddFn, onFailureFn])
        }
    }
    
    submitLanguage() {
        let teacher = this.languageData.teacher
        let curriculum = this.languageData.curriculum        
        let inProductions = this.languageData['in-productions']
        let subject = this.languageData.subject
        let form = this.languageFormRef.current
        let title = form.languageFormTitleRef.current.value        
        let language = form.languageFormLanguageCodeRef.current.value        
        let isProposalFromCurriculumSubmission = !!teacher
        let err
        if (!title) {
            err = 'Enter the language title'
        } else if (!Utility.validateTitle(title)) {
            err = 'Enter a valid, correctly capitalized language title.'
        } else if (!language) {
            err = 'Enter the language key.'
        } else if (language !== language.toLowerCase()) {
            err = 'Enter the subject key with all lower case letters.'
        } else if (!AdminUtility.validateLanguageCode(language)) {
            err = 'Enter a language key in ISO 639-1 two-letter code.'
        } else if (Database.isLanguageInDatabase(language)) {
            err = "Enter a different language. Current one is already in database."
        }
        if (err) {
            Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
        } else {            
            let languageData = {language: language, title: title}            
            let onSuccessFn = () => {
                Utility.showTextDialog(0, 'New language is added.', generalConfig.dialogBoxOKIconClassName)
                // reset the form:
                this.languageOptionsRef.current.setState({dataFetched: true, options:
                    [...Database.admin_runFunction('getProposedLanguages', []), 'Add new']
                })
                this.languageFormRef.current.setState({data: undefined})            
                return true
            }
            let onFailureFn = (err) => {
                console.log(err)
                return false
            }
            let onLanguageAddFn = () => {                
                // step 2: if this proposal came from a teacher (and not a direct add by the admin),update tne entry for the curriculum who proposed this new language in the curriculums table where it is:
                if (isProposalFromCurriculumSubmission) {
                    let keyParams = inProductions ? {id: {S: curriculum}, subject: {S: subject}} : {teacher: {S: teacher}, id: {S: curriculum}}
                    let expressionAttributeNames = {'#tl': 'the-language'}               
                    let expressionAttributeValues = {':l': {S: language}}
                    let updateExpressions = ['#tl = :l']            
                    Database.admin_runFunction('database_updateItem', [inProductions ? dbConfig.curriculumsTable : dbConfig.curriculumsSubmissionsTable, keyParams, 'set ' + updateExpressions.join(', ') , expressionAttributeNames, expressionAttributeValues, 'updating language for the curriculum', onSuccessFn, onFailureFn])
                } else
                    onSuccessFn()
            }
            // step 1: add new language by letting admin confirm language added in the code and (to be) pushed to live website...:
            Utility.showTextDialog(0, 'Please add the language code and title to the website code now:\ncode="' + languageData.language + '", ' + ' title="' + languageData.title + '"\nOnce done, click Proceed.', generalConfig.dialogBoxInfoIconClassName, undefined, ['Cancel', 'Proceed'], [undefined, onLanguageAddFn])
        }
    }
    
    // submits to the database a curriculum purchase manually entered by the admin
    // (used when purchases are made on external sites such as TpT and we want to 
    // transfer the purchase to our site too so that user can access their curriculum)
    submitAdminCurriculumPurchase() {        
        let username = this.adminCurriculumPurchaseFormUsernameRef.current.value.trim()
        let curriculums = this.adminCurriculumPurchaseFormCurriculumsRef.current.value.trim().split(',').filter(i => i).map(i => { return i.trim() })
        let subjects = this.adminCurriculumPurchaseFormSubjectsRef.current.value.trim().split(',').filter(i => i).map(i => { return i.trim() })
        let total = 0
        let prices = this.adminCurriculumPurchaseFormPricesRef.current.value.trim().split(',').filter(i => i).map(i => { let p = parseInt(i.trim(), 10); total += p; return p })
        
        let err = false
        if (!username)
            err = 'Enter username.'
        else if (curriculums.length === 0)
            err = 'Enter 1 or more curriculums/subjects/prices.'
        else if (curriculums.length !== subjects.length || curriculums.length !== prices.length)
            err = 'Make sure the count of items for the fields "Curriculums", "Subjects", and "Prices" are all the same.' 
        if (err) {
            Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
            return
        }
        let onSuccessFn = () => {
            Utility.showTextDialog(0, 'Curriculum purchase(s) for the user submitted.', generalConfig.dialogBoxOKIconClassName)
            // reset the form
            let refs = [this.adminCurriculumPurchaseFormUsernameRef, this.adminCurriculumPurchaseFormCurriculumsRef, this.adminCurriculumPurchaseFormSubjectsRef, this.adminCurriculumPurchaseFormPricesRef]
            refs.forEach(r => r.current.value = '')
        }
        let onFailureFn = (err) => {
            Utility.showTextDialog(0, 'Error: ' + err, generalConfig.dialogBoxOKIconClassName)
            console.log(err)
            return false
        }
        let checkoutData = {username: username, curriculums: curriculums, curriculumSubjects: subjects, pricesLocal: prices, pricesUSD: prices, total: {local: total, USD: total}}
        Database.admin_runFunction('database_adminCurriculumPurchase', [checkoutData, onSuccessFn, onFailureFn])
    }

    // sends bulk email to listed comma separated email list
    sendBulkEmail() {
        let recipients = this.bulkEmailFormEmailsRef.current.value.trim().split(',').filter(i => i).map(i => { return {S: i.trim()} })
        let recipientsCC = this.bulkEmailFormCCEmailsRef.current.value.trim().split(',').filter(i => i).map(i => { return {S: i.trim()} })
        let recipientsBCC = this.bulkEmailFormBCCEmailsRef.current.value.trim().split(',').filter(i => i).map(i => { return {S: i.trim()} })
        let subject = this.bulkEmailFormSubjectRef.current.value.trim()
        let body = this.bulkEmailFormBodyRef.current.value.trim()
        let isOneAtATime = Utility.isCheckboxChecked(this.bulkEmailFormOneAtATimeCheckboxRef.current)
        let hasTemplateVars = Utility.isCheckboxChecked(this.bulkEmailFormHasTemplateVarsCheckboxRef.current)
        // make sure admin turns on template vars replacement option if body has template vars in it:
        let maxBulkEmailRecipients = adminConfig.maxBulkEmailRecipients || 20
        let allTemplateVarsMatch = body.match(/\[\[[^\[\]]+\]\]/g) || []
        let nameTemplateVarsMatch = body.match(/\[\[NAME\]\]/g) || []
        let badRecipients = [recipients, recipientsCC, recipientsBCC].map(l => l.filter(i => !Utility.validateEmailAddressHeader(i.S)))
        let err = false
        if (recipients.length + recipientsCC.length + recipientsBCC.length === 0)
            err = 'Enter recipients or CC or BCC.'
        else if (recipients.length > maxBulkEmailRecipients || recipientsCC.length > maxBulkEmailRecipients || recipientsBCC.length > maxBulkEmailRecipients)
            err = 'Max number of recipients is ' + maxBulkEmailRecipients + '.'        
        else if (!(subject || body))
            err = 'Enter subject and body.'
        else if (!isOneAtATime && hasTemplateVars)
            err = 'Turn on the "Send one at a time" option if you want to include template variables in the body of email.'
        else if (allTemplateVarsMatch.length !== nameTemplateVarsMatch.length)
            err = 'Looks like the body of email contains template variables: [[???]]\nPlease use the following allowed variables and try again:\n[[NAME]]'
        else if (allTemplateVarsMatch.length > 0 && !hasTemplateVars)
            err = 'Looks like the body of email contains template variables: [[NAME]]\nTurn on the option "Replace template variables in body" and try again.'
        if (!err) {            
            for (let badRecipientsList of badRecipients) { 
                if (badRecipientsList.length > 0) { 
                    err = 'Invalid email address(es):\n' + badRecipientsList.map(i => i.S).join(', ')
                    break
                }
            }
        }
        if (err) {
            Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
            return
        }
        let item = {username: {S: Utility.getSessionUsername()}, 'date-sent': {N: '' + Utility.getEpochTime()}, title: {S: subject}, message: {S: body}, recipients: {L: recipients}, 'recipients-cc': {L: recipientsCC}, 'recipients-bcc': {L: recipientsBCC}, 'is-bulk': {BOOL: true}, 'is-one-at-a-time': {BOOL: isOneAtATime}, 'has-template-vars': {BOOL: hasTemplateVars}}        
        // send the email request to db for lambda to pick it up:
        Database.admin_runFunction('database_putItem', [dbConfig.emailsOutboxQueueTable, item, 'submitting bulk email request'])
        Utility.showTextDialog(0, 'Your bulk email request is sent.', generalConfig.dialogBoxOKIconClassName)        
        // reset the form
        let refs = [this.bulkEmailFormEmailsRef, this.bulkEmailFormCCEmailsRef, this.bulkEmailFormBCCEmailsRef, this.bulkEmailFormSubjectRef, this.bulkEmailFormBodyRef]
        refs.forEach(r => r.current.value = '')
    }

    // use this function for 1-time custom misc tasks...:
    async runCustomTask() {
        /*
        var lessons = await Database.database_async_scanTable(dbConfig.lessonsTable)
        var count = 0
        for (var lesson of lessons.Items) {
            if (lesson.teacher.S != 'us-east-1:9b172367-0b32-46fc-97ac-167992f3c7b7') {
                count += 1
                console.log(lesson.id.S)
                let keyParams = {curriculum: lesson.curriculum, id: lesson.id}
                //console.log('database_updateItem', [dbConfig.lessonsTable, keyParams, 'set teacher = :s', undefined, {':s': {S: 'us-east-1:9b172367-0b32-46fc-97ac-167992f3c7b7'}}, 'updating teacher for the lesson', undefined, undefined])
                Database.admin_runFunction('database_updateItem', [dbConfig.lessonsTable, keyParams, 'set teacher = :s', undefined, {':s': {S: 'us-east-1:9b172367-0b32-46fc-97ac-167992f3c7b7'}}, 'updating teacher for the lesson', undefined, undefined])
                if (count > 10)
                    break
            }
        }
        */
        /*
        let curriculumsData = {}
        let curriculumsDataList = await Database.database_async_scanTable(dbConfig.curriculumsTable)
        for (let curriculumData of curriculumsDataList.Items)
            curriculumsData[curriculumData.id.S] = curriculumData     
        let sales = await Database.database_async_scanTable(dbConfig.salesTable)
        sales.Items.sort((s1, s2) => parseInt(s1['date-purchased'].N, 10) - parseInt(s2['date-purchased'].N, 10))
        for (let sale of sales.Items) {            
            let priceUSD = parseInt(sale['total-price-usd'].N / sale.curriculums.L.length, 10)
            for (let curriculumT of sale.curriculums.L) {
                let curriculum = curriculumT.S
                let curriculumData = curriculumsData[curriculum]
                if (curriculumData != null) {
                    let item = {teacher: curriculumData.teacher, 'date-purchased': sale['date-purchased'], curriculum: curriculumT, subject: curriculumData.subject, 'price-usd': {N: '' + priceUSD}, username: sale.username}
                    console.log(sale, item)
                    await Database.admin_runFunction('database_putItem', [dbConfig.salesPerTeacherTable, item, 'adding sale per teacher item'])
                }
            }
        }
        */
        Utility.showTextDialog(0, 'Your task is done.', generalConfig.dialogBoxOKIconClassName)
    }


    subjectIconOnSuccessfulDropFn(file, fileName, origFileName) {
        let form = this.subjectFormRef.current
        let subject = form.subjectFormKeyRef.current.value //form.proposedSubject
        let fileExt = file.type.replace('image/', '')
        this.subjectIconFileName = subject + '-icon.' + fileExt
        let onUploadFailureFn = () => {
            form.subjectFormUploadDropZoneRef.current.onUploadFailureFn()
        }
        if (!subject) {
            Utility.showTextDialog(0, 'Enter a key for the proposed subject first.', generalConfig.dialogBoxErrorIconClassName)
            onUploadFailureFn()
            return
        } 
        let onUploadSuccessFn = () => {
            this.subjectIconUploaded = true
            let url = Fileserver.getSubjectIconUrl(subject, this.subjectIconFileName)            
            form.subjectFormUploadDropZoneRef.current.onUploadSuccessFn(url)
        }            
        Fileserver.admin_runFunction(Database, 'uploadSubjectIcon', [subject, this.subjectIconFileName, file, onUploadSuccessFn, onUploadFailureFn])
    }

    renderBody() {
        if (Database.isAdminUserDataFetched()) {
            return (
            <Tabs id="admin-body-content" className="page-body-listing-content page-tabs-with-top-margin page-body" defaultTab={this.lastTabOpen || 'curriculum'}
                onChange={(tabId) => { 
                    this.isSubjectProposal = tabId === 'proposed-subjects'
                    if (globals.haveLocalStorage) 
                        localStorage.setItem('termeric-page-' + this.pageId + '-last-tab-open', tabId) 
                    }} >
                <TabList>
                    <Tab tabFor='curriculum'><span className="tab-header">Curriculum Submissions</span></Tab>
                    <Tab tabFor='proposed-subjects'><span className="tab-header">Subject Proposals</span></Tab>
                    <Tab tabFor='proposed-topics'><span className="tab-header">Topic Proposals</span></Tab>                    
                    <Tab tabFor='proposed-languages'><span className="tab-header">Language Proposals</span></Tab>
                    <Tab tabFor='admin-curriculum-purchase'><span className="tab-header">Admin Curriculum Purchase</span></Tab>
                    <Tab tabFor='bulk-email'><span className="tab-header">Bulk Email</span></Tab>
                    <Tab tabFor='custom-task'><span className="tab-header">Custom Task</span></Tab>
                </TabList>
                <TabPanel tabId='curriculum'>
                    {this.renderCurriculumSearch()}
                </TabPanel>
                <TabPanel tabId='proposed-subjects'>
                    {this.renderProposedSubjectOrTopics(true)}
                </TabPanel>
                <TabPanel tabId='proposed-topics'>
                    {this.renderProposedSubjectOrTopics(false)}
                </TabPanel>
                <TabPanel tabId='proposed-languages'>
                    {this.renderProposedLanguages()}
                </TabPanel>
                <TabPanel tabId='admin-curriculum-purchase'>
                    {this.renderAdminCurriculumPurchase()}
                </TabPanel>
                <TabPanel tabId='bulk-email'>
                    {this.renderBulkEmail()}
                </TabPanel>
                <TabPanel tabId='custom-task'>
                    {this.renderCustomTask()}
                </TabPanel>
            </Tabs>
            )
        } else
            return (
                <div id="admin-body-content" className="page-body">
                    <span className="opac"><em>Admin login in progress...</em></span>
                </div>
                )
    }

    renderCurriculumSearch() {
        return (
            <div id="admin-curriculum-search-tab" className="flex-col section page-body">
                <div id="admin-new-top-section" className="page-body-top-listing section">
                    <div>
                        <h2 className="size31">Select a curriculum</h2>
                        <span className="form-input-description"><em className="opac">Use this to view any curriculum by its id. Curriculum submissions from creators will have their ids listed here for convenience.</em></span>
                    </div>
                    <div id="admin-curriculum-search-submit" className="flex-col form-side-panel curriculum-listings-side-panel opac-background">
                        {Utility.renderButton('View Curriculum', 'admin-curriculum-search-submit-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', this.openCurriculum.bind(this))}
                    </div>
                </div> 
                <div id="admin-curriculum-select" className="form flex-col">
                    <div className="form-item flex-col">                            
                        <label>Curriculum ID</label>
                        <div className="flex-row flex-center">
                            <CurriculumOptions page={this} id={'admin-form-curriculum-select'} label={'Choose curriculum'} extraClasses={'form-select-div'} optionsDivExtraClasses={'form-'} optionKeyToTitleFn={(idx, s) => { return s }} ref={this.curriculumOptionsRef} />
                        </div>
                    </div>
                </div>
                <hr className="full-width" />
                <CurriculumForm page={this} ref={this.curriculumFormRef} />
            </div>
        )
    }    

    renderProposedSubjectOrTopics(isSubjectProposal) {
        let label = isSubjectProposal ? 'subject' : 'topic'
        return (
            <div className="flex-col section page-body">
                <div id="admin-new-top-section" className="page-body-top-listing section">
                    <div>
                        <h2 className="size31">Select a proposed {label}</h2>
                        <span className="form-input-description"><em className="opac">Use this to view new {isSubjectProposal ? 'subject' : 'topic'} proposals submitted by the creator teachers and approve them to be officially added to the database.</em></span>
                    </div>
                    <div id="admin-curriculum-search-submit" className="flex-col form-side-panel curriculum-listings-side-panel opac-background">
                        {Utility.renderButton('Submit '+ label, 'admin-' + label + '-submit-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', this[isSubjectProposal ? 'submitSubject' : 'submitTopic'].bind(this))}
                    </div>
                </div> 
                <div id="admin-proposed-subject-select" className="form flex-col">                        
                    <div className="form-item flex-col">                            
                        <label>Subject</label>
                        <div className="flex-row flex-center">
                            <SubjectOrTopicOptions page={this} label={'Choose ' + label} isSubjects={isSubjectProposal} 
                            id={'admin-form-subject-select'} extraClasses={'form-select-div'} optionsDivExtraClasses={'form-'} optionKeyToTitleFn={(idx, s) => { return s}}
                            ref={isSubjectProposal ? this.subjectOptionsRef : this.topicOptionsRef}                             
                            />
                        </div>
                    </div>
                </div>
                <hr className="full-width" />
                <SubjectOrTopicForm isSubjectProposal={isSubjectProposal} page={this} ref={isSubjectProposal ? this.subjectFormRef : this.topicFormRef} />
            </div>
        )
    }

    renderProposedLanguages() {
        return (
            <div className="flex-col section page-body">
                <div id="admin-new-top-section" className="page-body-top-listing section">
                    <div>
                        <h2 className="size31">Select a proposed language</h2>
                        <span className="form-input-description"><em className="opac">Use this to view new language proposals submitted by the creator teachers and approve them to be officially added to the database.</em></span>
                    </div>
                    <div id="admin-curriculum-search-submit" className="flex-col form-side-panel curriculum-listings-side-panel opac-background">
                        {Utility.renderButton('Submit language', 'admin-language-submit-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', this.submitLanguage.bind(this))}
                    </div>
                </div> 
                <div id="admin-proposed-subject-select" className="form flex-col">                        
                    <div className="form-item flex-col">                            
                        <label>Language</label>
                        <div className="flex-row flex-center">
                            <LanguageOptions page={this} label={'Choose language'}
                            id={'admin-form-language-select'} extraClasses={'form-select-div'} optionsDivExtraClasses={'form-'} optionKeyToTitleFn={(idx, s) => { return s}} ref={this.languageOptionsRef}
                            />
                        </div>
                    </div>
                </div>
                <hr className="full-width" />
                <LanguageForm page={this} ref={this.languageFormRef} />
            </div>
        )
    }    
    
    renderAdminCurriculumPurchase() {
        return (
            <div className="flex-col section page-body">
                <div id="admin-new-top-section" className="page-body-top-listing section">
                    <div>
                        <h2 className="size31">Submit curriculum purchase</h2>
                        <span className="form-input-description"><em className="opac">Use this when purchases are made on other sites such as TpT and we want to register their purchase here, so that the user can access their curriculums on this site.</em></span>
                    </div>
                    <div id="admin-curriculum-admin-curriculum-purchase-send-submit" className="flex-col form-side-panel curriculum-listings-side-panel opac-background">
                        {Utility.renderButton('Submit curriculum purchase', 'admin-language-submit-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', this.submitAdminCurriculumPurchase.bind(this))}
                    </div>
                </div> 
                <div id="admin-admin-curriculum-purchase-form" className="form-container form flex-col">
                    <div className="form-item flex-col">
                        <label>Username</label>
                        <input type="text" placeholder="Username" defaultValue={''} ref={this.adminCurriculumPurchaseFormUsernameRef} />
                    </div>                     
                    <div className="form-item flex-col">
                        <label>Curriculums</label>
                        <span className="form-input-description"><em className="opac">Enter curriculm ids here.</em></span>
                        <input type="text" placeholder="Curriculum ids separated by commas" defaultValue={''} ref={this.adminCurriculumPurchaseFormCurriculumsRef} />
                    </div>
                    <div className="form-item flex-col">
                        <label>Subjects</label>
                        <span className="form-input-description"><em className="opac">Enter subject ids here.</em></span>
                        <input type="text" placeholder="Subject ids separate by commas" defaultValue={''} ref={this.adminCurriculumPurchaseFormSubjectsRef} />
                    </div>
                    <div className="form-item flex-col">
                        <label>Prices</label>
                        <span className="form-input-description"><em className="opac">Enter prices (USD) (numeric values) here.</em></span>
                        <input type="text" placeholder="Price numbers separate by commas" defaultValue={''} ref={this.adminCurriculumPurchaseFormPricesRef} />
                    </div>                                        
                </div>
            </div>
        )
    }


    renderBulkEmail() {
        return (
            <div className="flex-col section page-body">
                <div id="admin-new-top-section" className="page-body-top-listing section">
                    <div>
                        <h2 className="size31">Send bulk email</h2>
                        <span className="form-input-description"><em className="opac">Use this to send bulk email to multiple recipients (all at once or one at a time).</em></span>
                    </div>
                    <div id="admin-curriculum-bulk-email-send-submit" className="flex-col form-side-panel curriculum-listings-side-panel opac-background">
                        {Utility.renderButton('Send bulk email', 'admin-language-submit-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', this.sendBulkEmail.bind(this))}
                    </div>
                </div> 
                <div id="admin-bulk-email-form" className="form-container form flex-col">                        
                    <div className="form-item flex-col">
                        <label>Email Addresses</label>
                        <span className="form-input-description"><em className="opac">Use the format JOHN DOE &lt;john.doe@email.com&gt; for each recipient if using the template varible [[NAME]].</em></span>
                        <input type="text" placeholder="To email addresses separated by commas" defaultValue={''} ref={this.bulkEmailFormEmailsRef} />
                    </div>
                    <div className="form-item flex-col">
                        <label>CC Addresses</label>
                        <input type="text" placeholder="CC email addresses separate by commas" defaultValue={''} ref={this.bulkEmailFormCCEmailsRef} />
                    </div>
                    <div className="form-item flex-col">
                        <label>BCC Addresses</label>
                        <input type="text" placeholder="BCC email addresses separate by commas" defaultValue={generalConfig.teamGmail} ref={this.bulkEmailFormBCCEmailsRef} />
                    </div>
                    <div className="form-item flex-col">
                        <label>Subject</label>
                        <input type="text" placeholder="A title for your message" defaultValue={''} ref={this.bulkEmailFormSubjectRef} />
                    </div>
                    <div className="form-item flex-col">
                        <label>Body</label>
                        <span className="form-input-description"><em className="opac">Use template variable [[NAME]] to be replaced with the name in the email address header.</em></span>
                        <textarea id="contact-form-message" className="textarea" placeholder="Write a message..." ref={this.bulkEmailFormBodyRef} />
                    </div>
                    <div className="form-item flex-col">
                        {Utility.renderCheckbox('admin-bulk-email-form-checkbox-has-template-variables', false, 'Replace template variables in body', this.bulkEmailFormHasTemplateVarsCheckboxRef, 'text-input-list-item-element')}
                    </div>
                    <div className="form-item flex-col">
                        {Utility.renderCheckbox('admin-bulk-email-form-checkbox-one-at-a-time', false, 'Send one at a time', this.bulkEmailFormOneAtATimeCheckboxRef, 'text-input-list-item-element')}
                    </div>
                </div>
            </div>
        )
    }


    renderCustomTask() {
        return (
            <div className="flex-col section page-body">
                <div id="admin-new-top-section" className="page-body-top-listing section">
                    <div>
                        <h2 className="size31">Run custom task</h2>
                        <span className="form-input-description"><em className="opac">Use this to run whatever custom task as is currently coded inside the admin-library.js code.</em></span>
                    </div>
                    <div id="admin-curriculum-bulk-email-send-submit" className="flex-col form-side-panel curriculum-listings-side-panel opac-background">
                        {Utility.renderButton('Run custom task', 'admin-language-submit-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', this.runCustomTask.bind(this))}
                    </div>
                </div>             
            </div>
        )
    }
}

class CurriculumOptions extends Utility.UpdatableOptionsSelect {
    
    // if the pulldown menu selection is changed...
    onChangeFn(e, val) {
        if (val) {
            let isEnterId = val === 'Enter ID'
            let curriculum = val  
            let curriculumData = isEnterId ? {} : Database.getCurriculumData(curriculum)
            this.page.curriculumData = curriculumData            
            this.page.curriculumFormRef.current.setState({isEnterId: isEnterId, data: curriculumData})
        }
    }
}

class SubjectOrTopicOptions extends Utility.UpdatableOptionsSelect {
    constructor(props) {
        super(props)
        this.isSubjects = props.isSubjects
    }

    // if the search subject pulldown menu is changed...
    onChangeFn(e, val) {
        if (val) {
            let isNewPropose = val === 'Propose new'            
            let titleKey = val            
            let subjectOrTopicData = isNewPropose ? {} : Database.admin_runFunction(this.isSubjects ?'getProposedSubjectData' : 'getProposedTopicData', [titleKey])
            this.page.subjectOrTopicData = subjectOrTopicData            
            this.page[this.isSubjects ? 'subjectFormRef' : 'topicFormRef'].current.setState({data: subjectOrTopicData})
        }
    }   
}

class LanguageOptions extends Utility.UpdatableOptionsSelect {
    
    // if the language pulldown menu is changed...
    onChangeFn(e, val) {
        if (val) {
            let isNewPropose = val === 'Propose new'            
            let titleKey = val            
            let languageData = isNewPropose ? {} : Database.admin_runFunction('getProposedLanguageData', [titleKey])
            this.page.languageData = languageData            
            this.page.languageFormRef.current.setState({data: languageData})
        }
    }
}

class CurriculumForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isEnterId: false,
            data: undefined
        }
        this.curriculumFormSubjectRef = React.createRef()
        this.curriculumFormIdRef = React.createRef()
        this.curriculumFormRandomKeyRef = React.createRef()
        this.curriculumFormTeacherRef = React.createRef()
    }

    render() {
        let data = this.state.data
        if (data) {
            let isSelectedCurriculum = !this.state.isEnterId
            return (
                <div className="flex-row flex-center" key={Utility.getEpochTime()}>
                    <div id="admin-curriculum-search-form" className="form-container form form-col flex-col">
                        <div className="form-item flex-col">
                            <label>Subject</label>
                            <input type="text" placeholder="subject" defaultValue={data.subject || globals.urlParams.subject || undefined} disabled={isSelectedCurriculum} ref={this.curriculumFormSubjectRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Id</label>
                            <input type="text" placeholder="id" defaultValue={data.id || globals.urlParams.id || undefined} disabled={isSelectedCurriculum} ref={this.curriculumFormIdRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Random Key</label>
                            <input type="text" placeholder="random-key" defaultValue={data['random-key'] || globals.urlParams['random-key'] || undefined} disabled={isSelectedCurriculum} ref={this.curriculumFormRandomKeyRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Teacher</label>
                            <input type="text" defaultValue={data.teacher || globals.urlParams.teacher || undefined} placeholder="teacher" disabled={isSelectedCurriculum} ref={this.curriculumFormTeacherRef} />
                        </div>
                    </div>
                </div>
            )
        } else
            return null        
    }
}

class SubjectOrTopicForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: undefined
        }
        this.isSubjectProposal = props.isSubjectProposal
        this.page = props.page
        this.subjectFormTeacherRef = React.createRef()
        this.subjectFormCurriculumRef = React.createRef()
        this.subjectFormCurriculumTitleRef = React.createRef()
        this.subjectFormCurriculumStatusRef = React.createRef()
        this.subjectFormProposalDateRef = React.createRef()
        this.subjectFormTitleRef = React.createRef()
        this.subjectFormKeyRef = React.createRef()
        this.subjectFormTopicKeyRef = React.createRef()
        this.subjectFormPrefixRef = React.createRef()
        this.subjectFormSortIndexRef = React.createRef()
        this.subjectFormUploadDropZoneRef = React.createRef()
        this.subjectIconUploaded = false
        this.uploadDropZoneNewMessage = 'Drop an image (aspect ratio 1:1) file here, or click to select...'
        this.uploadDropZoneEditMessage = '✓ Image uploaded. To change it, drop an image (aspect ratio 1:1) file here or click to select...'      
    }

    render() {
        let data = this.state.data
        if (data) {
            let isSubjectProposal = this.isSubjectProposal
            this.proposedSubjectOrTopicTitle = data[isSubjectProposal ? 'subject' : 'topic'] || ''
            let proposedTitleKey = this.proposedSubjectOrTopicTitle.toLowerCase().split(' ').join('-')
            this.proposedSubject = isSubjectProposal ? proposedTitleKey : data.subject ? data.subject.toLowerCase().split(' ').join('-') : ''
            this.proposedTopic = isSubjectProposal ? undefined : proposedTitleKey     
            return (
                // adding random key to force render each time new option is selected:
                <div className="flex-row flex-center" key={Utility.getEpochTime()}>
                    <div id="admin-curriculum-language-form-1" className="form-container form form-col flex-col">
                        <div className="form-item flex-col">
                            <label>Teacher</label>
                            <input type="text" placeholder="teacher" defaultValue={data.teacher || 'N/A'} disabled={true} ref={this.subjectFormTeacherRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Curriculum</label>
                            <input type="text" placeholder="curriculum" defaultValue={data.curriculum  || 'N/A'}  disabled={true} ref={this.subjectFormCurriculumRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Curriculum Title</label>
                            <input type="text" placeholder="curriculum title" defaultValue={data.title  || 'N/A'}  disabled={true} ref={this.subjectFormCurriculumTitleRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Curriculum Status</label>
                            <input type="text" placeholder="curriculum status" defaultValue={data['in-productions'] !== undefined ? (data['in-productions'] ? 'live' : 'submission') : 'N/A'}  disabled={true} ref={this.subjectFormCurriculumStatusRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Proposal Date</label>
                            <input type="text" placeholder="proposal date" defaultValue={data['date-last-updated'] !== undefined ? ('' + new Date(1000 * parseInt(data['date-last-updated'], 10))) : 'N/A'}  disabled={true} ref={this.subjectFormProposalDateRef} />
                        </div>
                    </div>
                    <div id="admin-curriculum-language-form-2" className="form-container form form-col flex-col">
                        <div className="form-item flex-col">
                            <label>{isSubjectProposal ? 'Subject' : 'Topic'} Title</label>
                            <input type="text" placeholder="" defaultValue={this.proposedSubjectOrTopicTitle} ref={this.subjectFormTitleRef} />
                        </div>
                        <div>
                            {isSubjectProposal ? null :
                                <div className="form-item flex-col">
                                    <label>Topic Key</label>
                                    <input type="text" placeholder="" defaultValue={this.proposedTopic || ''} ref={this.subjectFormTopicKeyRef} />
                                </div>
                            }
                        </div>
                        <div className="form-item flex-col">
                            <label>Subject Key</label>
                            <input type="text" placeholder="" defaultValue={this.proposedSubject || ''} ref={this.subjectFormKeyRef} />
                        </div>
                        {isSubjectProposal ? (
                            <div>
                                <div className="form-item flex-col">
                                    <label>Subject Prefix</label>
                                    <input type="text" placeholder="" defaultValue={this.proposedSubject.slice(0, 2)} ref={this.subjectFormPrefixRef} />
                                </div>
                                <div className="form-item flex-col">
                                    <label>Sort Index</label>
                                    <input type="text" placeholder="0000" defaultValue={Database.admin_runFunction('generateNewSubjectSortIndex', [])} ref={this.subjectFormSortIndexRef} />
                                </div>
                                <div className="form-item flex-col">
                                    <label>Icon</label>
                                    <CreatorUtility.FileUploadDropZone accept={'image/*'} hasImagePreview={true} extraClasses={'teacher-dropzone'} isNew={true} previewImagePlaceHolder={generalConfig.subjectIconPlaceHolder} previewFile={undefined} newMessage={this.uploadDropZoneNewMessage} editMessage={this.uploadDropZoneEditMessage} onSuccessfulDropFn={this.page.subjectIconOnSuccessfulDropFn.bind(this.page)} ref={this.subjectFormUploadDropZoneRef} />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                ) 
        } else
            return null
    }
}

class LanguageForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: undefined
        }        
        this.page = props.page
        this.languageFormTeacherRef = React.createRef()
        this.languageFormCurriculumRef = React.createRef()
        this.languageFormCurriculumTitleRef = React.createRef()
        this.languageFormCurriculumStatusRef = React.createRef()
        this.languageFormProposalDateRef = React.createRef()
        this.languageFormTitleRef = React.createRef()
        this.languageFormLanguageCodeRef = React.createRef()        
    }

    render() {
        let data = this.state.data        
        if (data) {
            this.proposedLanguageTitle = data['the-language'] || ''
            let proposedTitleKey = this.proposedLanguageTitle.toLowerCase().substring(0, 2)
            this.proposedLanguage = proposedTitleKey
            return (
                // adding random key to force render each time new option is selected:
                <div className="flex-row flex-center" key={Utility.getEpochTime()}>
                    <div id="admin-curriculum-language-form-1" className="form-container form form-col flex-col">
                        <div className="form-item flex-col">
                            <label>Teacher</label>
                            <input type="text" placeholder="teacher" defaultValue={data.teacher || 'N/A'} disabled={true} ref={this.languageFormTeacherRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Curriculum</label>
                            <input type="text" placeholder="curriculum" defaultValue={data.curriculum  || 'N/A'}  disabled={true} ref={this.languageFormCurriculumRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Curriculum Title</label>
                            <input type="text" placeholder="curriculum title" defaultValue={data.title  || 'N/A'}  disabled={true} ref={this.languageFormCurriculumTitleRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Curriculum Status</label>
                            <input type="text" placeholder="curriculum status" defaultValue={data['in-productions'] !== undefined ? (data['in-productions'] ? 'live' : 'submission') : 'N/A'}  disabled={true} ref={this.languageFormCurriculumStatusRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Proposal Date</label>
                            <input type="text" placeholder="proposal date" defaultValue={data['date-last-updated'] !== undefined ? ('' + new Date(1000 * parseInt(data['date-last-updated'], 10))) : 'N/A'}  disabled={true} ref={this.languageFormProposalDateRef} />
                        </div>
                    </div>
                    <div id="admin-curriculum-language-form-2" className="form-container form form-col flex-col">
                        <div className="form-item flex-col">
                            <label>Language Title</label>
                            <input type="text" placeholder="" defaultValue={this.proposedLanguageTitle} ref={this.languageFormTitleRef} />
                        </div>                        
                        <div className="form-item flex-col">
                            <label>Language Code</label>
                            <input type="text" placeholder="ISO 639-1 two-letter code" defaultValue={this.proposedLanguage || ''} ref={this.languageFormLanguageCodeRef} />
                        </div>                        
                    </div>
                </div>
                ) 
        } else
            return null
    }
}

export default AdminLibraryPage

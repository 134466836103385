/*
 This file keeps various config data (database, server, etc.) and also globals for the application
*/

// FIXME: Moved .env environment variables here instead of .env due to problem building with dotenv:
export const reactAppEnv = {
    REACT_APP_SITE_DOWN: false, // for flaging site as down for maintenance
    REACT_APP_SITE_TITLE: 'Termeric — Buy & Sell Ready-to-Teach Curriculums',
    REACT_APP_SITE_DESCRIPTION: 'Termeric is a school course teacher curriculum buy-and-sell online marketplace. Teachers and homeschooling parents can buy K-12 school course curriculums or stand-alone unit curriculums directly from the teachers who created them. All course and unit curriculums are complete and ready-to-teach, previously taught in real classrooms, and peer reviewed for quality and completeness by our team of expert teachers.',
    REACT_APP_SITE_URL: 'https://termeric.education/',
    REACT_APP_TERMERIC_REGION: 'us-east-1',
    REACT_APP_TERMERIC_AUTH_REGION: 'us-east-1',
    REACT_APP_TERMERIC_ACCOUNTID: '255983348327',
    REACT_APP_TERMERIC_DEV_USERPOOLID: 'us-east-1_xe0t2IC3i',
    REACT_APP_TERMERIC_USERPOOLID: 'us-east-1_pQDxg4Yf0',
    REACT_APP_TERMERIC_DEV_IDENTITYPOOLID: 'us-east-1:27e5749f-8b75-4681-9c7c-f7b71e20148c',
    REACT_APP_TERMERIC_IDENTITYPOOLID: 'us-east-1:fd818fdf-0aff-46a1-94f2-5e46889fe2a4',
    REACT_APP_TERMERIC_DEV_CLIENTID: 'sbk05jd9792d1tdjsirs6990t',
    REACT_APP_TERMERIC_CLIENTID: '3rergknpi6hjbkltrlvqe1j2uk',
    REACT_APP_TERMERIC_PAY_CLIENT_P_KEY: 'pk_live_51IdtnUDBqyppoZNL3AGhmhwtT8OJ3XPDWdLZG4nGGZf16mKJX2GrlXinAnTEt0qzVQ0M3qnVQ1H3JuRSEMHlbkuh00E0aoFZej',
    REACT_APP_TERMERIC_DEV_PAYOUT_CLIENT_P_KEY: 'Aav2hrbXL-SeJTm8FCmfYTHRcSEZXGawmHN0s4C2N7bLlEKtHLdszDv5hhYtCXSfmWIzaudfbaOdMC06',
    REACT_APP_TERMERIC_PAYOUT_CLIENT_P_KEY: 'AYxsuE4jMzIdrABaW2WSunIPl8-FbV9hX0gKT5N9lSJ5tY620juZ1vBDgQD4VWjlezBnD8KFN3t0oCM6'
}

// HACK: Use this only for database callback to be able to trigger rendering of subjects section in the home page:
export const globals = {    
    debug: false, databaseInitStarted: false, databaseInitEnded: false, fileserverInit: false, authInit: false, localeDataFetched: false, urlParams: undefined, cart: undefined, 'cart-subjects': undefined, haveLocalStorage: window.localStorage !== undefined}
export const isDev = process.env.NODE_ENV !== 'production'

const devDomain = 'http://localhost:3000/'
const liveDomain = 'https://termeric.education/'
const domain = isDev ? devDomain : liveDomain

export const envConfig = {
    siteDown: reactAppEnv.REACT_APP_SITE_DOWN,
    region: reactAppEnv.REACT_APP_TERMERIC_REGION,
    authRegion: reactAppEnv.REACT_APP_TERMERIC_AUTH_REGION,
    accountId: reactAppEnv.REACT_APP_TERMERIC_ACCOUNTID,
    devUserPoolId: reactAppEnv.REACT_APP_TERMERIC_DEV_USERPOOLID,
    userPoolId: reactAppEnv.REACT_APP_TERMERIC_USERPOOLID,
    devIdentityPoolId: reactAppEnv.REACT_APP_TERMERIC_DEV_IDENTITYPOOLID,
    identityPoolId: reactAppEnv.REACT_APP_TERMERIC_IDENTITYPOOLID,
    devClientId: reactAppEnv.REACT_APP_TERMERIC_DEV_CLIENTID,
    clientId: reactAppEnv.REACT_APP_TERMERIC_CLIENTID
}

// Authentication Config (AWS Cognito)
export const authConfig = {
    region: envConfig.authRegion,
    cognitoUnauthRole: 'arn:aws:iam::' + envConfig.accountId + ':role/Cognito_termeric_' + envConfig.authRegion + (isDev ? '_dev' : '_') + 'Unauth_Role',
    cognitoAuthRole: 'arn:aws:iam::' + envConfig.accountId + ':role/Cognito_termeric_' + envConfig.authRegion + (isDev ? '_dev' : '_') + 'Auth_Role',
    userPoolId: isDev ? envConfig.devUserPoolId : envConfig.userPoolId,
    identityPoolId: isDev ? envConfig.devIdentityPoolId : envConfig.identityPoolId,
    clientId: isDev ? envConfig.devClientId : envConfig.clientId,
    loginJustDomain: isDev ? 'auth-dev.termeric.education' : 'auth.termeric.education',
    loginDomain: isDev ? 'https://auth-dev.termeric.education' : 'https://auth.termeric.education',
    signInRedirectUrl: domain + 'signin-callback',
    signOutRedirectUrl: domain,
    redirectTargetUrl: domain,
    scope: ['email', 'profile', 'openid']
}

// Database Config (AWS DynamoDB)
export const dbConfig = {
    accountId: envConfig.accountId,    
    region: envConfig.region,
    usersTable: 'termeric-users',
    creatorsTable: 'termeric-creators',
    adminsTable: 'termeric-admins',
    subjectsTable: 'termeric-subjects',
    topicsTable: 'termeric-topics',
    teachersTable: 'termeric-teachers',
    curriculumsTable: 'termeric-curriculums',
    lessonsTable: 'termeric-lessons',
    reviewsTable: 'termeric-reviews',
    salesTable: 'termeric-sales',
    salesPerTeacherTable: 'termeric-sales-per-teacher',
    curriculumsSubmissionsTable: 'termeric-curriculums-submissions',
    curriculumsDraftsTable: 'termeric-curriculums-drafts',
    curriculumsUpdatesTable: 'termeric-curriculums-updated-queue',
    curriculumsArchiveDeletedTable: 'termeric-curriculums-archive-deleted',
    curriculumsApprovedQueueTable: 'termeric-curriculums-approved-queue',
    curriculumsDeletedQueueTable: 'termeric-curriculums-deleted-queue',
    teachersSubmissionsTable: 'termeric-teachers-submissions',
    teachersUpdatesTable: 'termeric-teachers-updated-queue',
    lessonsSubmissionsTable: 'termeric-lessons-submissions',
    lessonsAddedQueueTable: 'termeric-lessons-added-queue',
    lessonsUpdatesTable: 'termeric-lessons-updated-queue',
    lessonsDeletedQueueTable: 'termeric-lessons-deleted-queue',
    lessonsArchiveDeletedTable: 'termeric-lessons-archive-deleted',
    reviewsSubmissionsTable: 'termeric-reviews-submissions',
    reviewsUpdatesTable: 'termeric-reviews-updated-queue',
    subjectsProposalsTable: 'termeric-subjects-proposals',
    topicsProposalsTable: 'termeric-topics-proposals',
    languagesProposalsTable: 'termeric-languages-proposals',
    emailsOutboxQueueTable: 'termeric-emails-outbox-queue',
    checkoutQueueTable: 'termeric-checkout-queue',
    sortIndexKey: 'sort-index',
    batchGetLimit: 100,
    batchWriteLimit: 25,
    tablesWithDeactivableItems: ['termeric-subjects', 'termeric-topics', 'termeric-teachers', 'termeric-curriculums', 'termeric-reviews'],    
    // field types for each table in db:
    tableFields: {
        // all admin users:
        'termeric-admins': {   
            username: 'S'
        },
        // all user accounts:
        'termeric-users': {   
            username: 'S',
            name: 'S',
            email: 'S',
            image: 'S',
            bought: ['L', 'S'],
            'bought-subjects': ['L', 'S'],
            'bought-date': ['L', 'N'],
            'bought-downloaded': ['L', 'BOOL'],
            wishlist: ['L', 'S'],
            'wishlist-subjects': ['L', 'S'],
            'checkout-token': 'S'
        }, 
        // all creator users' private data:
        'termeric-creators': {   
            username: 'S',      
            'payout-user-id': 'S',
            'payout-payer-id': 'S',
            'payout-name': 'S',
            'payout-email': 'S',
            'sales-total-usd': 'N',
            'date-created': 'N',
            'date-last-updated': 'N'
        },
        'termeric-subjects': {
            title: 'S',
            prefix: 'S',
            icon: 'S',
            'sort-index': 'N',
            'num-downloads': 'N'
        },
        'termeric-topics': {            
            subject: 'S',
            title: 'S',
            'num-downloads': 'N'
        },
        // all public teachers data:
        'termeric-teachers': {
            active: 'BOOL',
            id: 'S',
            name: 'S',
            webpage: 'S',
            rating: 'N',
            'num-ratings': 'N',
            'sum-ratings': 'N',
            'num-downloads': 'N',
            about: 'S',
            image: 'S',
            curriculums: ['L', 'S'],
            'curriculums-subjects': ['L', 'S'],
            'curriculums-drafted': ['L', 'S'],
            'curriculums-drafted-subjects': ['L', 'S'],
            'curriculums-submitted': ['L', 'S'],
            'curriculums-submitted-subjects': ['L', 'S'],
            'date-created': 'N',
            'date-last-updated': 'N'
        },
        'termeric-curriculums': {
            active: 'BOOL',
            private: 'BOOL',
            'single-unit': 'BOOL',
            'has-instruction-video': 'BOOL',
            id: 'S',
            'the-language': 'S',
            subject: 'S',
            'random-key': 'S',
            topic: 'S',
            'topic-title': 'S',
            'topic-title-lc': 'S',
            title: 'S',
            'title-lc': 'S',
            subtitle: 'S',
            'subtitle-lc': 'S',
            'price-tier': 'N',
            rating: 'N',
            teacher: 'S',
            'teacher-name': 'S',
            image: 'S',
            'date-created': 'N',
            'date-last-updated': 'N',
            'num-ratings': 'N',
            'sum-ratings': 'N',
            'num-downloads': 'N',
            'num-weeks': 'N',
            'num-lessons-per-week': 'N',
            'num-minutes-per-lesson': 'N',
            'num-resources': 'N',
            'num-tests': 'N',
            'num-assignments': 'N',
            'school-taught-title': 'S',
            'school-taught-website': 'S',
            'term-last-taught': 'S',
            'bundle': 'S',
            grade: ['L', 'N'],
            'learn-what': ['L', 'S'],
            'search-terms': ['L', 'S'],
            'teacher-requirements': ['L', 'S'],
            'teacher-requirements-optionals': ['L', 'S'],
            'student-requirements': ['L', 'S'],
            'student-requirements-optionals': ['L', 'S'],
            'lessons': ['L', 'S'],
            'lesson-titles': ['L', 'S'],
            'section-titles': ['L', 'S'],
            'section-lesson-indices': ['L', 'N'],
            'resource-descriptions': ['L', 'S'],
            'resources': ['L', 'S'],
            'resource-is-externals': ['L', 'BOOL'],
            'teacher-notes': ['L', 'S'],
            previews: 'S',
            'school-proof': 'S',
            'teaching-proof': 'S',
            'other-proof-descriptions': ['L', 'S'],
            'other-proofs': ['L', 'S'],
            'other-proof-is-externals': ['L', 'BOOL'],
            'promo-code-current': 'S',
            'promo-codes': ['L', 'S'],
            'promo-tiers': ['L', 'N']
        },
        'termeric-lessons': {
            curriculum: 'S',
            id: 'S',
            slides: 'S',
            'slides-is-external': 'BOOL',
            'key-concepts': ['L', 'S'],
            objectives: ['L', 'S'],
            'activity-titles': ['L', 'S'],
            'activity-descriptions': ['L', 'S'],
            'activity-durations': ['L', 'N'],
            assessments: ['L', 'S'],
            'other-resource-descriptions': ['L', 'S'],
            'other-resources': ['L', 'S'],
            'other-resource-is-externals': ['L', 'BOOL'],
            'other-resource-is-tests': ['L', 'BOOL'],
            'other-resource-is-assignments': ['L', 'BOOL'],
            'teacher-requirements': ['L', 'S'],
            'teacher-requirements-optionals': ['L', 'S'],
            'student-requirements': ['L', 'S'],
            'student-requirements-optionals': ['L', 'S'],
            'teacher-notes': ['L', 'S'],
            'date-created': 'N',
            'date-last-updated': 'N'
        },
        'termeric-reviews': {
            active: 'BOOL',
            curriculum: 'S',
            subject: 'S',
            reviewer: 'S',
            rating: 'N',
            'old-rating': 'N', // this only goes to update-queue table to help calculating change in rating...
            teacher: 'S',
            'reviewer-name': 'S',
            review: 'S',
            'date-last-updated': 'N'
        },
        'termeric-subjects-proposals': {
            teacher: 'S',
            'curriculum': 'S',
            'title': 'S',
            subject: 'S',
            'is-subject-proposal': 'BOOL',
            'in-productions': 'BOOL',
            'date-last-updated': 'N'
        },
        'termeric-topics-proposals': {
            teacher: 'S',
            'curriculum': 'S',
            'title': 'S',
            subject: 'S',
            topic: 'S',
            'is-subject-proposal': 'BOOL',
            'in-productions': 'BOOL',            
            'date-last-updated': 'N'
        },
        'termeric-languages-proposals': {
            teacher: 'S',
            subject: 'S',
            'curriculum': 'S',
            'title': 'S',          
            'the-language': 'S',
            'in-productions': 'BOOL',
            'date-last-updated': 'N'
        },
        'termeric-sales-per-teacher': {
            teacher: 'S',
            'date-purchased': 'N',
            'curriculum': 'S',
            'price-usd': 'N',
            'subject': 'S',
            'username': 'S',
            'buyer-name': 'S'        
        }
    },
    // related list fields in each table where indices should correspond (curriculum id -> subject):
    tableCurriculumToSubjectMappings: {
        'termeric-users': {
            bought: 'bought-subjects',
            wishlist: 'wishlist-subjects'
        },
        'termeric-teachers': {
            curriculums: 'curriculums-subjects',
            'curriculums-drafted': 'curriculums-drafted-subjects',
            'curriculums-submitted': 'curriculums-submitted-subjects'
        }
    }
}

// Fileserver Config (AWS S3)
export const fsConfig = {
    rootBucketUrl: 'https://s3.' + reactAppEnv.REACT_APP_TERMERIC_REGION + '.amazonaws.com', 
    privateRootBucket: 'files.termeric.education' + '.' + reactAppEnv.REACT_APP_TERMERIC_REGION,
    publicRootBucket: 'public.files.termeric.education' + '.' + reactAppEnv.REACT_APP_TERMERIC_REGION,
    webpageBucket: 'public.termeric.education' + '.' + reactAppEnv.REACT_APP_TERMERIC_REGION,
    webpageBucketUrl: 'https://public.termeric.education/',
    dataBucketDir: 'data/',
    publicImagesBucketDir: 'images/',
    liveDataBucketDir: 'live/',
    submissionsDataBucketDir: 'submissions/',
    updatesDataBucketDir: 'updates/',
    archiveDeletedDataBucketDir: 'archive/deleted/',
    teachersBucketDir: 'teachers/',
    curriculumsBucketDir: 'curriculums/',
    imageBucketDir: 'about/',
    aboutBucketDir: 'about/',
    previewsBucketDir: 'previews/',
    slidesBucketDir: 'slides/',
    resourcesBucketDir: 'resources/',
    lessonsBucketDir: 'lessons/',
    teachingProofsBucketDir: 'proofs/',
    bundleBucketDir: 'bundle/',
    curriculumDescriptionFile: 'description.html',
    curriculumPreviewsFilePostfix: '-previews.pdf',
    teacherPublicPageFile: 'teacher.html',
    curriculumPublicPageFile: 'curriculum.html',
    curriculumDirectoryPublicPageFile: 'directory.html',
    teacherDirectoryPublicPageFile: 'teacher-directory.html',
    allowedResourceUploadFileTypes: ['image/*', 'video/*', 'text/*', 'audio/*', 'application/pdf', 'application/zip', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'],
    allowedPreviewUploadFileTypes: ['application/pdf', 'application/zip'],
    allowedTeachingProofUploadFileTypes: ['image/*', 'application/pdf'],
    maxUploadFileSizeBytes: 15000000 // max resource file upload size: 15 MB 
}

// analytics:
export const analyticsConfig = {
    analyticsOn: true,
    analyticsTrackingId: 'G-9K2EFYZTNL' //'UA-196430871-2'
}

// serverless server:
export const serverConfig = {
    apiUrl: 'https://ro264ad8qf.execute-api.' + reactAppEnv.REACT_APP_TERMERIC_REGION + '.amazonaws.com/production/requests'
}

// payment:
export const payConfig = { 
    // stripe public key (FIXME: replace with live key)
    clientPKey: reactAppEnv.REACT_APP_TERMERIC_PAY_CLIENT_P_KEY,
    // will be updated later by initialization based on locale:
    currencySymbol: '$',
    // will be updated later by initialization based on locale:
    currency: 'USD',
    // will be updated later by initialization based on locale:
    country: 'US',
    // will be updated later by initialization based on locale:
    currencyExchangeRateToUSD: 1.0,
    // if cached locale data is newer than this # days old, we then skip getting the latest:
    maxNumberOfDaysBeforeLocaleCacheExpires: 14,
    // if cached exchange rate is newer than this # days old, we then skip getting the latest rate:
    maxNumberOfDaysBeforeExchangeRateCacheExpires: 7,
    // price tier prices (USD and local)    
    priceTierPrices: {
        USD: [0, 25, 50, 75, 100, 150, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000]
    },
    // promotion tier percentages
    promoTierDiscountPercents: [0, 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90, 100],
    // promo code length
    promoCodeLength: 8
}
// priceTiers
payConfig.priceTiers = [...Array(payConfig.priceTierPrices.USD.length).keys()]
// local prices will be a copy of USD and later will be updated by initialization based on exchange rates and locale:
payConfig.priceTierPrices.local = [...payConfig.priceTierPrices.USD]

// payout to creator teachers:
export const payoutConfig = {
    // paypal public key:
    clientPKey: reactAppEnv.REACT_APP_TERMERIC_PAYOUT_CLIENT_P_KEY,
    linkButtonUrl: 'https://www.paypalobjects.com/webstatic/en_US/developer/docs/login/connectwithpaypalbutton.png'
}
// link for PayPal account linking button: 
payoutConfig.payoutLinkAccountUrl = `https://www.paypal.com/connect?flowEntry=static&client_id=${payoutConfig.clientPKey}&response_type=code&scope=openid%20profile%20email&redirect_uri=${liveDomain}creator/edit-teacher?link-payout-result=true`

export const curriculumConfig = {    
    maxNumberOfLessonsPerWeek: 5,
    allowedLessonDurationsMinutes: [30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180],
    allowedLessonActivityDurationsMinutes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180],
    minLessonActivityDurationMinute: 1,
    maxLessonActivityDurationMinute: 180,
    minNumberOfWeeks: 10,
    maxNumberOfWeeks: 40,
    maxNumberOfTests: 6,
    defaultPriceTier: 2,
    defaultLessonDurationsMinutes: 50,
    defaultLessonActivityDurationMinutes: 20,
    defaultNumberOfLessonsPerWeek: 2,
    defaultNumberOfWeeks: 20,
    defaultNumberOfTests: 0,
    defaultNumberOfAssignments: 0,
    defaultGrade: 1,
    minNumberOfLearningOutcomes: 3,
    maxNumberOfSearchTerms: 5,
    minLessonNumberOfObjectives: 3,
    maxLessonNumberOfObjectives: 5,
    minLessonNumberOfActivities: 2,
    lessonsOtherResourcesOptionalFields: {'other-resource-is-tests': 'numTestsCounted', 'other-resource-is-assignments': 'num-assignments'}
}

// various configs for various pages:
export const pages = {
    general: {
        teamName: 'Termeric Team',
        teamEmail: 'team@termeric.education',
        teamGmail: 'termeric.education@gmail.com',
        theme: {colors: {yellow: '#F3CA3E'}},
        defaultLanguage: 'en',
        logoUrl: fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/logo.png',
        bannerImageUrl: fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/banner.png',
        avatarIconImageUrl: fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/avatar-icon.png',
        subjectIconPlaceHolder: fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/subject-icon-placeholder.png',
        curriculumImagePlaceHolder: 'https://upload.wikimedia.org/wikipedia/commons/e/ea/Image_placeholder.svg',
        // teacher profile image placeholder:
        teacherImagePlaceHolder: fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/profile-picture-placeholder.png',
        nothingToShowImageUrl: fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/nothing-to-show.png',
        ctaItemIconImageUrl: fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/benefit-icon.png',
        // fontawesome icon class name for showing error/ok icon in a dialog box:
        dialogBoxErrorIconClassName: 'red-error-color fas fa-exclamation-circle',
        dialogBoxOKIconClassName: 'green-ok-color fas fa-check-circle',
        dialogBoxInfoIconClassName: 'blue-info-color fas fa-info-circle',
        dialogBoxQuestionIconClassName: 'orange-question-color fas fa-question-circle',
        socialUrls: {facebook: 'https://www.facebook.com/termeric.education', instagram: 'https://www.instagram.com/termeric.education/', linkedin: 'https://www.linkedin.com/company/termeric.education', twitter: ''},
        // url parms that once set in a page, will persist to other pages with navigation:
        urlParamsToPersist: ['promo']
    },
    home: {
        // home page lists popular curriculums (rating >= X):
        curriculumsPopularRatingThreshold: 0,
        // home page lists popular curriculums (num-downloads >= X):
        curriculumsPopularDownloadCountThreshold: 0,
        // home page lists latest curriculums (date-created >= now - X days):
        curriculumsLatestCreationDateDaysThreshold: 365,
        // home page lists popular subjects ordered by download count. min number to show:
        minNumberOfPopularSubjectsShown: 5,
        // home page lists popular subjects ordered by download count. max number to show:
        maxNumberOfPopularSubjectsShown: 10,
        // numer of popular curriculums to show in home page listing
        maxNumberOfPopularCurriculumsToShow: 30,
        // curriculum view card width (in px)
        curriculumCardWidth: 245,
        // curriculum cards view side border (in px)
        curriculumCardsViewSideBorder: 100,
        // if screen width is <= this we assume we're on mobile (in px)
        maxScreenWidthToAssumeMobile: 1000
    },
    search: {
        // default results sort type:
        defaultResultsSortType: 'relevant',
        // how many curriculum results does search page show in each page:
        numberOfResultsShown: 15,
        // how many page toggles show on each side of ellipsis in page toggle buttons section:
        numberOfPageTogglesShownOnEachSide: 4
    },
    library: {
        // how many curriculum results does page show in each page:
        numberOfResultsShown: 15,
        // money-back guarantee expires after days:
        numberOfDaysBeforeMoneyBackGuaranteeExpiresDays: 7
    },
    cart: {
        // how many curriculum results does page show in each page:
        numberOfResultsShown: 15
    },
    teacher: {
        // how many curriculum results does page show in each page:
        numberOfResultsShown: 15        
    },
    // for creator site pages:
    creator: {
        // counter to ensure unique ids generated from current epoch time are indeed unique:
        uniqueIdCtr: 0,
        // length of random key generated for curriculums:
        randomKeyLength: 36,
        // term names
        termNames: ['Fall', 'Spring', 'Winter', 'Summer']
    },
    // for admin site pages:
    admin: {
        maxBulkEmailRecipients: 50
    }
}

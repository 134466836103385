/*
  Handles checkout payment and connection with payment service: Stripe
*/

import fetch from 'isomorphic-unfetch'
import { loadStripe } from '@stripe/stripe-js'
import uuid from 'react-uuid'

import * as Config from './config'
import * as Database from './database-api'
import * as Utility from './utility'

const globals = Config.globals
const generalConfig = Config.pages.general
const serverConfig = Config.serverConfig
const payConfig = Config.payConfig

const stripePromise = loadStripe(payConfig.clientPKey)
let Stripe

// handles return from external checkout session and if it can verify the token it will proceed to fulfill the purchase:
export function pay_handleReturnFromCheckoutSession(checkoutResult, callerPage) {      
  let urlParams = globals.urlParams
  let urlCheckoutToken = urlParams.token
  if (urlCheckoutToken !== undefined) {
    // get the stored checkout token so we can verify:
    let storedCheckoutToken = getCheckoutToken()      
    // remove the stored token since we got it already for verification at this time:
    removeCheckoutToken()
    // if checkout result was success proceed with fulfilling the purchase:
    if (checkoutResult === 'success') {
      let verified = urlCheckoutToken === storedCheckoutToken        
      if (verified) {
        try {
          // FIXME: should get transaction id from Stripe rather than our own manually created token:
          let checkoutData = {transactionId: storedCheckoutToken, curriculums: JSON.parse(urlParams.cart), curriculumSubjects: JSON.parse(urlParams['cart-subjects']), pricesLocal: JSON.parse(urlParams.pricesLocal), pricesUSD: JSON.parse(urlParams.pricesUSD), total: JSON.parse(urlParams.total)}
          callerPage.paymentProcessed({error: false, data: checkoutData})
        } catch (error) {
          // FIXME: handle error
          console.log('Error parsing URL parameters of checkout session return!')
        }
      } else {
        // FIXME: handle error
        console.log('Failed to verify successful checkout')
      }
    }
  }
} 

// called by the frontend UI once a payment info (card # etc) is tokenized by Strip library
// it then sends the token to the backend serverless server and returns the result of the payment:
export async function pay_requestCheckoutSession(items, total, callerPage) {
  if (Stripe === undefined)
    Stripe = await stripePromise  
  // FIXME: use Stripe webhook to receive checkout.session.completed event to verify payment was successful, instead of manual token verification below:
  let token = uuid()
  let data = await storeCheckoutToken(token)
  if (!data.error) {
    try {
      const res = await fetch(serverConfig.apiUrl, { // Backend API url
        method: 'POST',
        body: JSON.stringify({        
          type: 'payment',
          params: {
            token: token,
            isDev: Config.isDev,
            currency: payConfig.currency,
            items: items,
            total: total
          }
        }),
      })
      data = await res.json()
    } catch (error) {
      data.error = error
    }
  }  
  paymentSessionReady(data, token, callerPage)
}

// called once the payment session is set up in the backend stripe and result returned back:
async function paymentSessionReady(response, token, callerPage) {  
  if (response.error) // payment error:
    // response.error .type / .code / .message:      
    Utility.showTextDialog(0, 'Problem setting up a payment session: "' + response.error.toString() + '"', generalConfig.dialogBoxErrorIconClassName)
  else {    
    try {
      // Request the payment:
      Stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as argument here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: response.data.session.id
      }).then(function (errorResult) {        
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `errorResult.error.message`.      
        callerPage.paymentProcessed(errorResult)
      })
    } catch (error) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `errorResult.error.message`:
      callerPage.paymentProcessed({error: {message: error.toString()}})
    }
  }
}

/*
  Helper functions
*/

// FIXME: use Stripe webhook to receive checkout.session.completed event to verify payment was successful, instead of manual token verification below:
// Stores checkout session token so that upon return from external payment session we can verify the return param matches the stored token...:
async function storeCheckoutToken(token) {  
  if (globals.haveLocalStorage) {
    localStorage.setItem('termeric-checkout-session-token', token)
    return {}
  } else {
    // in the absense of local storage store this in database
    return await Database.database_storeUserCheckoutToken(token)
  }
}

// Removes stored checkout session token once we have got it already for verification purposes:
async function removeCheckoutToken() {  
  if (globals.haveLocalStorage) {
    localStorage.removeItem('termeric-checkout-session-token')    
  } else {
    // in the absense of local storage store this in database
    return await Database.database_storeUserCheckoutToken('')
  }
}

// FIXME: use Stripe webhook to receive checkout.session.completed event to verify payment was successful, instead of manual token verification below:
// upon return from external payment session it gives us the stored checkout session token so that we can verify it matches the return param...:
function getCheckoutToken() {
  return globals.haveLocalStorage ?
    localStorage.getItem('termeric-checkout-session-token') :  
    // in the absense of local storage store this in database
    Database.getUserCheckoutToken()
}
